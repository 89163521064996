import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import {Link} from "react-router-dom";
import {textFilter} from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";

class SubjectFacilityDetails extends Component {
    getAddress(facility) {
        let address = `${facility.street}, ${facility.city},, ${facility.state} ${facility.zipcode}`
        // Replace only does first occurance
        let retString = address.split('null,').join('').split('null').join('').trim()
        if (retString.endsWith(",")){
            retString = retString.slice(0,retString.length-1)
        }
        return retString
    }

    render() {
        const columns = [
            {
                dataField: "cageCode",
                text: "Cage Code",
                formatter: (cell, row) => <Link to={`/org/dash/facility/${row.id}`}>{cell}</Link>,
                sort: true
            },
            {
                dataField: "name",
                text: "Name",
                formatter: (cell, row) => <Link to={`/org/dash/facility/${row.id}`}>{cell}</Link>,
                sort: true
            },
            {
                dataField: "address",
                text: "Address",
                formatter: (cell, row) => this.getAddress(row),
                sort: true
            },
        ];

        if (this.props.subject.fullFacilities){
            return (
                this.getFacilityList(columns)
            )
        } else {
            return <Fragment></Fragment>;
        }
    }

    getFacilityList(columns) {
        return <Fragment>
            <Card className={"h-100"} border={"success"}>
                <Card.Body>
                    <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="id"
                                    data={this.props.subject.fullFacilities} columns={columns}/>
                </Card.Body>
            </Card>
        </Fragment>;
    }
}

SubjectFacilityDetails.propTypes = {
    subject: PropTypes.object.isRequired,
};

export default SubjectFacilityDetails;