import React, { Component, Fragment } from "react";
import { connect } from "react-redux"; 
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import { fetchPersonAsync, reportForSubjectBySO } from "../../../../../../actions/subject_actions.js";
import { showErrorGrowl, showSuccessGrowl } from "../../../../../../actions/msg_actions.js"; 
import { ConditionalDisplay } from "../../../../../util/shouldDisplay.js";
import Modal from "react-bootstrap/Modal";
import { CommonPersonalInfoArrest } from "../../../../../reporting/subject/commonInfo/common_personalInfo_arrest.js";
import { SubjectArrestDetails } from "../subject_arrest_details.js";
import { CommonReportMaritalStatus } from "../../../../../reporting/subject/commonInfo/common_personalInfo_maritalstatus.js";
import { SubjectMaritalDetails } from "../subject_marital_details.js";
import { CommonUtils } from "../../../../../../actions/common_utils.js";


class SubjectReportMaritalStatustComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModel: false,
            subject:null,
            spouseHistory:[]
        }

    }
    
    componentDidMount() {
        const { subject } = this.props;
        this.setState({ subject: subject })
        this.setState({ spouseHistory: subject?.spouseHistory })

    }

    async onSubmit(values) {
        const { subject } = this.state;
        values.subjectId = subject.id;
        values.type = "PERSONNEL_INFO_MARITAL_STATUS";
        values.jsonClassType = "SpouseReportable";
        if (values.status === "marriage") {
            values.dateInvalidated = null;
            values.reasonInvalidated = null;
        } else {
            values.firstName = null;
            values.lastName = null;
            values.startDate = null;
        } 
        values.supportingDocs = values.file;
        if (values.supportingDocs){
            for (let eachDoc of values.supportingDocs){
                eachDoc.docType = 'SPOUSE_CITIZEN_VERIFICATION';
            }
        }
        let response = await reportForSubjectBySO(values)
        if (response) {
            this.props.showSuccessGrowl("Marital Status Reported");
            let response = await fetchPersonAsync(subject.id);
            if (response != null && response.arrests) {
                this.setState({ subject: response })
            }
            this.setState({ showModel: false })
        } else {
            if (error.response.status === 404) {
                this.props.showErrorGrowl("Subject Not Found", "Please check the identity information.")
            }
        }
    }

    handleCancel = () => {
        this.setState({ showModel: false })
    }
    viewAdd = () => {
        this.setState({ showModel: true })
    } 
    render() { 
        const { showModel,spouseHistory } = this.state;
        return (
            <Fragment>
                {ConditionalDisplay(
                    <div className="align-right">
                        <ButtonToolbar>
                            <Button variant="primary" className="mb-3" onClick={this.viewAdd}>Change Marital Status</Button>
                        </ButtonToolbar>
                    </div>
                )}
                <Modal size="lg" show={showModel} onHide={this.handleCancel} >
                    <Modal.Header closeButton>
                        <Modal.Title><h3>Report Marital Status Form</h3></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <CommonReportMaritalStatus onSubmit={this.onSubmit.bind(this)} onCancel={this.handleCancel}/>
                    </Modal.Body>
                </Modal>
                <SubjectMaritalDetails spouseHistory={spouseHistory}/>
            </Fragment>
        )
    }
}

function mapStateToProps({ person }) {
    return {
        subject: person
    }
}
export const SubjectReportMaritalStatus = connect(mapStateToProps, {showSuccessGrowl, showErrorGrowl})(SubjectReportMaritalStatustComp);

 