import React, {Component, Fragment} from "react";
import {DateUtils} from "../../../../../actions/common_utils";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";

export class SubjectForeignIdCardDetails extends Component {
    getForeignIdCards() {
        return this.props.foreignIdCards.map((foreignIdCard) => {
            return <tr key={foreignIdCard.id}>
                <td>{foreignIdCard.country}</td>
                <td>{foreignIdCard.idCardNumber}</td>
                <td>{foreignIdCard.reason}</td>
                <td>{foreignIdCard.startDate ? DateUtils.getDateString(foreignIdCard.startDate) : "Unknown"}</td>
                <td>{foreignIdCard.expireDate ? DateUtils.getDateString(foreignIdCard.expireDate) : "Present"}</td>
            </tr>
        })
    }

    render() {
        const {foreignIdCards} = this.props;

        if (!foreignIdCards || foreignIdCards.length === 0) {
            return <Alert variant="danger">No Foreign ID/Cards!</Alert>
        }

        return (
            <Fragment>
                <Table>
                    <thead>
                    <tr>
                        <th>Country</th>
                        <th>ID Card #</th>
                        <th>Reason</th>
                        <th>Date Acquired</th>
                        <th>Date Relinquished</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.getForeignIdCards()}
                    </tbody>
                </Table>
            </Fragment>
        );
    }
}