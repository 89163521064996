import React, {Component, Fragment} from "react";
import {Form, Card, Col, Button, ButtonToolbar, Container, Modal} from "react-bootstrap";
import {Link} from "react-router-dom";
import {getDocTypes, getEntityTypes} from "../../../../actions/fieldValue_actions";
import {connect} from "react-redux";
import {CUR_ORG} from "../../../../actions/session_constants";
import {ORG_NAME} from "../../../../actions/auth_actions";
import {fetchCurOrg} from "../../../../actions/org_actions";
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link";
import * as Yup from "yup";
import {createEmailTask, createEmailTaskPrecheck} from "../../../../actions/task_actions";
import {showSuccessGrowl, showErrorGrowl} from "../../../../actions/msg_actions";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import {getOrgContracts} from "../../../../actions/contract_actions";
import {
    fetchActiveOrgPersonsWithInvalidEmail, fetchActiveOrgPersonsEligibleForScreening,
    getActiveOrgPersons,
    getOrgPersons
} from "../../../../actions/subject_actions";
import {getOrgFacilities} from "../../../../actions/facility_actions";
import {fetchTemplates} from "../../../../actions/signature_actions";
import {Formik, Field} from "formik";
import {CommonUtils} from "../../../../actions/common_utils";
import {FormikDatePickerComponent} from "../../../../components/formik/formik_date_picker";
import {FormikFieldDropzone} from "../../../../components/formik/formik_field_dropzone";
import {FormikFieldDualListBox} from "../../../../components/formik/formik_field_duallistbox";
import {fetchAvailableStrlngPackagesForOrg, initiateScreening} from "../../../../actions/bg_check_actions";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import DeleteConfirmationModal from "../../../../components/delete_confirm_modal";

class ScreeningNewComp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
        }
    }

    getStrlngPackages = async () => {
        let strlngPackages = await fetchAvailableStrlngPackagesForOrg(
            (error) => {
                this.props.showErrorGrowl("Server Error", error.response.data.message);
            }
        );
        console.log('Got sterling packages', strlngPackages)
        let packagesAvailable = [
            {
            id: "",
            name: "Select Package"
            }
        ];
        if (strlngPackages){
            for (let strlngPackage of strlngPackages){
                packagesAvailable.push({
                    id: strlngPackage.id,
                    name: strlngPackage.title
                })
            }
        }
        console.log('Got sterling packages', packagesAvailable)
        this.setState({
            packagesAvailable,
            warningMessage: ''
        });
    }

    componentDidMount() {
        this.getStrlngPackages();

        this.fetchValidEmailSubjects();
    }

    onSubmit = async (values, actions) => {
        console.log('Submit called with values', values)
        await initiateScreening(values.subjectId, values.packageId,()=>{
            this.props.showSuccessGrowl("Screening Created Successfully!");
            this.props.history.push("/org/dash/screening");
        }, (error)=>{
            this.props.showErrorGrowl(error.response.data);
        });
        actions.setSubmitting(false);
    }
    fetchValidEmailSubjects = async () => {
        try {
            const subjects = await fetchActiveOrgPersonsEligibleForScreening();
            console.log('Valid email subjects are ', subjects);
            let subjectsAvailable = [
                {
                    id: "",
                    name: "Select Subject"
                }
            ];
            subjects.forEach((subject) => {
                subjectsAvailable.push({
                    id: subject.id,
                    name: `${subject.personFirstName?subject.personFirstName:''} ${subject.personLastName}`
                });
            })
            this.setState({subjectsAvailable});
        } catch(error){
            this.props.showErrorGrowl("Unexpected error occurred", "Error fetching subjects with valid email.");
            console.log(error);
        };
    }

    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/org/dash" onClick={() => fetchCurOrg()}>
    //                 Organization {this.props.cur_org.name}
    //             </BreadcrumbLink>
    //             <BreadcrumbLink to="/org/dash/screening">
    //                 Tasks
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 New Screening
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    close = () => {
        this.setState({showModal: false, warningMessage: null});
    }

    handleCancel = () => {
        window.history.back();
    }

    render() {
        const {packagesAvailable, subjectsAvailable, warningMessage, showModal} = this.state;

        return (
            <Formik
                initialValues={{
                    "subjectId": "",
                    "packageId": "",
                }}
                enableReinitialize={true}
                onSubmit={this.onSubmit}
                validationSchema={
                    Yup.object().shape({
                        subjectId: Yup.string().required('Please choose a subject'),
                        packageId: Yup.string().required('Please choose a package')
                    })
                }
            >
                {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, resetForm, errors, handleBlur}) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        {/* <h3>New Screening</h3> */}
                            <Card>
                                <Card.Header>
                                    <Card.Title>New Screening Form</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId={'packageId'}>
                                            <Form.Label>Select Package</Form.Label>
                                            <Form.Control required as="select" name={'packageId'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          isInvalid={touched.packageId && !!errors.packageId}
                                                          value={values.packageId}>
                                                {CommonUtils.getOptionsForSelect(packagesAvailable)}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.packageId}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId={'subjectId'}>
                                            <Form.Label>Select Subject <OverlayTrigger placement="top" overlay={personalinformation}
                                                                                       className="tooltip"><i className="fa fa-question" aria-hidden="true"/></OverlayTrigger></Form.Label>
                                            <Form.Control required as="select" name={'subjectId'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          isInvalid={touched.subjectId && !!errors.subjectId}
                                                          value={values.subjectId}>
                                                {CommonUtils.getOptionsForSelect(subjectsAvailable)}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.subjectId}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                </Card.Body>
                                <Card.Footer>
                                <ButtonToolbar>
                                            <Button variant="primary" disabled={isSubmitting} type="submit">Submit</Button>
                                            {/* <Link to="/org/dash/task" className="btn btn-danger">Cancel</Link> */}
                                            <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="New Screening Form"
                                                         handleDeleteCallback={this.handleCancel}/>
                                        </ButtonToolbar>
                                </Card.Footer>
                            </Card>
                    </Form>
                )}
            </Formik>
        )
    }
}

const personalinformation = props => (
    <Tooltip {...props}>Only subjects with valid email, DOB, SSN and Address are eligible.</Tooltip>
);


export const ScreeningNew = connect(null, {createEmailTask, createEmailTaskPrecheck, showSuccessGrowl, showErrorGrowl})(ScreeningNewComp);