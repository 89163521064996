import React, {Component} from "react";
import {Field, reduxForm} from "redux-form";
import {
    FieldMultiSelectDropdown,
    FieldRBDatePicker,
    FieldRBInput,
    FieldRBSelect,
    FieldRBSelectWithKeys
} from "../../../../../components/redux_form/field_components_rb";
import {connect} from "react-redux";
import {fetchPerson, updateSubjectClearanceDetails} from "../../../../../actions/subject_actions";
import PropTypes from "prop-types";
import {showSuccessGrowl} from "../../../../../actions/msg_actions";
import Form from "react-bootstrap/Form";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import {ClearanceMapping} from "../../../../../actions/common_utils";
import { Card } from "react-bootstrap";

export const NON_SCI_ACCESS_OPTIONS = Array.of('N/A', 'Confidential', 'Secret', 'Top Secret');
export const SCI_ACCESS_OPTIONS = Array.of({name:'SI'}, {name:'TK'},
    {name:'G'}, {name:'H'},{name:'N/A'});
export const SPECIAL_ACCESS_OPTIONS = Array.of({name:'NATO'}, {name:'COMSEC'}, {name:'CNWDI'});
export const SCI_ACCESS_OPTIONS_ARR = Array.of('SI', 'TK', 'G', 'H');
export const SPECIAL_ACCESS_OPTIONS_ARR = Array.of('NATO', 'COMSEC', 'CNWDI');

class SubjectClearanceUpdate extends Component {
    constructor(props) {
        super(props);

        this.cancelUpdate = this.cancelUpdate.bind(this);
        this.state = {
            sciAccesses : [],
            specialAccesses : []
        }
    }

    componentDidMount() {
        this.didFormInit = false;
        this.props.fetchPerson(this.props.subjectId);

    }

    nonSciAccessValues(){
        const {subject} = this.props;
        if (subject){
            let nonSciAccesses = [];
            nonSciAccesses.push(...NON_SCI_ACCESS_OPTIONS);
            if (!nonSciAccesses.includes(ClearanceMapping.mapNonSciAccessForUI(subject.nonSciAccesses))){
                nonSciAccesses.push(subject.nonSciAccesses);
            }
            return nonSciAccesses;
        }
    }

    sciAccessOptions(){
        const {subject} = this.props;
        if (subject){
            let sciAccessesOptions = [];
            sciAccessesOptions.push(...SCI_ACCESS_OPTIONS);
            let sciAccessOptionsStr = '';
            for (let option of sciAccessesOptions) {
                sciAccessOptionsStr += (option.name + ',');
            }
            if (subject.sciAccesses){
                for (let sciAccess of subject.sciAccesses.split(',')){
                    if (!sciAccessOptionsStr.includes(sciAccess)){
                        sciAccessesOptions.push({name:sciAccess});
                    }
                }
            }
            return sciAccessesOptions;
        }
    }

    sciAccessSelectedOptions(){
        const {subject} = this.props;
        if (subject){
            let sciAccessSelected = [];
            if (subject.sciAccesses){
                for (let sciAccess of subject.sciAccesses.split(',')){
                    sciAccessSelected.push({name:sciAccess});
                }
            }
            return sciAccessSelected;
        }
    }

    onSciSelect = (selectedList, selectedItem) => {
        if (selectedItem.name === 'N/A'){
            // clear all others out of the selected list
            selectedList.splice(0, selectedList.length, selectedItem);
        } else {
            // if any of the item in the selected list is N/A but a new value is selected then get rid of the N/A in the selected list
            for (let [index, eachItem] of selectedList.entries()){
                if (eachItem.name === 'N/A'){
                    selectedList.splice(index, 1);
                    break;
                }
            }
        }
        this.setState({
            sciAccesses: selectedList
        });
    }

    onSciRemove = (selectedList, removedItem) => {
        this.setState({
            sciAccesses: selectedList
        });
    }

    specialAccessOptions = () => {
        const {subject} = this.props;
        if (subject){
            let specialAccessOptions = [];
            specialAccessOptions.push(...SPECIAL_ACCESS_OPTIONS);
            let specialAccessOptionsStr = '';
            for (let option of specialAccessOptions) {
                specialAccessOptionsStr += (option.name + ',');
            }
            if (subject.specialAccesses){
                for (let specialAccess of subject.specialAccesses.split(',')){
                    if (!specialAccessOptionsStr.includes(specialAccess)){
                        specialAccessOptions.push({name:specialAccess});
                    }
                }
            }
            return specialAccessOptions;
        }
    }

    specialAccessSelectedOptions(){
        const {subject} = this.props;
        if (subject){
            let specialAccessSelected = [];
            if (subject.specialAccesses){
                for (let specialAccess of subject.specialAccesses.split(',')){
                    specialAccessSelected.push({name:specialAccess});
                }
            }
            return specialAccessSelected;
        }
    }

    onSpecialSelect = (selectedList, selectedItem) => {
        if (selectedItem.name === 'N/A'){
            // clear all others out of the selected list
            selectedList.splice(0, selectedList.length, selectedItem);
        } else {
            // if any of the item in the selected list is N/A but a new value is selected then get rid of the N/A in the selected list
            for (let [index, eachItem] of selectedList.entries()){
                if (eachItem.name === 'N/A'){
                    selectedList.splice(index, 1);
                    break;
                }
            }
        }
        this.setState({
            specialAccesses: selectedList
        });
    }

    onSpecialRemove = (selectedList, removedItem) => {
        this.setState({
            specialAccesses: selectedList
        });
    }

    componentDidUpdate() {
        const {subject} = this.props;
        if (subject && this.didFormInit === false){
            let clearanceDetails = {};
            clearanceDetails.nonSciAccesses = ClearanceMapping.mapNonSciAccessForUI(subject.nonSciAccesses);
            clearanceDetails.investigationBeginDate = subject.investigationBeginDate;
            clearanceDetails.investigationDate = subject.investigationDate;
            clearanceDetails.investigationType = subject.investigationType;
            clearanceDetails.sciAccesses = subject.sciAccesses;
            clearanceDetails.specialAccesses = subject.specialAccesses;
            clearanceDetails.ceDateEnrolled = subject.ceDateEnrolled;
            clearanceDetails.determination = subject.determination;
            clearanceDetails.ceStatus = subject.ceStatus;
            clearanceDetails.eligibility = subject.eligibility;
            clearanceDetails.eligibilityDate = subject.eligibilityDate;
            clearanceDetails.polygraph = subject.polygraph;

            this.props.initialize(clearanceDetails);
            this.didFormInit = true;
        }
    }

    getAccessStr = (accesses) => {
        let accessStr = '';
        let index = 1;
        for (let access of accesses){
            accessStr += access.name;
            if (index++ !== accesses.length){
                accessStr += ',';
            }
        }
        return accessStr;
    }

    onSubmit = (values) => {
        if (this.state && this.state.sciAccesses){
            values.sciAccesses = this.getAccessStr(this.state.sciAccesses);
        }
        if (this.state && this.state.specialAccesses){
            values.specialAccesses = this.getAccessStr(this.state.specialAccesses);
        }
        values.nonSciAccesses = ClearanceMapping.mapNonSciAccessFromUI(values.nonSciAccesses);
        this.props.updateSubjectClearanceDetails(this.props.subjectId, values, () => {
            this.props.showSuccessGrowl("Subject Updated", "Subject clearance details updated successfully!");
            this.props.onFinish();
        });
    }

    cancelUpdate() {
        this.props.reset();
        this.didFormInit = false;
        this.props.onFinish();
    }

    render() {
        const {handleSubmit, subject} = this.props;
        const {badgingLevels} = this.state;
        if (!subject || !subject.id) {
            return <div>Loading...</div>;
        }

        return (
            <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                <Card>
                    <Card.Header>Update Clearance Information</Card.Header>
                    <Card.Body>
                        <div className="row">
                            <div className="col-lg-4">
                                <Field name="eligibility" component={FieldRBInput} horizontal label="Eligibility" />
                            </div>

                            <div className="col-lg-4">
                                <Field name="eligibilityDate" component={FieldRBDatePicker} horizontal label="Eligibility Date" />
                            </div>

                            <div className="col-lg-4">
                                <Field name="nonSciAccesses" component={FieldRBSelect} horizontal label="Non-SCI Access"
                                    options={this.nonSciAccessValues()} />
                            </div>

                            <div className="col-lg-4">
                                <Field name="sciAccesses" component={FieldMultiSelectDropdown} horizontal label="SCI Access"
                                    options={this.sciAccessOptions()}
                                    selectedValues={this.sciAccessSelectedOptions()}
                                    onSelect={this.onSciSelect}
                                    onRemove={this.onSciRemove}
                                    displayValue='name'
                                />
                            </div>

                            <div className="col-lg-4">
                                <Field name="specialAccesses" component={FieldMultiSelectDropdown} horizontal label="Special Access"
                                    options={this.specialAccessOptions()}
                                    selectedValues={this.specialAccessSelectedOptions()}
                                    onSelect={this.onSpecialSelect}
                                    onRemove={this.onSpecialRemove}
                                    displayValue='name'
                                />
                            </div>

                            <div className="col-lg-4">
                                <Field name="investigationType" component={FieldRBSelect} horizontal label="Investigation Type"
                                    options={Array.of('NACI', 'SSBI', 'SBPR', 'PPR', 'T3', 'T3R', 'T5', 'T5R', '')} />
                            </div>

                            <div className="col-lg-4">
                                <Field name="investigationBeginDate" component={FieldRBDatePicker} horizontal label="Investigation Begin Date" />
                            </div>

                            <div className="col-lg-4">
                                <Field name="investigationDate" component={FieldRBDatePicker} horizontal label="Investigation Close Date" />
                            </div>

                            <div className="col-lg-4">
                                <Field name="ndaDate" component={FieldRBDatePicker} horizontal label="NDA Date" />
                            </div>

                            <div className="col-lg-4">
                                <Field name="ceDateEnrolled" component={FieldRBDatePicker} horizontal label="Continuous Evaluation Date (Enrolled)" />
                            </div>

                            <div className="col-lg-4">
                                <Field name="ceStatus" component={FieldRBInput} horizontal label="Continuous Evaluation Status" />
                            </div>

                            <div className="col-lg-4">
                                <Field name="determination" component={FieldRBInput} horizontal label="Determination" />
                            </div>

                            <div className="col-lg-4">
                                <Field name="polygraph.date" component={FieldRBDatePicker} horizontal label="Polygraph Date" />
                            </div>

                            <div className="col-lg-4">
                                <Field name="polygraph.type" component={FieldRBInput} horizontal label="Polygraph Type" />
                            </div>

                            <div className="col-lg-4">
                                <Field name="polygraph.agency" component={FieldRBInput} horizontal label="Polygraph Agency" />
                            </div>


                        </div>
                    </Card.Body>

                    <Card.Footer>
                        <ButtonToolbar>
                            <Button variant="primary" type="submit">Save</Button>
                            <Button type="button" onClick={this.cancelUpdate}>Cancel</Button>
                        </ButtonToolbar>
                    </Card.Footer>
                    {/*<Field name="sciAccesses" component={FieldRBSelect} horizontal label="SCI Access"*/}
                    {/*       options={Array.of('NATO', 'COMSEC', 'CNWDI', 'SI', 'TK', 'G', 'H', 'N/A','')}/>*/}
                </Card>

            </Form>
        )
    }
}

function validate(values) {
    const errors = {
        polygraph: {}
    };
    if (values.polygraph && (values.polygraph.date || values.polygraph.type || values.polygraph.agency)){
        if (!values.polygraph.date || values.polygraph.date === ''){
            errors.polygraph.date = "Enter the polygraph date!";
        }
        if (!values.polygraph.type || values.polygraph.type === ''){
            errors.polygraph.type = "Enter the polygraph type!";
        }
        if (!values.polygraph.agency || values.polygraph.agency === ''){
            errors.polygraph.agency = "Enter the polygraph agency!";
        }
    }
    return errors;
}

function mapStateToProps({person}) {
    return {
        subject: person
    }
}

SubjectClearanceUpdate.propTypes = {
    subjectId: PropTypes.string.isRequired,
    onFinish: PropTypes.func.isRequired
};

export default reduxForm({
    validate, // same as validate:validate
    form: 'SubjectClearanceUpdateForm',
    initialValues: {
    }
})(
    connect(mapStateToProps, {fetchPerson, updateSubjectClearanceDetails, showSuccessGrowl})(SubjectClearanceUpdate)
);