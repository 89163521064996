import React, {Component, Fragment} from "react";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";

export class SubjectReportedOnByOthersDetails extends Component {
    getReportsByOthers() {
        return this.props.reportedByOthersReportables.map((reportedByOthersReportable) => {
            return <tr key={reportedByOthersReportable.id}>
                <td>{reportedByOthersReportable.reportSummary}</td>
                <td>{reportedByOthersReportable.reportDetails}</td>
                <td>{reportedByOthersReportable.reportedPersonFullName}</td>
                <td>{reportedByOthersReportable.reportedPersonEmail}</td>
                <td>{reportedByOthersReportable.reportedPersonPhone}</td>
                <td>{reportedByOthersReportable.reportedPersonEmployer}</td>
                <td>{reportedByOthersReportable.reportedPersonDepartment}</td>
                <td>{reportedByOthersReportable.reportedBy?reportedByOthersReportable.reportedBy:'Anonymous'}</td>
            </tr>
        })
    }

    render() {
        const {reportedByOthersReportables} = this.props;

        console.log('Other reportables are ', reportedByOthersReportables)
        if (!reportedByOthersReportables || reportedByOthersReportables.length === 0) {
            return <Alert variant="danger">No reports by others</Alert>
        }

        console.log('Reportables is ', reportedByOthersReportables)
        return (
            <Fragment>
                <Table>
                    <thead>
                    <tr>
                        <th>Summary</th>
                        <th>Details</th>
                        <th>Name</th>
                        <th>Email Address</th>
                        <th>Phone Number</th>
                        <th>Organization</th>
                        <th>Department</th>
                        <th>Reported By</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.getReportsByOthers()}
                    </tbody>
                </Table>
            </Fragment>
        );
    }
}