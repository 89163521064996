import React, {Component, Fragment} from "react";
import {DateUtils} from "../../../../../actions/common_utils";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";

export class SubjectForeignAdoptionDetails extends Component {
    getForeignChildAdoptions() {
        return this.props.foreignChildAdoptions.map((adoption) => {
            console.log("Adoption is ", adoption)
            return <tr key={adoption.id}>
                <td>{adoption.adoptionCountry}</td>
                <td>{adoption.adoptionAgencyName}</td>
                <td>{adoption.foreignGovtOrgs}</td>
                <td>{adoption.foreignCountryRelationshipForParents}</td>
                <td>{adoption.foreignTravelRequired?'Yes':'No'}</td>
                <td>{adoption.startDate ? DateUtils.getDateString(adoption.startDate) : "Unknown"}</td>
            </tr>
        })
    }

    render() {
        const {foreignChildAdoptions} = this.props;

        if (!foreignChildAdoptions || foreignChildAdoptions.length === 0) {
            return <Alert variant="danger">No Foreign Child Adoptions</Alert>
        }

        return (
            <Fragment>
                <Table>
                    <thead>
                    <tr>
                        <th>Adoption Country</th>
                        <th>Agency Name</th>
                        <th>Foreign Govt. Involved</th>
                        <th>Parents relationship to adoption country</th>
                        <th>Required Foreign Travel</th>
                        <th>Adoption Date</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.getForeignChildAdoptions()}
                    </tbody>
                </Table>
            </Fragment>
        );
    }
}