import React, {Component, Fragment} from "react";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import {DateUtils} from "../../../../../actions/common_utils";

export class SubjectMediaContactDetails extends Component {
    getMediaContacts() {
        return this.props.mediaContacts.map((contact) => {
            return <tr key={contact.id}>
                <td>{contact.contactDate ? DateUtils.getDateString(contact.contactDate) : "Unknown"}</td>
                <td>{contact.mediaOutletName}</td>
                <td>{contact.firstName} {contact.lastName}</td>
                <td>{contact.contactReason}</td>
                <td>{contact.classifiedInformation ? "Yes" : "No"}</td>
            </tr>
        })
    }

    render(){
        const {mediaContacts} = this.props;

        if (!mediaContacts || mediaContacts.length === 0) {
            return <Alert variant="danger">No Media Contacts!</Alert>
        }

        return (
            <Fragment>
                <Table>
                    <thead>
                    <tr>
                        <th>Contact Date</th>
                        <th>Media Outlet Name</th>
                        <th>Media Representative Name</th>
                        <th>Contact Reason</th>
                        <th>Classified or Prohibited Information</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.getMediaContacts()}
                    </tbody>
                </Table>
            </Fragment>
        )
    }
}