/**
 * Created by pshivaraman on 7/30/18.
 */

import React, {Component} from "react";
import {fetchCurOrg, fetchManagedOrgs, fetchOrg} from "../../../../actions/org_actions";
import {CUR_ORG} from "../../../../actions/session_constants";
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {fetchUsersByRole, removeServiceCenterToOrg} from "../../../../actions/user_actions";
import UserAssignServiceCenterModal from "../../../../components/user/user_assign_svcctr_modal";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";

class ServiceCenterOrgDetail extends Component {
    constructor(props) {
        super(props);

        this.unassignSCP = this.unassignSCP.bind(this);
        this.onAssignSCP = this.onAssignSCP.bind(this);
        this.onLoad = this.onLoad.bind(this);
    }

    componentDidMount() {
        this.onLoad();
    }

    onLoad() {
        this.props.fetchOrg(this.props.match.params.id);
        this.props.fetchUsersByRole("ORG", this.props.match.params.id, "SERV_CTR", false);
    }

    unassignSCP(userId) {
        this.props.removeServiceCenterToOrg(sessionStorage.getItem(CUR_ORG), this.props.match.params.id, userId, () => {
            this.onLoad();
        });
    }

    onAssignSCP() {
        this.onLoad();
    }

    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/org/dash" onClick={() => fetchCurOrg()}>
    //                 Organization {this.props.cur_org.name}
    //             </BreadcrumbLink>
    //             <BreadcrumbLink to="/org/dash/serviceCenter"
    //                             onClick={() => fetchManagedOrgs(sessionStorage.getItem(CUR_ORG))}>
    //                 Service Center
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 Managed Organization {this.props.org.name}
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    getServiceCenterPersonnel() {
        const {users} = this.props;
        if (users) {
            return users.map((user) => {
                const {subject} = user;
                return (
                    <ListGroupItem key={user.id}>
                        <Link to={`/org/dash/subject/${subject.id}`}>
                            {subject.personFirstName} {subject.personLastName}
                        </Link>{' '}
                        <Link to={`/org/dash/user/${user.id}`}>
                            ({user.userName})
                        </Link>
                        <Button variant={'link'} className="close" onClick={() => this.unassignSCP(user.id)}>
                            <span aria-hidden="true" title={"Un-assign Service Center Personnel"} style={{padding: 10}}
                                  aria-label="Un-assign Service Center Personnel"><i class="fa fa-times-circle"></i></span>
                        </Button>
                    </ListGroupItem>
                )
            });
        }
    }

    render() {
        const {org, users} = this.props;

        if (!org || !users) {
            return <div>Loading...</div>;
        }

        return (
            <div>
                {/* {this.getBreadCrumb()} */}
                <div className="pull-right">
                    <UserAssignServiceCenterModal existingUsers={users} onAssignUser={this.onAssignSCP}
                                                  managedOrgId={this.props.match.params.id}/>
                </div>
                <h4>Service Center Personnel for {org.name}</h4>
                <ListGroup>
                    {this.getServiceCenterPersonnel()}
                </ListGroup>

            </div>
        )
    }
}

function mapStateToProps({org, users}) {
    return {
        org,
        users
    }
}

export default connect(mapStateToProps, {
    fetchUsersByRole,
    fetchOrg,
    removeServiceCenterToOrg
})(ServiceCenterOrgDetail);