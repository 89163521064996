import React, {Component, Fragment} from "react";
import {DateUtils} from "../../../../../actions/common_utils";
import Alert from "react-bootstrap/Alert";
import filterFactory, {textFilter} from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import SubjectForeignTravelDetailsModal from "../../../../../components/subject/subject_foreign_travel_details_modal";
import PropTypes from "prop-types";

export class SubjectForeignTravelDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
        }
    }

    open = () => {
        this.setState({showModal: true});
    }

    close = () => {
        this.setState({
            showModal: false,
        });
    }

    render() {
        const {foreignTravels} = this.props;
        const {showModal} = this.state;

        if (!foreignTravels || foreignTravels.length === 0) {
            return <Alert variant="danger">No Foreign Travels!</Alert>
        }

        const columns = [{
            dataField: 'departureDate',
            text: 'Departure Date',
            formatter: (cell, row) => cell ? DateUtils.getDateString(cell) : "Unknown",
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'arrivalDate',
            text: 'Arrival Date',
            formatter: (cell, row) => cell ? DateUtils.getDateString(cell) : "Unknown",
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'countriesVisited',
            text: 'Countries Visited',
            // formatter: (cell, row) => cell && cell.isArray ? cell.join(): cell,
            formatter: (cell, row) => cell.join(", "),
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'travelReason',
            text: 'Reason for Travel',
            formatter: (cell, row) => cell,
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'actions',
            text: 'Actions',
            formatter: (cell, row) => {
                return (
                    <ButtonToolbar style={{flexWrap: "nowrap"}}>
                        <SubjectForeignTravelDetailsModal foreignTravelDetail={row} allowCancel={row.postTravelResponse === null}/>
                        {/*<Button className="close" onClick={() => this.removeSubject(row.id)}>*/}
                        {/*<span aria-hidden="true" style={{padding: 10}} aria-label="Remove Subject From Facility">*/}
                        {/*&times;*/}
                        {/*</span>*/}
                        {/*</Button>*/}
                    </ButtonToolbar>
                )
            }
        }];

        return (
            <Fragment>
                <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="id" data={this.props.foreignTravels}
                                columns={columns} filter={filterFactory()}/>
            </Fragment>
        );
    }
}

