import React, {Component, Fragment} from "react";
import {withRouter} from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from 'react-bootstrap-table2-paginator';
import PropTypes from "prop-types";
import {connect} from "react-redux";

class ScreeningReportItemListComp extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        const {reportItems} = this.props;

        const columns = [
            {
                dataField: "type",
                text: "Type",
                formatter: (cell, row) => cell,
                sort: true,
                title: this.titleFunction
            },
            {
                dataField: "status",
                text: "Status",
                formatter: (cell, row) => cell,
                sort: true,
                title: this.titleFunction
            },
            {
                dataField: "result",
                text: "Result",
                sort: true,
                title: this.titleFunction,
                formatter: (cell, row) => cell,
            }
        ];

        const pagination = paginationFactory({
            sizePerPageList: [
                {text: '10', value: 10},
                {text: '20', value: 20},
                {text: '30', value: 30}
            ]
        });

        return (
            <Fragment>
                <h3>Report Items</h3>
                {reportItems &&
                <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="id" data={reportItems}
                                columns={columns} pagination={pagination}
                                filter={filterFactory()}/>
                }
            </Fragment>
        )
    }
}

export const ScreeningReportItemList = withRouter(connect(null, null)(ScreeningReportItemListComp));

ScreeningReportItemList.propTypes = {
    reportItems: PropTypes.array.isRequired
};

