import React, {Component, Fragment} from "react";
import {ClearanceMapping, DateUtils} from "../../../../../actions/common_utils";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

class SubjectClearanceDetails extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {subject} = this.props;

        return (
            <Fragment>
                <Row className={'mb-3'}>
                    <Col as={Form.Label} sm={3} md={2}>
                        Eligibility
                    </Col>
                    <Col sm={3} md={4}>
                        {subject.eligibility}
                    </Col>
                    <Col as={Form.Label} sm={3} md={2}>
                        Eligibility Date
                    </Col>
                    <Col sm={3} md={4}>
                        {DateUtils.getDateString(subject.eligibilityDate)}
                    </Col>
                </Row>
                <Row className={'mb-3'}>
                    <Col as={Form.Label} sm={3} md={2}>
                        Non-SCI Access
                    </Col>
                    <Col sm={3} md={4}>
                        {ClearanceMapping.mapNonSciAccessForUI(subject.nonSciAccesses)}
                    </Col>
                    <Col as={Form.Label} sm={3} md={2}>
                        SCI Access
                    </Col>
                    <Col sm={3} md={4}>
                        {subject.sciAccesses}
                    </Col>
                </Row>
                <Row className={'mb-3'}>
                    <Col as={Form.Label} sm={3} md={2}>
                        Special Accesses
                    </Col>
                    <Col sm={3} md={4}>
                        {subject.specialAccesses}
                    </Col>
                </Row>
                <Row className={'mb-3'}>
                    <Col as={Form.Label} sm={3} md={2}>
                        Investigation Type
                    </Col>
                    <Col sm={3} md={4}>
                        {subject.investigationType}
                    </Col>
                    <Col as={Form.Label} sm={3} md={2}>
                        Investigation Begin Date
                    </Col>
                    <Col sm={3} md={4}>
                        {DateUtils.getDateString(subject.investigationBeginDate)}
                    </Col>
                </Row>
                <Row className={'mb-3'}>
                    <Col as={Form.Label} sm={3} md={2}>
                        Investigation Close Date
                    </Col>
                    <Col sm={3} md={4}>
                        {DateUtils.getDateString(subject.investigationDate)}
                    </Col>
                    <Col as={Form.Label} sm={3} md={2}>
                        NDA Date
                    </Col>
                    <Col sm={3} md={4}>
                        {DateUtils.getDateString(subject.ndaDate)}
                    </Col>
                </Row>
                <Row className={'mb-3'}>
                    <Col as={Form.Label} sm={3} md={2}>
                        Continuous Evaluation Date (Date Enrolled)
                    </Col>
                    <Col sm={3} md={4}>
                        {DateUtils.getDateString(subject.ceDateEnrolled)}
                    </Col>
                    <Col as={Form.Label} sm={3} md={2}>
                        Continuous Evaluation Status
                    </Col>
                    <Col sm={3} md={4}>
                        {subject.ceStatus}
                    </Col>
                </Row>
                <Row className={'mb-3'}>
                    <Col as={Form.Label} sm={3} md={2}>
                        Determination
                    </Col>
                    <Col sm={3} md={4}>
                        {subject.determination}
                    </Col>
                </Row>
                {subject.polygraph &&
                <Row className={'mb-3'}>
                    <Col as={Form.Label} sm={3} md={2}>
                        Polygraph Type
                    </Col>
                    <Col sm={3} md={4}>
                        {subject.polygraph.type}
                    </Col>
                    <Col as={Form.Label} sm={3} md={2}>
                        Polygraph Date
                    </Col>
                    <Col sm={3} md={4}>
                        {DateUtils.getDateString(subject.polygraph.date)}
                    </Col>
                    <Col as={Form.Label} sm={3} md={2}>
                        Polygraph Agency
                    </Col>
                    <Col sm={3} md={4}>
                        {subject.polygraph.agency}
                    </Col>
                </Row>
                }
            </Fragment>
        )
    }
}

SubjectClearanceDetails.propTypes = {
    subject: PropTypes.object.isRequired
};

export default SubjectClearanceDetails;