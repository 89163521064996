import React from "react";
import {connect} from "react-redux";
import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {CUR_ORG} from "../../../../actions/session_constants";
import {
    fetchActiveOrgPersonsWithInvalidEmail,
    fetchActivePersonsByOrg,
    generateReport
} from "../../../../actions/subject_actions";
import Col from "react-bootstrap/Col";
import {showErrorGrowl, showSuccessGrowl} from "../../../../actions/msg_actions";
import {Field, Formik} from "formik";
import * as Yup from "yup";
import {Card, Form, Modal} from "react-bootstrap";
import {CommonUtils} from "../../../../actions/common_utils";
import {fetchAssets} from "../../../../actions/asset_actions";
import {fetchDocControls, fetchForeignTravels} from "../../../../actions/doc_control_actions";
import reportFieldMap from '../../../../mappingFiles/reportFields.json';
import {FormikFieldDualListBox} from "../../../../components/formik/formik_field_duallistbox";
import {
    ASSET_REPORT, CUSTOM_REPORT, DOC_CONTROL_REPORT, FOREIGN_TRAVEL_REPORT, REQD_DOCS_DUE_REPORT,
    SUBJECT_COMPLIANCE_REPORT,
    SUBJECT_EQIP_STATUS_REPORT, SUBJECT_INVALID_EMAIL_REPORT,
    SUBJECT_REPORT
} from "../../../../actions/action_constants";
import {getReqdDocsDueReport} from "../../../../actions/doc_actions";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link";


class GenerateReport extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            reportTypes: [SUBJECT_REPORT, SUBJECT_COMPLIANCE_REPORT, SUBJECT_EQIP_STATUS_REPORT, SUBJECT_INVALID_EMAIL_REPORT, ASSET_REPORT, DOC_CONTROL_REPORT, REQD_DOCS_DUE_REPORT, FOREIGN_TRAVEL_REPORT, CUSTOM_REPORT].sort()
        }
    }

    componentDidMount() {
        // get the subjects
        this.props.fetchActivePersonsByOrg(sessionStorage.getItem(CUR_ORG));
    }

    getInvalidEmailSubjects = async () => {
        try {
            const subjects = await fetchActiveOrgPersonsWithInvalidEmail();
            console.log('Invalid email subjects are ', subjects);
            this.setState({subjects});
        } catch (error) {
            this.props.showErrorGrowl("Unexpected error occurred", "Error fetching subjects with invalid email.");
            console.log(error);
            return [];
        }
        ;
    }

    fetchAssetAsync = async () => {
        try {
            let assets = await fetchAssets();
            this.setState({
                assets
            })
            console.log('Assets fetched');
        } catch (error) {
            this.props.showErrorGrowl("Unexpected error occurred", "Error fetching assets");
            console.log(error);
            return [];
        }
    }

    fetchDocControlsAsync = async () => {
        try {
            let docControls = await fetchDocControls();
            this.setState({
                docControls
            })
            console.log('Doc Controls fetched');
        } catch (error) {
            this.props.showErrorGrowl("Unexpected error occurred", "Error fetching document control list");
            console.log(error);
            return [];
        }
    }

    fetchForeignTravelsAsync = async () => {
        try {
            let foreignTravels = await fetchForeignTravels();
            this.setState({
                foreignTravels
            })
            console.log('Foreign Travels fetched', foreignTravels);
        } catch (error) {
            this.props.showErrorGrowl("Unexpected error occurred", "Error fetching foreign travels list");
            console.log(error);
            return [];
        }
    }

    extractFieldMap = (customFields, mapName) => {
        let returnObj = {};
        if (customFields) {
            for (let selField of customFields) {
                returnObj[selField] = reportFieldMap[mapName][selField];
            }
        }
        return returnObj;
    }
    onSubmit = async (values, actions) => {
        const {subjects, assets, docControls, foreignTravels} = this.state;
        console.log(values);
        if (values.reportType === SUBJECT_REPORT) {
            this.props.generateReport(reportFieldMap['subjectGeneralReportMap'], values.reportType, this.props.subjects);
        } else if (values.reportType === SUBJECT_COMPLIANCE_REPORT) {
            this.props.generateReport(reportFieldMap['subjectComplianceReportMap'], values.reportType, this.props.subjects);
        } else if (values.reportType === SUBJECT_EQIP_STATUS_REPORT) {
            this.props.generateReport(reportFieldMap['subjectEqipReportMap'], values.reportType, this.props.subjects);
        } else if (values.reportType === SUBJECT_INVALID_EMAIL_REPORT) {
            // filter out the valid email subjects
            let invalidEmailSubjects = subjects;
            console.log('Invalid email subjects are ', invalidEmailSubjects);
            this.props.generateReport(reportFieldMap['subjectInvalidEmailReportMap'], values.reportType, this.props.subjects);
        } else if (values.reportType === ASSET_REPORT) {
            this.props.generateReport(reportFieldMap['assetMap'], values.reportType, assets);
        } else if (values.reportType === DOC_CONTROL_REPORT) {
            this.props.generateReport(reportFieldMap['docControlMap'], values.reportType, docControls);
        } else if (values.reportType === FOREIGN_TRAVEL_REPORT) {
            this.props.generateReport(reportFieldMap['foreignTravelCustomFieldsMap'], values.reportType, foreignTravels);
        } else if (values.reportType === REQD_DOCS_DUE_REPORT) {
            const reqdDocsDueReport = await getReqdDocsDueReport();
            reqdDocsDueReport.sort((a, b) => a.daysToExpire - b.daysToExpire);
            const newReportValues = reqdDocsDueReport.map(r => {
                if (r.daysToExpire < 0){
                    r.daysToExpire = "Past Due!";
                }

                return r;
            });
            this.props.generateReport(reportFieldMap['reqdDocsDueMap'], values.reportType, newReportValues);
        } else if (values.reportType === CUSTOM_REPORT) {
            this.props.generateReport(this.extractFieldMap(values.customFields, 'subjectCustomFieldsMap'), values.reportType, this.props.subjects);
        }
        actions.setSubmitting(false);
        this.props.history.push("/org/dash/subject");
        this.props.showSuccessGrowl("Report generated successfully", "Report generated successfully. Please check your downloads folder");
    }

    fetchEntities = (event) => {
        if (event.target.value === SUBJECT_INVALID_EMAIL_REPORT) {
            this.getInvalidEmailSubjects();
        } else if (event.target.value === ASSET_REPORT) {
            this.fetchAssetAsync();
        } else if (event.target.value === DOC_CONTROL_REPORT) {
            this.fetchDocControlsAsync();
        } else if (event.target.value === FOREIGN_TRAVEL_REPORT) {
            this.fetchForeignTravelsAsync();
        }
    }

    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/org/dash" onClick={() => fetchCurOrg()}>
    //                 Organization {this.props.cur_org.name}
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 Reports
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    render() {
        let {subjects} = this.props;
        if (!subjects) {
            return <div>Loading...</div>;
        }
        const avail_custom_fields = Object.keys(reportFieldMap['subjectCustomFieldsMap']).map((keyField) => {
            return {
                value: keyField,
                label: keyField
            }
        });

       

        return (
            <React.Fragment>
                 {/* {this.getBreadCrumb()} */}
                <Formik
                    initialValues={{
                        "reportType": SUBJECT_REPORT,
                        "customFields": []
                    }}
                    enableReinitialize={true}
                    onSubmit={this.onSubmit}
                    validationSchema={
                        Yup.object().shape({
                            reportType: Yup.string().required('Select a Report Type'),
                            customFields: Yup.array().required('Select at least one field to report').test(
                                'required-custom-fields-test',
                                'Please select one or more custom fields',
                                function (value) {
                                    return (this.parent.reportType !== CUSTOM_REPORT) || (value && (value.length > 0));
                                }
                            ),
                        })
                    }
                >
                    {({
                          isSubmitting,
                          values,
                          handleChange,
                          setFieldValue,
                          setFieldTouched,
                          handleSubmit,
                          touched,
                          errors,
                          handleBlur
                      }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Card className="border-blue">
                                <Card.Header>Subject Reporting</Card.Header>
                                <Card.Body>
                                    <Form.Row>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Report Type</Form.Label>
                                            <Form.Control required as="select" name={'reportType'}
                                                onChange={(value, e) => {
                                                    this.fetchEntities(value);
                                                    handleChange(value);
                                                }}
                                                onBlur={handleBlur}
                                                placeholder={'Select report desired'}
                                                isInvalid={touched.reportType && !!errors.reportType}
                                                value={values.reportType}
                                            >
                                                {CommonUtils.getOptionsForSelect(CommonUtils.forDropDown(this.state.reportTypes))}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.reportType}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    {values.reportType === CUSTOM_REPORT &&
                                        <Form.Row>
                                            <Form.Group as={Col} md="6" controlId={'customFields'}>
                                                <Form.Label>Select fields for Report</Form.Label>
                                                <Field
                                                    id="customFields"
                                                    name="customFields"
                                                    component={FormikFieldDualListBox}
                                                    options={avail_custom_fields}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.customFields}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                    }

                                </Card.Body>
                                <Card.Footer>
                                    <ButtonToolbar>
                                        <Button variant="primary" type="submit"
                                            disabled={isSubmitting}>Generate</Button>
                                    </ButtonToolbar>
                                </Card.Footer>


                            </Card>


                        </Form>
                    )}
                </Formik>
            </React.Fragment>
        );
    }
}

function mapStateToProps({subjects}) {
    return {
        subjects
    };
}

export default connect(mapStateToProps, {
    fetchActivePersonsByOrg,
    showSuccessGrowl,
    generateReport,
    showErrorGrowl
})(GenerateReport);