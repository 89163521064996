/**
 * Created by shiva on 5/29/18.
 */

import React from "react"
import {connect} from "react-redux"
import {showErrorGrowl, showSuccessGrowl} from "../../../../actions/msg_actions";
import {fetchCurOrg} from "../../../../actions/org_actions"
import {terminateSubjects} from "../../../../actions/person_csvupload_action"
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link"
import Breadcrumb from "react-bootstrap/Breadcrumb";
import BootstrapTable from "react-bootstrap-table-next";
import {textFilter} from "react-bootstrap-table2-filter";
import {Col, Container, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Modal from "react-bootstrap/Modal";

class SubjectRemovalList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: [],
            initializeSelect: false
        }
    }

    componentDidMount() {
    }

    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/org/dash" onClick={() => fetchCurOrg()}>
    //                 Organization {this.props.cur_org.name}
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 Subjects
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    cancelRemoval = () => {
        this.props.history.push("/org/dash/subject");
    }
    removeSubjects = () => {
        const {selected} = this.state;
        if (selected && selected.length !== 0) {
            let filteredSubjects = this.props.subjects.filter(x => selected.includes(x.socialSecurityNumber));
                this.props.terminateSubjects(filteredSubjects).then(() => {
                this.props.history.push("/org/dash/subject");
                this.props.showSuccessGrowl("Subjects Removed Successfully", "The Subjects were removed successfully!")
            }).catch((message) => {
                this.props.showErrorGrowl("Error", message);
            });
        } else {
            this.props.showErrorGrowl("No Subjects Selected", "Select at least one Subject.");
        }
    }

    render() {
        const {subjects} = this.props;
        if (!subjects){
            return <div>Loading...</div>
        }
        console.log('Subjects got are ', subjects);
        const selectRow = {
            mode: 'checkbox',
            clickToSelect: true,
            selected: this.state.selected,
            onSelect: (row, isSelect, rowIndex, e) => {
                if (isSelect) {
                    this.setState(() => ({
                        selected: [...this.state.selected, row.socialSecurityNumber]
                    }));
                } else {
                    this.setState(() => ({
                        selected: this.state.selected.filter(x => x !== row.socialSecurityNumber)
                    }));
                }
            },
            onSelectAll: (isSelect, rows) => {
                const ids = rows.map(r => r.socialSecurityNumber);
                if (isSelect) {
                    this.setState(() => ({
                        selected: ids
                    }));
                } else {
                    this.setState(() => ({
                        selected: []
                    }));
                }
            }
        };

        const columns = [{
            dataField: 'personFirstName',
            text: 'First Name',
            formatter: (cell, row) => cell,
            sort: false
        }, {
            dataField: 'personLastName',
            text: 'Last Name',
            formatter: (cell, row) => cell,
            sort: false
        }, {
            dataField: 'socialSecurityNumber',
            text: 'SSN',
            formatter: (cell, row) => cell,
            sort: false
        }, {
            dataField: 'stateOfBirth',
            text: 'State of Birth',
            formatter: (cell, row) => cell,
            sort: false
        }, {
            dataField: 'countryOfBirth',
            text: 'Country of Birth',
            formatter: (cell, row) => cell,
            sort: false
        }, {
            dataField: 'affiliations',
            text: 'Data Source(s)',
            formatter: (cell, row) => cell,
            sort: false
        }];

        return (
            <div>
                {/* {this.getBreadCrumb()} */}
                <div className="pull-right">
                    <Container>
                        <Row>
                            <Col md={"auto"}>
                                <ButtonToolbar>
                                    <Button variant="primary" onClick={this.removeSubjects}>Remove Subjects</Button>
                                    <Button variant="danger" onClick={this.cancelRemoval}>Cancel</Button>
                                </ButtonToolbar>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <h3>Subjects missing from the prior upload. Select to Remove (Ignore otherwise)</h3>
                <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="socialSecurityNumber"
                                data={subjects} columns={columns}
                                selectRow={selectRow} wrapperClasses={"table-responsive"}/>

            </div>
        );

    }
}

function mapStateToProps({subjects_for_removal}) {
    return {
        subjects: subjects_for_removal
    }
}

export default connect(mapStateToProps, {
    terminateSubjects,
    showSuccessGrowl,
    showErrorGrowl
})(SubjectRemovalList);

