import React, {Component, Fragment} from "react";
import filterFactory, {textFilter} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import BootstrapTable from "react-bootstrap-table-next";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Field, Formik} from "formik";
import * as Yup from "yup";
import {Card, Col, Form, Modal, Button, Alert} from "react-bootstrap";
import {
    AVAILABLE_COUNTRIES,
    CommonUtils,
    DateUtils,
    MARRIED_STATUS,
    SINGLE_STATUS
} from "../../../../../actions/common_utils";
import DeleteConfirmationModal from "../../../../../components/delete_confirm_modal";
import {ConditionalDisplay, isHROnlyRole, isReadOnlyRole} from "../../../../util/shouldDisplay";
import {NewHireSearchModal} from "./newhire_search_modal";
import {FormikDatePickerComponent} from "../../../../../components/formik/formik_date_picker";
import MaskedInput from "react-input-mask";
import {getEmployeeTypes} from "../../../../../actions/fieldValue_actions";
import {showErrorGrowl, showSuccessGrowl} from "../../../../../actions/msg_actions";
import {FormikFieldDualListBox} from "../../../../../components/formik/formik_field_duallistbox";
import {CUR_ORG} from "../../../../../actions/session_constants";
import {createNewHireRequest} from "../../../../../actions/new_hire_actions";
import {getActiveOrgContractsAsync, getOrgContractsAsync} from "../../../../../actions/contract_actions";
import {getCitizenshipAcquiredTypes} from '../../../../../actions/fieldValue_actions'
import {getSubjectDob, getSubjectSsn} from "../../../../../actions/subject_actions";


class NewHireComp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            newHires: [],
            showAddNewHireForm: false,
            disableSubmit: false,
            citizenshipTypes:[]
        }
    }

    onHireAdd = (values, actions) => {
        
        actions.setSubmitting(false);

        let {newHires} = this.state;
        if (values.personBirthDate){
            values.personBirthDate = DateUtils.toYYYYMMDD(values.personBirthDate);
        }
        if(!values.citizenshipAcquiredType && values.bornStatus){
            values.citizenshipAcquiredType = 'BORN_IN_TERRITORY'
        }
        newHires.push(values);
        this.setState({showAddNewHireForm: false});
    }

    onInitiateNewHire = async () => {
        this.setState({disableSubmit: true});
        console.log('New hires ', this.state.newHires)
        
        try {

            await createNewHireRequest(this.state.newHires);
            this.props.showSuccessGrowl("New Hire Request", "Successfully created New Hire Request");
            this.props.history.push("/org/dash/task");
        } catch (e) {
            console.log(e);
            this.props.showErrorGrowl("Unable to initiate new Hire process", "Unexpected error");
        }
        this.setState({disableSubmit: false});
    }
  
    componentDidMount() {
        this.getEmpTypes();
        this.getContracts();
        this.getCitizenshipTypes()
    }

    getEmpTypes = async () => {
        const response = await getEmployeeTypes();
        console.log('Response of employee types are ', response.data);
        this.setState({
            avail_employee_types: response.data.filter((empType)=>empType.onBoardingType).map((empType) => {
                return {
                    value: empType.id,
                    label: empType.longName
                }
            })
        });
    }

    getContracts = async () => {
        const contracts = await getActiveOrgContractsAsync((error) => {
            this.props.showErrorGrowl("Cannot fetch contracts available", error.message);
        });
        if (contracts) {
            console.log('Contracts are ', contracts)
            this.setState({
                avail_contracts: contracts.map((contract) => {
                    return {
                        value: contract.id,
                        label: contract.name
                    }
                })
            });
            console.log('Available Contracts are ', this.state.avail_contracts)
        }
    }

    getCitizenshipTypes = async () =>{
        const response = await getCitizenshipAcquiredTypes();
        let newCitizenshipTypes = []  
         response.data.forEach((citizenType) => {
              if (citizenType.displayName.toLowerCase() !== "user") {
                newCitizenshipTypes.push({
                  id: citizenType.id,
                  name: citizenType.displayName,
                });
              }
            });
            this.setState({
                citizenshipTypes: newCitizenshipTypes
            });
          }


    open = () => {
        this.setState({showAddNewHireForm: true});
    }
    close = () => {
        this.setState({showAddNewHireForm: false});
    }

    handleCancel = () => {
        window.history.back();
    }

    removeNewHire = (newHire) => {
        let {newHires} = this.state;
        let index = newHires.indexOf(newHire);
        newHires.splice(index, 1);
        this.setState({newHires})
    }

    getSensData = (subject) => {
        return new Promise((resolve)=> {
            getSubjectDob(subject.id, (resp) => {
                subject.personBirthDate = DateUtils.getDateString(resp.data);
                getSubjectSsn(subject.id, (resp) => {
                    subject.socialSecurityNumber = resp.data;
                    resolve();
                });
            });
        })
    }

    onSelectSubject = (subject) => {
        console.log('Subject b4 is ', subject.countryOfBirth);
        let {showErrorGrowl} = this.props;
        // Check if subject already exists within this org and if so no they cant be a new hire
        let orgFound = false;
        if (subject.orgs) {
            for (let eachOrg of subject.orgs) {
                if (eachOrg.active && eachOrg.orgId === sessionStorage.getItem(CUR_ORG)) {
                    orgFound = true;
                    break;
                }
            }
        }
        let abbrToCountryMap = CommonUtils.getCountryAbbrToFullNameMap();
        console.log('Country to name is ', abbrToCountryMap)
        subject.countryOfBirth = abbrToCountryMap.get(subject.countryOfBirth);
        this.getSensData(subject).then(()=>{
            console.log('Subject after is ', subject);
            if (orgFound){
                showErrorGrowl("Subject Exists in Organization", "Subject already is a member of this organization. Cannot be added again");
                return;
            }
            this.setState({
                selectedSubject: subject,
                showAddNewHireForm: true
            });
        });
    }
    onCreateSubject = (values) => {
        console.log('Values are ', values);
        let subject = {
            socialSecurityNumber: values.ssn,
            countryOfBirth: "United States",
            gender: 'Male',
            maritalStatusCode: 'Single',
            citizenshipAcquiredType: 'BORN_IN_TERRITORY'
        };
        this.setState({
            selectedSubject: subject,
            showAddNewHireForm: true
        });
    }

    render() {

        const {showAddNewHireForm, newHires, selectedSubject, avail_employee_types, avail_contracts} = this.state;
        console.log(selectedSubject,"selectedSubject")
        const columns = [{
            dataField: 'personFirstName',
            text: 'First Name',
            sort: true,
            filter: (newHires && newHires.length > 0)?textFilter():''
        }, {
            dataField: 'personLastName',
            text: 'Last Name',
            sort: true,
            filter: (newHires && newHires.length > 0)?textFilter():''
        }, {
            dataField: 'countryOfBirth',
            text: 'Country of Birth',
            sort: true,
            filter: (newHires && newHires.length > 0)?textFilter():''
        }, {
            dataField: 'workEmailAddress',
            text: 'Email Address',
            sort: true,
            filter: (newHires && newHires.length > 0)?textFilter():''
        }, {
            dataField: 'actions',
            text: 'Actions',
            formatter: (cell, row) => {
                return (
                    <Fragment>
                        {
                        <Button className="close close_icon" onClick={() => this.removeNewHire(row)}>
                            <span aria-hidden="true" title={"Remove Subject From New Hire Process"} style={{padding: 10}} aria-label="Remove Subject From Hiring">
                            <i className="fa fa-times-circle" style={{color: "black"}}></i>
                            </span>
                        </Button>
                        }
                    </Fragment>
                )
            }
        }];

        const pagination = paginationFactory({
            sizePerPageList: [
                {text: '10', value: 10},
                {text: '20', value: 20},
                {text: '30', value: 30}
            ]
        });

        return (
            <Fragment>
                <Modal.Body>
                    <Card>
                        <Card.Header>New Hires</Card.Header>
                        <Card.Body>
                            {(!newHires || newHires.length === 0) &&
                            <BootstrapTable bootstrap4={true} hover striped bordered={false}
                                            keyField="socialSecurityNumber"
                                            data={newHires}
                                            columns={columns}
                                            filter={filterFactory()}/>
                            }
                            {(newHires && newHires.length > 0) &&
                            <BootstrapTable bootstrap4={true} hover striped bordered={false}
                                            keyField="socialSecurityNumber"
                                            data={newHires}
                                            columns={columns} pagination={pagination}
                                            filter={filterFactory()}/>
                            }
                            <Modal show={showAddNewHireForm} onHide={this.close} size={'lg'} scrollable={false}>
                                <Formik
                                    initialValues={{
                                        personFirstName: (selectedSubject?selectedSubject.personFirstName:""),
                                        personMiddleName: (selectedSubject?selectedSubject.personMiddleName:""),
                                        personLastName: (selectedSubject?selectedSubject.personLastName:""),
                                        suffix: (selectedSubject?selectedSubject.suffix:""),
                                        personBirthDate: (selectedSubject?DateUtils.getDateString(selectedSubject.personBirthDate):""),
                                        socialSecurityNumber: (selectedSubject?selectedSubject.socialSecurityNumber:""),
                                        cityOfBirth: (selectedSubject?selectedSubject.cityOfBirth:""),
                                        stateOfBirth: (selectedSubject?selectedSubject.stateOfBirth:""),
                                        countryOfBirth: (selectedSubject?selectedSubject.countryOfBirth:""),
                                        gender: (selectedSubject?selectedSubject.gender:""),
                                        homePhone: (selectedSubject?selectedSubject.homePhone:""),
                                        workEmailAddress: "",
                                        employeeTypes: (selectedSubject?selectedSubject.employeeTypes:""),
                                        startDate: (selectedSubject?selectedSubject.startDate:""),
                                        maritalStatusCode: (selectedSubject?selectedSubject.maritalStatusCode:""),
                                        supportingContractIds: (selectedSubject?selectedSubject.supportingContractIds:""),
                                        bornStatus: false,
                                        ...(selectedSubject && selectedSubject.bornStatus ? {
                                        citizenshipAcquiredType: selectedSubject?.selectedSubject?.citizenshipAcquiredType || '',
                                         citizenshipDate: selectedSubject.citizenshipDate || '' } : {})
                                    }}
                                    enableReinitialize={true}
                                    onSubmit={this.onHireAdd}
                                    validationSchema={
                                        Yup.object().shape({
                                            personFirstName: Yup.string().required("Please enter a first name"),
                                            personLastName: Yup.string().required("Please enter a last name"),
                                            personBirthDate: Yup.date().required("Please enter a birth date"),
                                            socialSecurityNumber: Yup.string().required("Please enter an SSN"),
                                            cityOfBirth: Yup.string().required("Please enter the city of birth"),
                                            stateOfBirth: Yup.string().required("Please enter the state of birth"),
                                            countryOfBirth: Yup.string().required("Please enter the country of birth"),
                                            gender: Yup.string().required("Please enter a gender"),
                                            workEmailAddress: Yup.string().required("Please enter an email address"),
                                            employeeTypes: Yup.array().required('Select at least one employee type').test(
                                                'employee-type-test',
                                                'Please select a type',
                                                function (value) {
                                                    return value && (value.length > 0);
                                                }
                                            ),
                                            startDate: Yup.date().required("Please enter a start date"),
                                            maritalStatusCode: Yup.string().required("Please enter a marital status"),
                                            citizenshipDate: Yup.date().when('bornStatus', {
                                                is: true,
                                                then: Yup.date().required("Please enter citizenship date"),
                                                otherwise: Yup.date()
                                            })
                                        
                                        })
                                    }
                                >
                                    {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur}) => (
                                        <Form noValidate onSubmit={handleSubmit}>
                                            <Modal.Body>
                                                <Card>
                                                    <Card.Header>Add New Hire Information</Card.Header>
                                                    <Card.Body>
                                                        <Form.Row>
                                                            <Form.Group as={Col} md="4">
                                                                <Form.Label>First Name</Form.Label>
                                                                <Form.Control required type="text" name={'personFirstName'}
                                                                              onChange={handleChange}
                                                                              onBlur={handleBlur}
                                                                              isInvalid={touched.personFirstName && !!errors.personFirstName}
                                                                              value={values.personFirstName}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.personFirstName}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="4">
                                                                <Form.Label>Middle Name</Form.Label>
                                                                <Form.Control required type="text" name={'personMiddleName'}
                                                                              onChange={handleChange}
                                                                              onBlur={handleBlur}
                                                                              isInvalid={touched.personMiddleName && !!errors.personMiddleName}
                                                                              value={values.personMiddleName}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.personMiddleName}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="4">
                                                                <Form.Label>Last Name</Form.Label>
                                                                <Form.Control required type="text" name={'personLastName'}
                                                                              onChange={handleChange}
                                                                              onBlur={handleBlur}
                                                                              isInvalid={touched.personLastName && !!errors.personLastName}
                                                                              value={values.personLastName}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.personLastName}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Form.Row>
                                                        <Form.Row>
                                                            <Form.Group as={Col} md="4">
                                                                <Form.Label>Suffix</Form.Label>
                                                                <Form.Control required type="text" name={'suffix'}
                                                                              onChange={handleChange}
                                                                              onBlur={handleBlur}
                                                                              isInvalid={touched.suffix && !!errors.suffix}
                                                                              value={values.suffix}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.suffix}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="4" controlId={'personBirthDate'}>
                                                                <Form.Label>Date of Birth</Form.Label>
                                                                <Field
                                                                    id="personBirthDate"
                                                                    name="personBirthDate"
                                                                    component={FormikDatePickerComponent}
                                                                    placeholder={"Date of Birth"}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="4" controlId="socialSecurityNumber">
                                                                <Form.Label>SSN</Form.Label>
                                                                <Form.Control
                                                                    required
                                                                    type={"text"}
                                                                    name="socialSecurityNumber"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    isInvalid={touched.socialSecurityNumber && !!errors.socialSecurityNumber}
                                                                    value={values.socialSecurityNumber}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.socialSecurityNumber}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Form.Row>
                                                        <Form.Row>
                                                            <Form.Group as={Col} md="4">
                                                                <Form.Label>Birth City</Form.Label>
                                                                <Form.Control required type="text" name={'cityOfBirth'}
                                                                              onChange={handleChange}
                                                                              onBlur={handleBlur}
                                                                              isInvalid={touched.cityOfBirth && !!errors.cityOfBirth}
                                                                              value={values.cityOfBirth}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.cityOfBirth}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="4">
                                                                <Form.Label>Birth State</Form.Label>
                                                                <Form.Control required type="text" name={'stateOfBirth'}
                                                                              onChange={handleChange}
                                                                              onBlur={handleBlur}
                                                                              isInvalid={touched.stateOfBirth && !!errors.stateOfBirth}
                                                                              value={values.stateOfBirth}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.stateOfBirth}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="4" controlId={'countryOfBirth'}>
                                                                <Form.Label>Birth Country</Form.Label>
                                                                <Form.Control required as="select" name={'countryOfBirth'}
                                                                              onChange={handleChange}
                                                                              onBlur={handleBlur}
                                                                              isInvalid={touched.countryOfBirth && !!errors.countryOfBirth}
                                                                              value={values.countryOfBirth? values.countryOfBirth:null}
                                                                >
                                                                    {CommonUtils.getOptionsForSelect(CommonUtils.getAvailCountries())}
                                                                </Form.Control>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.countryOfBirth}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Form.Row>
                                                        <Form.Row>
                                                            <Form.Group as={Col} md={"4"} controlId="workEmailAddress">
                                                                <Form.Label>Email Address</Form.Label>
                                                                <Form.Control
                                                                    type="email"
                                                                    name="workEmailAddress"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    placeholder="Work Email Address"
                                                                    isInvalid={touched.workEmailAddress && !!errors.workEmailAddress}
                                                                    value={values.workEmailAddress}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.workEmailAddress}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="4" controlId="homePhone">
                                                                <Form.Label>Phone Number</Form.Label>
                                                                <Form.Control
                                                                    required
                                                                    as={MaskedInput}
                                                                    name="homePhone"
                                                                    mask="999-999-9999"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    isInvalid={touched.homePhone && !!errors.homePhone}
                                                                    value={values.homePhone}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.homePhone}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="4" controlId={'gender'}>
                                                                <Form.Label>Gender</Form.Label>
                                                                <Form.Control required as="select" name={'gender'}
                                                                              onChange={handleChange}
                                                                              onBlur={handleBlur}
                                                                              isInvalid={touched.gender && !!errors.gender}
                                                                              value={values.gender}
                                                                >
                                                                    {CommonUtils.getOptionsForSelect(CommonUtils.forDropDown(Array.of('Male', 'Female')))}
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Form.Row>
                                                        <Form.Row>
                                                            <Form.Group as={Col} md="4" controlId={'startDate'}>
                                                                <Form.Label>Start Date</Form.Label>
                                                                <Field
                                                                    id="startDate"
                                                                    name="startDate"
                                                                    component={FormikDatePickerComponent}
                                                                    placeholder={"First day of work"}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="4" controlId={'maritalStatusCode'}>
                                                                <Form.Label>Marital Status</Form.Label>
                                                                <Form.Control required as="select" name={'maritalStatusCode'}
                                                                              onChange={handleChange}
                                                                              onBlur={handleBlur}
                                                                              isInvalid={touched.maritalStatusCode && !!errors.maritalStatusCode}
                                                                              value={values.maritalStatusCode}
                                                                >
                                                                    {CommonUtils.getOptionsForSelect(CommonUtils.forDropDown(Array.of(SINGLE_STATUS, MARRIED_STATUS)))}
                                                                </Form.Control>
                                                            </Form.Group>
                                                           
                                                        </Form.Row>

                                                        <Form.Row>
                                                            <Form.Group as={Col} md="6" controlId={'bornStatus'}>
                                                                <Form.Label> </Form.Label>
                                                                    <Form.Check
                                                                    className="custom_check"
                                                                        type="checkbox"
                                                                        name={"bornStatus"}
                                                                        onBlur={handleBlur}
                                                                        label="Is the person born outside of the US"
                                                                        onChange={handleChange}
                                                                        checked={values?.bornStatus}
                                                
                                                                    />
                                                                </Form.Group>
                                                        </Form.Row>

                                                        {values?.bornStatus ? <Form.Row>
                                                        <Form.Group as={Col} md="6" controlId={'citizenshipAcquiredType'}>
                                                                <Form.Label>Citizenship Type</Form.Label>
                                                                <Form.Control required as="select" name={'citizenshipAcquiredType'}
                                                                              onChange={handleChange}
                                                                              onBlur={handleBlur}
                                                                              isInvalid={touched.citizenshipAcquiredType && !!errors.citizenshipAcquiredType}
                                                                              value={values.citizenshipAcquiredType? values.citizenshipAcquiredType:null}
                                                                >
                                                                    {CommonUtils.getOptionsForSelect(this.state.citizenshipTypes)}
                                                                </Form.Control>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.citizenshipAcquiredType}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="6" controlId={'citizenshipDate'}>
                                                                <Form.Label> Citizenship Date</Form.Label>
                                                                <Field
                                                                    id="citizenshipDate"
                                                                    name="citizenshipDate"
                                                                    component={FormikDatePickerComponent}
                                                                    placeholder={"citizen Ship Date"}
                                                                />
                                                            </Form.Group>
                                                            <Form.Control.Feedback type="invalid">
                                                                    {errors.citizenshipDate}
                                                                </Form.Control.Feedback>
                                                        </Form.Row>:null}
                                                        <Form.Row>
                                                            <Form.Group as={Col} md="12">
                                                                <Form.Label>Employee Types</Form.Label>
                                                                <Field
                                                                    id="employeeTypes"
                                                                    name="employeeTypes"
                                                                    component={FormikFieldDualListBox}
                                                                    options={avail_employee_types}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.employeeTypes}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Form.Row>
                                                        {avail_contracts && avail_contracts.length > 0 &&
                                                        <Form.Row>
                                                            <Form.Group as={Col} md="12">
                                                                <Form.Label>Contracts</Form.Label>
                                                                <Field
                                                                    id="supportingContractIds"
                                                                    name="supportingContractIds"
                                                                    component={FormikFieldDualListBox}
                                                                    options={avail_contracts}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.supportingContractIds}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Form.Row>
                                                        }
                                                    </Card.Body>
                                                    <ButtonToolbar>
                                                    <Button variant="primary" disabled={isSubmitting} type="submit">Add
                                                        New Hire</Button>
                                                    <Button variant="danger" onClick={this.close}>Cancel</Button>
                                                </ButtonToolbar>
                                                </Card>
                                            </Modal.Body>
                                            {/* <Modal.Footer>
                                               
                                            </Modal.Footer> */}
                                        </Form>
                                    )}
                                </Formik>
                            </Modal>
                        </Card.Body>
                        <Card.Footer>
                            {(!isReadOnlyRole() || isHROnlyRole()) &&
                            <div className={"text-right"}>
                                <NewHireSearchModal buttonText="Search/Create Subject" onSelect={this.onSelectSubject} onCreateNew={this.onCreateSubject}/>
                            </div>
                            }
                        </Card.Footer>
                    </Card>
                </Modal.Body>
                <Modal.Footer>
                    <div className={"pull-right1"}>
                        <ButtonToolbar>
                            <Button variant="primary" disabled={!newHires || newHires.length === 0 || this.state.disableSubmit}
                                    onClick={this.onInitiateNewHire}>Submit</Button>
                            {/* <Button variant="danger" onClick={this.props.onCancel}>Cancel</Button> */}
                            <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="New Hire Process"
                                                         handleDeleteCallback={this.handleCancel}/>
                        </ButtonToolbar>
                    </div>
                </Modal.Footer>
            </Fragment>
        )
    }

}

export const NewHire = connect(null, {showErrorGrowl, showSuccessGrowl})(NewHireComp);
