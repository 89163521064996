import React, {Component, Fragment} from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {textFilter} from "react-bootstrap-table2-filter";
import paginationFactory from 'react-bootstrap-table2-paginator';
import {Link} from "react-router-dom";
import {
    addFacilityToSubjects,
    fetchSubjectsByFacilityId,
    removeFacilityFromSubjects,
    searchSubjectsNotPartOfFacility,
    updateFacilityRelForSubjects
} from "../../../../../actions/subject_actions";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {SubjectAddlClearanceNewModal} from "../clearance/subject_addl_clearance_new_modal";
import {SubjectAddlClearanceDetail} from "../clearance/subject_addl_clearance_detail_modal";
import {ClearanceDebrief} from "../clearance/subject_addl_clearance_debrief";
import {showErrorGrowl, showSuccessGrowl} from "../../../../../actions/msg_actions";
import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {ConditionalDisplay, isReadOnlyRole} from "../../../../util/shouldDisplay";
import {DateUtils} from "../../../../../actions/common_utils";
import {createClearance, deleteClearance, fetchClearancesForSubject} from "../../../../../actions/clearance_actions";
import DeleteConfirmationModal from "../../../../../components/delete_confirm_modal";

class SubjectClearanceListComp extends Component {
    constructor(props) {
        super(props);

        this.handleOnSelect = this.handleOnSelect.bind(this);
        this.handleOnSelectAll = this.handleOnSelectAll.bind(this);
        this.onClearanceAdd = this.onClearanceAdd.bind(this);
        this.removeSelectedSubjects = this.removeSelectedSubjects.bind(this);
        this.editRelationshipSelectedSubjects = this.editRelationshipSelectedSubjects.bind(this);
        this.onEditModalClose = this.onEditModalClose.bind(this);
        this.onEditRelFinished = this.onEditRelFinished.bind(this);

        this.state = {
            selected: [],
            showEditModal: false
        };
    }

    componentDidMount() {
        this.getSubjectClearances();
    }

    getSubjectClearances = async () => {
        try {
            let clearances = await fetchClearancesForSubject(this.props.subjectId);
            if (clearances) {
                console.log('Clearances got are ', clearances);
                for (let eachClearance of clearances) {
                    eachClearance.status = 'Active';
                    if (eachClearance.debriefInfo) {
                        eachClearance.status = 'Debriefed';
                    }
                }
                this.setState({
                    clearances
                })
            }
        } catch (error) {
            console.log('Error getting clearances ', error)
            this.props.showErrorGrowl("Unable to get subject's clearances", error.response.data);
        }
    }

    handleOnSelect(row, isSelect) {
        if (isSelect) {
            this.setState({
                selected: [...this.state.selected, row.id]
            });
        } else {
            this.setState({
                selected: this.state.selected.filter(x => x !== row.id)
            });
        }
    }

    handleOnSelectAll(isSelect, rows) {
        const ids = rows.map(r => r.id);
        if (isSelect) {
            this.setState({
                selected: ids
            });
        } else {
            this.setState({
                selected: []
            });
        }
    }

    onClearanceAdd = async (clearance) => {
        try {
            clearance.subjectId = this.props.subjectId;
            await createClearance(clearance);
            this.getSubjectClearances();
        } catch (error) {
            console.log('Error adding clearance ', error)
            this.props.showErrorGrowl("Unable to add clearance to subject", error.response.data);
        }
    }

    removeClearance = async (clearanceId) => {
        try {
            await deleteClearance(clearanceId);
            this.getSubjectClearances();
        } catch (error) {
            console.log('Error removing clearance ', error)
            this.props.showErrorGrowl("Unable to remove clearance from subject", error.response.data);
        }
    }

    removeSelectedSubjects() {
        if (this.state.selected && this.state.selected.length !== 0) {
            this.props.removeFacilityFromSubjects(this.props.facilityId, this.state.selected, () => {
                this.props.fetchSubjectsByFacilityId(this.props.facilityId);
                this.setState({selected: []});
                this.props.showSuccessGrowl("Subjects Removed", "Subjects were removed from the facility successfully");
            });
        } else {
            this.props.showErrorGrowl("No Subjects Selected", "Select at least 1 subject");
        }
    }

    editRelationshipSelectedSubjects() {
        if (this.state.selected && this.state.selected.length !== 0) {
            this.setState({showEditModal: true});
        } else {
            this.props.showErrorGrowl("No Subjects Selected", "Select at least 1 subject");
        }
    }

    onEditModalClose() {
        this.setState({showEditModal: false, selected: []});
        this.props.fetchSubjectsByFacilityId(this.props.facilityId);
    }

    onEditRelFinished(relType, callback) {
        this.props.updateFacilityRelForSubjects(this.props.facilityId, this.state.selected, relType, () => {
            callback();
            this.props.showSuccessGrowl("Subjects' Relationships Edited");
        });
    }

    render() {
        const {showEditModal, clearances} = this.state;

        const clearanceOrgTypeSortFunc = (a, b, order) => {
            const aValue = a && a.displayName ? a.displayName.toLowerCase() : '';
            const bValue = b && b.displayName ? b.displayName.toLowerCase() : '';
        
            if (order === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        };
        
        function customClearanceOrgTypeFilter(filterVal, data) {
            const lowerCaseFilterVal = filterVal.toLowerCase();
            return data.filter(row => 
                row.clearanceOrgType && row.clearanceOrgType.displayName &&
                row.clearanceOrgType.displayName.toLowerCase().includes(lowerCaseFilterVal)
            );
        }
        
        if (!clearances) {
            return <div>Loading...</div>
        }
        
        const columns = [{
            dataField: 'clearanceOrgType',
            text: 'Granting Organization',
            formatter: (cell, row) => cell.displayName,
            sortFunc: clearanceOrgTypeSortFunc,
            sort: true,
            filter: textFilter({
                onFilter: customClearanceOrgTypeFilter
            })
        }, {
            dataField: 'investigationType',
            text: 'Investigation Type',
            formatter: (cell, row) => cell,
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'investigationDate',
            text: 'Investigation Date',
            formatter: (cell, row) => DateUtils.getDateString(cell),
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'eligibility',
            text: 'Eligibility',
            formatter: (cell, row) => cell,
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'eligibilityDate',
            text: 'Eligibility Date',
            formatter: (cell, row) => DateUtils.getDateString(cell),
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'status',
            text: 'Status',
            formatter: (cell, row) => cell,
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'actions',
            text: 'Actions',
            formatter: (cell, row) => {
                return (
                    ConditionalDisplay(
                <Fragment>
                    <ButtonToolbar className="p-0 nowrap flex-start">
                        <SubjectAddlClearanceDetail subjectAddlClearanceDetail={row}/>
                        <SubjectAddlClearanceNewModal existingClearance={row} onFinish={this.getSubjectClearances} subjectId={row.subjectId}/>
                       
                        <DeleteConfirmationModal deleteEntity="Clearance" variant={'link'}  actionText={"remove"} handleDeleteCallback={this.removeClearance} entityId={row.id}
                                                     buttonText={'Remove'} jsxButtonType={<span title={"Remove Clearance From Subject"} style={{padding: 10 , color: "black"}} aria-label="Remove Clearance From Subject" aria-hidden="true"><i class="fa fa-trash"></i></span>}
                            />
                             <ClearanceDebrief clearance={row} onFinish={this.getSubjectClearances}/>
                       
                        {/* <Button variant={'link'} className="close" onClick={() => this.removeClearance(row.id)}>
                                <span aria-hidden="true" style={{padding: 10}} aria-label="Remove Clearance From Subject">
                                <i class="fa fa-times-circle"></i>
                                </span>
                        </Button>  */}
                    </ButtonToolbar>
                </Fragment>
                        )
                )
            }
        }];

        const pagination = paginationFactory({
            sizePerPageList: [
                {text: '10', value: 10},
                {text: '20', value: 20},
                {text: '30', value: 30}
            ]
        });

        return (
            <Fragment>
                <div className="d-flex space-between mb-4">
                <h3 className="page_title_left">Clearances</h3>
                {ConditionalDisplay(
                <ButtonToolbar>
                    <SubjectAddlClearanceNewModal subjectId={this.props.subjectId} onFinish={this.getSubjectClearances}/>
                </ButtonToolbar>
                )}
                </div>
               
                {isReadOnlyRole() &&
                <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="id" data={clearances}
                                columns={columns} pagination={pagination}
                                filter={filterFactory()}/>
                }
                {!isReadOnlyRole() &&
                <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="id" data={clearances}
                                columns={columns} pagination={pagination}
                                filter={filterFactory()}/>
                }
                {showEditModal && !isReadOnlyRole() &&
                <FacilitySubjectEditRelModal onClose={this.onEditModalClose} onFinish={this.onEditRelFinished}/>}
            </Fragment>
        )
    }
}

export const SubjectClearanceList = connect(null, {
    fetchSubjectsByFacilityId,
    searchSubjectsNotPartOfFacility,
    addFacilityToSubjects,
    removeFacilityFromSubjects,
    showErrorGrowl,
    updateFacilityRelForSubjects,
    showSuccessGrowl
})(SubjectClearanceListComp);

SubjectClearanceList.propTypes = {
    subjectId: PropTypes.string.isRequired
};

