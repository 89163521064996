import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import {DateUtils} from "../../../../../actions/common_utils";

export class SubjectMaritalDetails extends Component {
    getMaritalStatusHistory() {
        const {spouseHistory, isTaskDetail} = this.props;

        return spouseHistory.map((spouse) => (
            <tr key={spouse.id}>
                <td>{spouse.firstName} {spouse.lastName}</td>
                <td>{spouse.startDate ? DateUtils.getDateString(spouse.startDate) : "Unknown"}</td>
                {!isTaskDetail && (
                    <td>
                        {spouse.dateInvalidated
                            ? `${DateUtils.getDateString(spouse.dateInvalidated)} (${spouse.reasonInvalidated ? spouse.reasonInvalidated.displayName : "Unknown"})`
                            : "Present"}
                    </td>
                )}
                {isTaskDetail && (
                    <>
                        <td>{spouse.spouseDob ? DateUtils.getDateString(spouse.spouseDob) : ""}</td>
                        <td>{spouse.spousePlaceOfBirth || ''}</td>
                        <td>{spouse.spouseCitizenship || ''}</td>
                        <td>{DateUtils.getDateString(spouse?.dateInvalidated) ||'' }</td>
                        <td>{spouse?.reasonInvalidated ? spouse?.reasonInvalidated.displayName || '' : ''} </td>
                    </>
                )}
            </tr>
        ));
    }

    render() {
        const {spouseHistory, isTaskDetail} = this.props;

        if (spouseHistory.length === 0) {
            return (
                <Fragment>
                    <Table>
                        <thead>
                            <tr>
                                <th><Alert variant="danger">No Marital Status History!</Alert></th>
                            </tr>
                        </thead>
                    </Table>
                </Fragment>
            );
        }

        return (
           <Fragment>
               <Table>
                   <thead>
                       <tr>
                           <th>Name</th>
                           {isTaskDetail ? (
                               <>
                                   <th>Date of Marriage</th>
                                   <th>Spouse Date of Birth</th>
                                   <th>Spouse Place of Birth</th>
                                   <th>Spouse Citizenship Country</th>
                                   <th>End Date</th>
                                   <th>Reason</th>
                               </>
                           ) : (
                               <>
                                   <th>From Date</th>
                                   <th>To Date</th>
                               </>
                           )}
                       </tr>
                   </thead>
                   <tbody>
                       {this.getMaritalStatusHistory()}
                   </tbody>
               </Table>
           </Fragment>

        );
    }
}

SubjectMaritalDetails.propTypes = {
    spouseHistory: PropTypes.array.isRequired,
    isTaskDetail: PropTypes.bool
};

