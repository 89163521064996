import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {DateUtils} from "../../../../../actions/common_utils";

class SubjectEqipInformation extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {eqipInvestigation} = this.props.subject;

        return (
            <Fragment>
                {eqipInvestigation &&
                    <Fragment>
                        <Row className={'mb-3'}>
                            <Col as={Form.Label} sm={3} md={2}>
                                Subject Notified Date
                            </Col>
                            <Col sm={3} md={4}>
                                {DateUtils.getDateString(eqipInvestigation.subjectNotifiedDate)}
                            </Col>
                            <Col as={Form.Label} sm={3} md={2}>
                                Fingerprint Date
                            </Col>
                            <Col sm={3} md={4}>
                                {DateUtils.getDateString(eqipInvestigation.fingerPrintDate)}
                            </Col>
                        </Row>
                        <Row className={'mb-3'}>
                            <Col as={Form.Label} sm={3} md={2}>
                                Documents Submitted Date
                            </Col>
                            <Col sm={3} md={4}>
                                {DateUtils.getDateString(eqipInvestigation.documentsSubmittedDate)}
                            </Col>
                            <Col as={Form.Label} sm={3} md={2}>
                                Approved Date
                            </Col>
                            <Col sm={3} md={4}>
                                {DateUtils.getDateString(eqipInvestigation.approvedDate)}
                            </Col>
                        </Row>
                        <Row className={'mb-3'}>
                            <Col as={Form.Label} sm={3} md={2}>
                                Removed Date
                            </Col>
                            <Col sm={3} md={4}>
                                {DateUtils.getDateString(eqipInvestigation.removedDate)}
                            </Col>
                            <Col as={Form.Label} sm={3} md={2}>
                                Investigation Status
                            </Col>
                            <Col sm={3} md={4}>
                                {eqipInvestigation.status}
                            </Col>
                        </Row>
                    </Fragment>
                }
            </Fragment>
        )
    }
}

SubjectEqipInformation.propTypes = {
    subject: PropTypes.object.isRequired
};

export default SubjectEqipInformation;