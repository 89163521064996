import React, {Component, Fragment} from "react";
import {Link} from "react-router-dom";
import {fetchCurOrg} from "../../../../actions/org_actions";
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {textFilter} from "react-bootstrap-table2-filter";
import {connect} from "react-redux";
import {CUR_ORG} from "../../../../actions/session_constants";
import {fetchAllScreenings, fetchScreeningsUsage} from "../../../../actions/bg_check_actions";
import {showErrorGrowl} from "../../../../actions/msg_actions";
import {ConditionalDisplay} from "../../../util/shouldDisplay";
import {DateUtils} from "../../../../actions/common_utils";
import {Breadcrumb, ButtonToolbar, Tab, Tabs} from 'react-bootstrap';
import CollapsibleCard from "../../../../components/bootstrap_addons/collapsible-card";

class ScreeningListComp extends Component {
    constructor(props) {
        super(props);

        this.onTableChange = this.onTableChange.bind(this);

        this.state = {
            selEntityTypes: [{value: "ORG", label: "Organization"}],
            selStatus: [{value: "ACTIVE", label: "Active"}],
            selWFStatus: [{value: "PENDING", label: "Pending"},
                {value: "DOCS_SUBMITTED", label: "Documents Submitted"},
                {value: "REJECTED", label: "Rejected"}]
        }
    }

    getScreenings = async () => {
        let screenings = await fetchAllScreenings();
        this.setState({
            screenings
        })
    }

    getScreeningUsages = async () => {
        try {
            let screeningUsages = await fetchScreeningsUsage();
            console.log('Screening usages are ', screeningUsages)
            this.setState({
                screeningUsages
            })
        } catch (error) {
            console.log("Error is ", error);
            this.props.showErrorGrowl('Unexpected error occurred', 'Error getting screening usages');
        }
    }

    componentDidMount() {
        this.getScreenings();
        this.getScreeningUsages();
    }

    onTableChange(type, newState) {
        switch (type) {
            case "filter":
                const filters = newState.filters;
                let issueName = "";
                let entityTypes = ["ORG"];
                let entityName = "";
                let issueStatus = ["ACTIVE"];
                let docSubmitWorkflowStatus = ["PENDING", "DOCS_SUBMITTED", "REJECTED"];
                if (filters.issueName) {
                    issueName = filters.issueName.filterVal;
                }
                if (filters.entityName) {
                    entityName = filters.entityName.filterVal;
                }
                if (filters.entityType) {
                    entityTypes = filters.entityType.filterVal.map((val) => val.value);
                }
                if (filters.active) {
                    issueStatus = filters.active.filterVal.map((val) => val.value);
                }
                if (filters.docSubmitWorkflowStatus) {
                    docSubmitWorkflowStatus = filters.docSubmitWorkflowStatus.filterVal.map((val) => val.value);
                }
                this.props.fetchIssuesWithEntityForOrg(sessionStorage.getItem(CUR_ORG), issueName, entityName, entityTypes, issueStatus, docSubmitWorkflowStatus);
                break;
        }
    }

    getCellLink = (row, cellData) => {
        return <Link to={`/org/dash/screening/detail/${row.id}`}>{cellData}</Link>;
    }

    getColumns() {
        const headerFormatter = (column, colIndex, { sortElement, filterElement }) => {
            return (
                <div style={ { display: 'flex', flexDirection: 'column' } }>
                    <div>
                        { column.text }
                        { sortElement }
                    </div>
                    { filterElement }
                </div>
            );
        }

        return [{
            dataField: 'subjectName',
            text: 'Name',
            formatter: (cell, row) => this.getCellLink(row, cell),
            sort: true,
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'submittedAt',
            text: 'Date Created',
            formatter: (cell, row) => this.getCellLink(row, (cell? DateUtils.getDateTimeString(cell): cell)),
            sort: true,
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'status',
            text: 'Status',
            formatter: (cell, row) => this.getCellLink(row, cell),
            sort: true,
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'result',
            text: 'Result',
            formatter: (cell, row) => this.getCellLink(row, cell),
            sort: true,
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'estimatedCompletionTime',
            text: 'Estimated Completed Time',
            formatter: (cell, row) => this.getCellLink(row, cell),
            sort: true,
            filter: textFilter(),
            headerFormatter: headerFormatter
        }];
    }

    getUsageColumns = () => {
        const headerFormatter = (column, colIndex, { sortElement, filterElement }) => {
            return (
                <div style={ { display: 'flex', flexDirection: 'column' } }>
                    <div>
                        { column.text }
                        { sortElement }
                    </div>
                    { filterElement }
                </div>
            );
        }

        return [{
            dataField: 'monthName',
            text: 'Month',
            formatter: (cell, row) => cell,
            sort: true,
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'count',
            text: 'Total Count',
            formatter: (cell, row) => cell,
            sort: true,
            filter: textFilter(),
            headerFormatter: headerFormatter
        }];
    }

    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/org/dash" onClick={() => fetchCurOrg()}>
    //                 Organization {this.props.cur_org.name}
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 Screenings
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    getCollapsibleCardForEachPackage = (screeningResponses) => {
        let cardArray = [];
        if (screeningResponses) {
            screeningResponses.forEach((screeningResponse) => {
                cardArray.push(
                    <CollapsibleCard header={screeningResponse.packageName} defaultOpen>
                        <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="monthNumber" data={screeningResponse.usages}
                                        columns={this.getUsageColumns()} filter={filterFactory()}
                        />
                    </CollapsibleCard>
                )
            })
        }
        return cardArray;
    }

    render() {
        let {screenings, screeningUsages} = this.state;

        if (!screenings) {
            return <div>Loading...</div>
        }

        console.log('Screenings are', screenings);
        const remoteSettings = {
            filter: true,
            pagination: false,
            sort: false,
            cellEdit: false
        };

        return (
            <Fragment>
                {/* {this.getBreadCrumb()} */}

                {ConditionalDisplay(
                    <div className="float-right">
                        <ButtonToolbar>
                            <Link className="btn btn-primary" to="/org/dash/screening/new">New Screening</Link>
                        </ButtonToolbar>
                    </div>
                )}
                <h3 className="page_title_left mb-4">Screenings & Usage</h3>
               
                <Tabs defaultActiveKey='screenings' id='screenings_list_tab'>
                    <Tab eventKey='screenings' title={'Screenings'}>
              
                <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="issueId" data={screenings}
                                columns={this.getColumns()} filter={filterFactory()} remote={remoteSettings}
                                onTableChange={this.onTableChange}/>
                    </Tab>
                    <Tab eventKey='screeningUsages' title={'Screening Usages'}>
                        {this.getCollapsibleCardForEachPackage(screeningUsages)}
                    </Tab>
                </Tabs>
            </Fragment>
        )
    }
}

export const ScreeningList = connect(null, {showErrorGrowl})(ScreeningListComp);