import {Field, Formik} from "formik";
import * as Yup from "yup";
import {Card, Form, Modal} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import {CommonUtils} from "../../../../../actions/common_utils";
import {FormikDatePickerComponent} from "../../../../../components/formik/formik_date_picker";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import React, {Component} from "react";
import PropTypes from 'prop-types';
import {updateSubjectEqipInvestigation} from '../../../../../actions/subject_actions';
import {showErrorGrowl, showSuccessGrowl} from "../../../../../actions/msg_actions";
import {connect} from "react-redux";

class SubjectEqipUpdateComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            statusList : ['Initiated', 'Submitted', 'Approved', 'Removed']
        }
    }

    onSubmit = (values) => {
        try {
            let {subject, updateSubjectEqipInvestigation} = this.props;
            updateSubjectEqipInvestigation(values, subject.id, () => {
                console.log('SucceSSS')
                this.props.showSuccessGrowl("Subject Updated", "Subject eQip Investigation dates updated");
                this.props.onClose();
            });
        } catch (error) {
            console.log(error);
            this.props.showErrorGrowl("Unexpected Error", "Unexpected error when trying to update the subject's eQip data");
        }
    }

    render() {
        let {onClose, subject} = this.props;
        const {statusList} = this.state;
        let initObj = {
            subjectNotifiedDate: '',
            fingerPrintDate: '',
            documentsSubmittedDate: '',
            approvedDate: '',
            removedDate: '',
            status: 'Initiated'
        }
        if (subject.eqipInvestigation){
            Object.assign(initObj, subject.eqipInvestigation);
        }
        return (
            <Formik
                initialValues={initObj}
                // enableReinitialize={true}
                onSubmit={this.onSubmit}
                validationSchema={
                    Yup.object().shape({
                        status: Yup.string().required('Enter a status'),
                        subjectNotifiedDate: Yup.date().required('Enter date when subject was notified')
                    })
                }
            >
                {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur}) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Modal.Body>
                            <Card>
                                <Card.Body>
                                    <Form.Row>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Subject Notified Date</Form.Label>
                                            <Field
                                                id="subjectNotifiedDate"
                                                name="subjectNotifiedDate"
                                                component={FormikDatePickerComponent}
                                                placeholder={"Enter date when subject was notified"}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Fingerprint Date</Form.Label>
                                            <Field
                                                id="fingerPrintDate"
                                                name="fingerPrintDate"
                                                component={FormikDatePickerComponent}
                                                placeholder={"Enter date when subject was fingerprinted"}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Documents Submitted Date</Form.Label>
                                            <Field
                                                id="documentsSubmittedDate"
                                                name="documentsSubmittedDate"
                                                component={FormikDatePickerComponent}
                                                placeholder={"Enter date when documents were submitted"}
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Approved Date</Form.Label>
                                            <Field
                                                id="approvedDate"
                                                name="approvedDate"
                                                component={FormikDatePickerComponent}
                                                placeholder={"Enter date investigation was approved"}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Removed Date</Form.Label>
                                            <Field
                                                id="removedDate"
                                                name="removedDate"
                                                component={FormikDatePickerComponent}
                                                placeholder={"Enter date when subject was removed"}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId={"status"}>
                                            <Form.Label>Status</Form.Label>
                                            <Form.Control required as="select" name={'status'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          isInvalid={touched.status && !!errors.status}
                                                          value={values.status}
                                            >
                                                {CommonUtils.getOptionsForSelect(CommonUtils.forDropDown(statusList))}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.status}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                </Card.Body>
                            </Card>
                        </Modal.Body>
                        <Modal.Footer>
                            <ButtonToolbar>
                                <Button variant="primary" type="submit" disabled={isSubmitting}>Update Eqip</Button>
                                <Button variant="danger" onClick={onClose}>Cancel</Button>
                            </ButtonToolbar>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        );
    }
}

export const SubjectEqipUpdate = connect(null, {updateSubjectEqipInvestigation, showErrorGrowl, showSuccessGrowl})(SubjectEqipUpdateComponent);

SubjectEqipUpdate.propTypes = {
    subject: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired
}
