import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import {Link} from "react-router-dom";
import {selectFilter, textFilter} from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import {DateUtils} from "../../../../../actions/common_utils";

class SubjectContractDetails extends Component {
    render() {
        const columns = [
            {
                dataField: "name",
                text: "Name",
                formatter: (cell, row) => <Link to={`/org/dash/contract/${row.id}`}>{cell}</Link>,
                sort: true
            },
            {
                dataField: "contractNo",
                text: "Contract Number",
                formatter: (cell, row) => <Link to={`/org/dash/contract/${row.id}`}>{cell}</Link>,
                sort: true
            },
            {
                dataField: "status",
                text: "Status",
                sort: true
            },
            {
                dataField: "daysLeft",
                text: "Time Remaining",
                formatter: (cell, row) => DateUtils.getDaysLeft(row.endDate, 30),
                sort: true
            },
            {
                dataField: "endDate",
                text: "End Date",
                formatter: (cell) => DateUtils.getDateString(cell),
                sort: true
            },
        ];

        if (this.props.subject.fullContracts){
            return (
                this.getContractList(columns)
            )
        } else {
            return <Fragment></Fragment>;
        }
    }

    getContractList(columns) {
        return <Fragment>
            <Card className={"h-100"} border={"success"}>
                <Card.Body>
                    <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="id"
                                    data={this.props.subject.fullContracts} columns={columns}/>
                </Card.Body>
            </Card>
        </Fragment>;
    }
}

SubjectContractDetails.propTypes = {
    subject: PropTypes.object.isRequired,
};

export default SubjectContractDetails;