import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {DateUtils} from "../../../../../actions/common_utils";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import { SubjectReportArrest } from "./addDetails/subject_add_arrest";

export class SubjectArrestDetails extends Component {
    getArrests() {
        return this.props.arrests.map((arrest) => {
            return <tr key={arrest.id}>
                <td>{arrest.incidentDate ? DateUtils.getDateString(arrest.incidentDate) : "Unknown"}</td>
                <td>{arrest.incidentLocation}</td>
                <td>{arrest.charges}</td>
                <td>{arrest.disposition}</td>
                <td>{arrest.startDate ? DateUtils.getDateString(arrest.startDate) : "Unknown"}</td>
                <td>
                    {arrest.dateInvalidated ?
                        `${DateUtils.getDateString(arrest.dateInvalidated)} (${arrest.reasonInvalidated ? arrest.reasonInvalidated.displayName : "Unknown"})`
                        : "Present"}
                </td>
            </tr>
        });
    }

    render() {
        const {arrests} = this.props;  
        return (
            <Fragment> 
                {(!arrests || arrests.length === 0) ?
                    <Table>
                        <thead>
                            <Alert variant="danger">No Arrests!</Alert>
                        </thead>
                    </Table> :
                    <Table>
                        <thead>
                            <tr>
                                <th>Incident Date</th>
                                <th>Incident Location</th>
                                <th>Charges</th>
                                <th>Disposition</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.getArrests()}
                        </tbody>
                    </Table>
                }
            </Fragment>
        )
    }
}
