import React, {Component, Fragment} from "react";
import {FieldRBMaskedInput} from "../../../../../components/redux_form/field_components_rb";
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {addOrgToSubject, fetchPerson, fetchSubjectBySocial} from "../../../../../actions/subject_actions";
import PropTypes from "prop-types";
import SubjectNewModal from "../../../../../components/subject/subject_new_modal";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

class NewHireSearchModalComp extends Component {
    constructor(props) {
        super(props);

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.onCreate = this.onCreate.bind(this);
        this.onFinish = this.onFinish.bind(this);
        this.state = {
            showModal: false,
            values: {},
            noSubjectFound: false
        }
    }

    open() {
        this.setState({
            showModal: true,
            values: {},
            noSubjectFound: false
        });
    }

    onSubmit = async (values) => {
        this.setState({noSubjectFound: false,
        values});
        this.props.initialize(values);
        try {
            let subject = await fetchSubjectBySocial(values.ssn);
            this.setState({values: values,
            subject});
        } catch (error) {
            console.log('Error is ', error)
            if (error.response.status === 404) {
                this.setState({noSubjectFound: true});
            }
        }
    }

    onFinish() {
        const {onSelect} = this.props;
        const {subject} = this.state;
        if (subject) {
            console.log('Subject after selection', subject.countryOfBirth)
            let newSub = {};
            Object.assign(newSub, subject);
            onSelect(newSub);
            this.close();
        }
    }

    onCreate(ssn) {
        this.onSubmit({ssn: ssn});
        this.props.handleSubmit(this.onSubmit.bind(this));
    }

    close() {
        // Clear form fields
        this.props.change("ssn", null);

        this.setState({
            showModal: false
        });
    }

    getResult() {
        const {dirty, valid, anyTouched} = this.props;
        const {subject} = this.state;
        const {noSubjectFound} = this.state;

        if (!dirty && !anyTouched && valid) {
            if (!subject || !subject.id || noSubjectFound) {
                return <Alert variant="danger">No Subject Found</Alert>;
            }
            return <Alert variant="success">Found {subject.personFirstName} {subject.personLastName}</Alert>;
        }
    }

    getButton() {
        const {dirty, valid, anyTouched, onCreateNew} = this.props;
        const {subject} = this.state;

        const {noSubjectFound} = this.state;

        if (dirty || anyTouched || !valid) {
            return <Button variant="primary" type="submit">Search</Button>;
        }
        if (subject && subject.id && !noSubjectFound) {
            return <Button variant="primary" onClick={this.onFinish}>Select</Button>
        }
        if (onCreateNew){
            return <Button variant="primary" onClick={()=>{
                this.close();
                onCreateNew(this.state.values);
            }}>Create</Button>
        } else {
            return <SubjectNewModal onCreate={this.onCreate} buttonText="Create" subjectPrefill={this.state.values}/>
        }
    }

    onKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Not allowing this, since it causes issues with selecting subject. Click the Search button instead
        }
    }

    render() {
        const {showModal} = this.state;
        const {handleSubmit, buttonText} = this.props;

        return (
            <Fragment>
                <Button variant="primary" onClick={this.open}>{buttonText ? buttonText : "Select Subject"}</Button>
                <Modal show={showModal} onHide={this.close}>
                    <form onSubmit={handleSubmit(this.onSubmit.bind(this))} onKeyPress={this.onKeyPress}>
                        <Modal.Header closeButton>
                            <Modal.Title>Subject Search</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Field name="ssn" component={FieldRBMaskedInput} mask="*99-99-9999"
                                   label="Social Security Number (SSN)" required/>

                            {this.getResult()}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={this.close}>Cancel</Button>
                            {this.getButton()}
                        </Modal.Footer>
                    </form>
                </Modal>
            </Fragment>
        )
    }
}

function validate(values) {
    const errors = {};

    if (!values.ssn) {
        errors.ssn = "SSN is required";
    } else if (!/^[0-9A-Za-z][0-9]{2}-[0-9]{2}-[0-9]{4}$/.test(values.ssn)) {
        errors.ssn = "Enter a valid SSN";
    }

    return errors;
}


export const NewHireSearchModal = reduxForm({
    validate, // same as validate:validate
    form: 'NewHireSearchForm'
})(
    connect(null, {fetchPerson, addOrgToSubject})(NewHireSearchModalComp)
);

NewHireSearchModal.propTypes = {
    buttonText: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
    onCreateNew: PropTypes.func,
    sysAdminUserCreation: PropTypes.bool,
};
