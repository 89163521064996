import React from "react";
import {connect} from "react-redux";
import {fetchCurOrg} from "../../../../actions/org_actions";
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import CollapsibleCard from "../../../../components/bootstrap_addons/collapsible-card";
import {showErrorGrowl} from "../../../../actions/msg_actions";
import {fetchScreening} from "../../../../actions/bg_check_actions";
import {ScreeningReportItemList} from "./screening_items_list";

class ScreeningDetailComp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        this.fetchScreening();
    }

    fetchScreening = async () => {
        const screeningId = this.props.match.params.id;
        const screening = await fetchScreening(screeningId, (error)=>{
            console.log('Error is ', error);
            this.props.showErrorGrowl("Screening Not Found", "Screening not found.")
        })
        console.log('Got screening ', screening)
        this.setState({screening})
    }

    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/org/dash" onClick={() => fetchCurOrg()}>
    //                 Organization {this.props.cur_org.name}
    //             </BreadcrumbLink>
    //             <BreadcrumbLink to="/org/dash/screening">
    //                 Screenings
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 Screening
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }


    render() {
        const {screening} = this.state;

        if (!screening) {
            return (
                <div>Loading...</div>
            );
        }

        return (
            <div>
                {/* {this.getBreadCrumb()} */}

                <h3>Screening Details</h3>
                <CollapsibleCard header="General" defaultOpen>
                    <Row className={'mb-3'}>
                        <Col sm={3} md={2}>
                            Subject Name
                        </Col>
                        <Col sm={3} md={4}>
                            {screening.subjectName}
                        </Col>
                        <Col sm={3} md={2}>
                            Package Name
                        </Col>
                        <Col sm={3} md={4}>
                            {screening.packageName}
                        </Col>
                    </Row>
                    <Row className={'mb-3'}>
                        <Col sm={3} md={2}>
                            Submitted At
                        </Col>
                        <Col sm={3} md={4}>
                            {screening.submittedAt}
                        </Col>
                        <Col sm={3} md={2}>
                            Estimated Completed Time
                        </Col>
                        <Col sm={3} md={4}>
                            {screening.estimatedCompletionTime}
                        </Col>
                    </Row>
                    <Row className={'mb-3'}>
                        <Col sm={3} md={2}>
                            Status
                        </Col>
                        <Col sm={3} md={4}>
                            {screening.status}
                        </Col>
                        <Col sm={3} md={2}>
                            Result
                        </Col>
                        <Col sm={3} md={4}>
                            {screening.result}
                        </Col>
                    </Row>
                </CollapsibleCard>
                <CollapsibleCard header="Report Items" defaultOpen>
                    <ScreeningReportItemList reportItems={screening.reportItems}/>
                </CollapsibleCard>
            </div>
        );
    }
}

export const ScreeningDetail = connect(null, {showErrorGrowl})(ScreeningDetailComp);

