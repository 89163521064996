/**
 * Created by pshivaraman on 7/24/18.
 */

import React, {Component} from "react";
import {fetchCurOrg, fetchManagedOrgs, setOrg} from "../../../../actions/org_actions";
import {CUR_ORG} from "../../../../actions/session_constants";
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link";
import filterFactory, {textFilter} from "react-bootstrap-table2-filter";
import {fetchOrgFacilities} from "../../../../actions/facility_actions";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import OrgNew from "../../../../components/org/org_new";
import BootstrapTable from 'react-bootstrap-table-next';
import Breadcrumb from "react-bootstrap/Breadcrumb";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import { Service_Filter } from "./service_filter";

class ServiceCenterDash extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filter: {name: ""}
        }
    }
    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/org/dash" onClick={() => fetchCurOrg()}>
    //                 Organization {this.props.cur_org.name}
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 Service Center
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    getColumns() {
        const makeLink = (id, text) => {
            return (
                <Link to={`/org/dash/facility`} onClick={() => {
                    setOrg(id);
                    fetchOrgFacilities(id);
                }}>{text}</Link>
            )
        };

        return [
            {
                dataField: "name",
                text: "Name",
                formatter: (cell, row) => makeLink(row.id, cell),
                sort: true,
                // filter: textFilter()
            },
            {
                dataField: "pocName",
                text: "POC Name",
                formatter: (cell, row) => makeLink(row.id, cell)
            },
            {
                dataField: "pocPhone",
                text: "POC Phone",
                formatter: (cell, row) => makeLink(row.id, cell)
            },
            {
                dataField: "highestLevel",
                text: "Location Level",
                formatter: (cell, row) => makeLink(row.id, cell ? cell.name : "None")
            },
            {
                dataField: "actions",
                text: "Actions",
                formatter: (cell, row) => <Link to={`/org/dash/serviceCenter/${row.id}`}>Manage Personnel</Link>
            }
        ];
    }

    onFilterClick = async (values) => {
        console.log(values)
        this.setState({
            filter: values
        })
    }

    serviceFilterby = () => { 
        let { filter } = this.state;  
        let {managed_orgs} = this.props;
        console.log("filter",filter);
        let filteredData = managed_orgs.filter(item =>
            (filter.name === "" || item.name.toLowerCase().includes(filter.name.toLowerCase()))
            
        )
        return filteredData; 
    }

    render() {
        const {managed_orgs, fetchManagedOrgs} = this.props;
        let {filter} = this.state;

        if (!managed_orgs){
            return <div>Loading...</div>
        }

        return (
            <div>
                {/* {this.getBreadCrumb()} */}

                <div className="float-right">
                    <ButtonToolbar>
                        <OrgNew managingOrgId={sessionStorage.getItem(CUR_ORG)} onCreate={() => fetchManagedOrgs()}/>

                    </ButtonToolbar>
                </div>
                {/* <h3 className="page_title_left">Service Center</h3> */}
                <div className="d-flex">
                    <h3 className="page_title_left">Service Center</h3>
                    <Service_Filter filters={filter} onSubmit={this.onFilterClick} />
                </div>
                <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="id" data={this.serviceFilterby()} columns={this.getColumns()}
                                filter={filterFactory()}/>
            </div>
        )
    }
}

function mapStateToProps({managed_orgs}) {
    return {
        managed_orgs
    }
}

export default connect(mapStateToProps, {fetchManagedOrgs})(ServiceCenterDash);