import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import PropTypes from "prop-types";
import {showErrorGrowl, showSuccessGrowl} from "../../../../../actions/msg_actions";
import {SubjectAddlClearanceDetailComp} from "./subject_addl_clearance_detail";


class SubjectAddlClearanceDetailModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false
        }
    }

    open = () => {
        this.setState({
            showModal: true
        });
    }

    cancel = async () => {
        // try {
        //     const {subjectAddlClearanceDetail} = this.props;
        //     await cancelForeignTravel(foreignTravelDetail.id);
        //     this.props.showSuccessGrowl("Foreign Travel Cancelled", "Your foreign travel has been cancelled and removed from your records");
        //     this.setState({
        //         showModal: false
        //     });
        //     let emailReq = {
        //         emailAddress: sessionStorage.getItem(VERIFIED_EMAIL),
        //         passCode: sessionStorage.getItem(REPORTING_PASS_CODE)
        //     }
        //     this.props.reportingSecondFactor(emailReq);
        //
        // } catch (error){
        //     console.log('Error is ', error)
        //     this.props.showErrorGrowl("Unable to cancel", error.response.data.message);
        // }
    }

    close = () => {
        this.setState({
            showModal: false
        });
    }

    render() {
        const {showModal} = this.state;
        const {subjectAddlClearanceDetail} = this.props;

        return (
            <Fragment>
                <Button variant={'link'} className="close" onClick={this.open}>
                        <span aria-hidden="true" style={{paddingRight: 10}} title={"View Clearance"} aria-label="Details">
                             <i className="fa fa-file-invoice"></i>
                        </span>
                </Button>
                <Modal show={showModal} onHide={this.close} size={"lg"}>
                    <Modal.Header closeButton>
                        <Modal.Title>Subject Additional Clearance</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <SubjectAddlClearanceDetailComp subjectAddlClearance={subjectAddlClearanceDetail}/>
                    </Modal.Body>
                    <Modal.Footer>
                        <ButtonToolbar>
                            {/*{allowCancel &&*/}
                            {/*<DeleteConfirmationModal buttonText={"Delete"} actionText={"delete"} deleteEntity="Foreign Travel"*/}
                            {/*                         handleDeleteCallback={this.cancel}/>*/}
                            {/*}*/}
                            <Button variant="primary" onClick={this.close}>Close</Button>
                        </ButtonToolbar>
                    </Modal.Footer>
                </Modal>
            </Fragment>
        )
    }
}

export const SubjectAddlClearanceDetail = connect(null, {showErrorGrowl, showSuccessGrowl})(SubjectAddlClearanceDetailModal);

SubjectAddlClearanceDetail.propTypes = {
    subjectAddlClearanceDetail: PropTypes.object.isRequired
};

