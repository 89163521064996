/**
 * Created by pshivaraman on 6/7/18.
 */

import React from "react";
import {CUR_ORG} from "../../../../actions/session_constants";
import {fetchCurOrg} from "../../../../actions/org_actions";
import {
    fetchPerson,
    removeOrgFromSubject,
} from "../../../../actions/subject_actions";
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link";
import {connect} from "react-redux";
import DocumentManager from "../../../../components/document/doc_manager";
import {DateUtils} from "../../../../actions/common_utils";
import {deleteDocument} from "../../../../actions/doc_actions";
import SubjectGeneralDetails from "./details/subject_general_details";
import SubjectGeneralUpdate from "./details/subject_general_update";
import SubjectClearanceDetails from "./details/subject_clearance_details";
import SubjectFingerprintDetails from "./details/subject_fingerprinting_detail";
import SubjectBackgroundInformation from "./details/subject_background_detail";
import EntityIssues from "../../../../components/entity_issue";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {Tabs, Tab} from 'react-bootstrap';
import CollapsibleCard from "../../../../components/bootstrap_addons/collapsible-card";
import SubjectOrgDetails from "./details/subject_org_details";
import {showSuccessGrowl, showErrorGrowl} from "../../../../actions/msg_actions";
import {EntityType} from "../../../../actions/action_constants";
import SubjectAddressDetails from "./details/subject_address_details";
import {SubjectMaritalDetails} from "./details/subject_marital_details";
import {SubjectForeignContactDetails} from "./details/subject_foreign_contact_details";
import {SubjectForeignVotingDetails} from "./details/subject_foreign_voting_details";
import {SubjectCohabitantDetails} from "./details/subject_cohabitant_details";
import {SubjectArrestDetails} from "./details/subject_arrest_details";
import SubjectFacilityDetails from "./details/subject_facility_details";
import SubjectContractDetails from "./details/subject_contract_details";
import SubjectClearanceUpdate from "./details/subject_clearance_update";
import SubjectFingerprintingUpdate from "./details/subject_fingerprinting_update";
import {SubjectDatasourceDetails} from "./details/subject_datasource_details";
import {isHROnlyRole, isHRRole, isReadOnlyRole} from "../../../util/shouldDisplay";
import {SubjectReportForeignTravel} from "./details/addDetails/subject_add_foreign_travel";
import {SubjectForeignIntelligenceContactDetails} from "./details/subject_foreign_intelligence_contact_details";
import {SubjectForeignPropertyDetails} from "./details/subject_foreign_property_details";
import {SubjectForeignAdoptionDetails} from "./details/subject_foreign_adoption_details";
import {SubjectFinancialIssueAnomaliesDetails} from "./details/subject_financial_anomalies_details";
import {SubjectMediaContactDetails} from "./details/subject_media_contact_details";
import {SubjectForeignBankDetails} from "./details/subject_foreign_bank_details";
import {SubjectForeignCitizenshipDetails} from "./details/subject_foreign_citizenship_details";
import {SubjectReportIdCard} from "./details/addDetails/subject_add_foreign_id_card";
import {ForeignContactWithQuestionnaire, SubjectForeignContactListDetails} from './details/subject_foreign_contact_ques_details';
import {SubjectAlcoholDrugTreatmentDetails} from "./details/subject_alcohol_drug_treatment_details";
import SubjectEqipInformation from "./details/subject_eqip_detail";
import {SubjectEqipUpdate} from "./details/subject_eqip_update";
import {SubjectClearanceList} from "./clearance/subject_addl_clearance_list";
import {SubjectReportedOnByOthersDetails} from "./details/subject_reported_on_by_others";
import { SubjectReportArrest } from "./details/addDetails/subject_add_arrest";
import { SubjectReportMaritalStatus } from "./details/addDetails/subject_add_maritalstatus";
import { SubjectReportVoting } from "./details/addDetails/subject_add_voting";
import { SubjectReportCitizenShip } from "./details/addDetails/subject_add_citizenship";
import { SubjectReportBankAccount } from "./details/addDetails/subject_add_bank_account";
import { SubjectReportIntelligenceContact } from "./details/addDetails/subject_add_intelligence_contact";
import { SubjectReportCohabitant } from "./details/addDetails/subject_add_cohabitant";
import { SubjectReportIMediaContact } from "./details/addDetails/subject_add_mediacontact";
import { SubjectReportOthers } from "./details/addDetails/subject_add_report_others";
import { SubjectReportAlcoholDrugTreatment } from "./details/addDetails/subject_add_alcoholdrug_treatment";
import { SubjectReportFinancialIssueAnomaly } from "./details/addDetails/subject_add_financialIssue_anomaly";
import { SubjectReportAdoptions } from "./details/addDetails/subject_add_adoptions";
import { SubjectReportForeginProperty } from "./details/addDetails/subject_add_foreign_property";
import { SubjectReportForeginContacts } from "./details/addDetails/subject_add_foreign_contacts";
import {reportForeignTravel} from '../../../../actions/subject_actions'

class SubjectDetail extends React.Component {
    constructor(props) {
        super(props);

        this.editGeneral = this.editGeneral.bind(this);
        this.editGeneralFinish = this.editGeneralFinish.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleAdd = this.handleAdd.bind(this);

        this.state = {
            generalEdit: false,
            eqipInvEdit: false,
            clearanceEdit: false,
            fingerprintingEdit: false
        }
    }

    isEditable() {
        // Loop through and find the last relationship for this organization
        return !!this.props.subject.orgs.find((rel) => rel.orgId === sessionStorage.getItem(CUR_ORG) && rel.active);
    }

    handleDelete() {
        this.props.history.push("/org/dash/subject");
        this.props.showSuccessGrowl("Subject Removed Successfully", "Subject was marked separated from the Organization");
    }

    componentDidMount() {
        this.props.fetchPerson(this.props.match.params.id);
    }

    editGeneral() {
        this.setState({generalEdit: true});
    }

    editEqipInv = () => {
        this.setState({eqipInvEdit: true});
    }

    editGeneralFinish() {
        this.props.fetchPerson(this.props.match.params.id);
        this.setState({generalEdit: false});
    }

    editEqipFinish= () => {
        console.log('Eqip Edit done');
        this.props.fetchPerson(this.props.match.params.id);
        this.setState({eqipInvEdit: false});
    }

    editClearance = () => {
        this.setState({clearanceEdit: true});
    }

    editFingerprinting = () => {
        this.setState({fingerprintingEdit: true});
    }

    editClearanceFinish = () => {
        this.props.fetchPerson(this.props.match.params.id);
        this.setState({clearanceEdit: false});
    }

    editFingerprintingFinish = () => {
        this.props.fetchPerson(this.props.match.params.id);
        this.setState({clearanceEdit: false});
    }

    handleAdd() {
        this.props.fetchPerson(this.props.match.params.id);
    }

    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/org/dash" onClick={() => fetchCurOrg()}>
    //                 Organization {this.props.cur_org.name}
    //             </BreadcrumbLink>
    //             <BreadcrumbLink to="/org/dash/subject">
    //                 Subjects
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 {this.props.subject.personFirstName} {this.props.subject.personLastName}
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    render() {
        const {subject} = this.props;
        let {generalEdit, clearanceEdit, eqipInvEdit, fingerprintingEdit} = this.state;

        if (!subject || !subject.id) {
            return <div>Loading...</div>;
        }
        console.log('Subject details are ', subject)
        return (
            <div>
                {/* {this.getBreadCrumb()} */}

                <h3>Subject Details</h3>
                <br/>
                <EntityIssues issueEntity={subject}/>
                <Tabs defaultActiveKey='general' id='subject_details_tab'>
                    <Tab eventKey='general' title={'General Information'}>
                        <CollapsibleCard header="General" defaultOpen>
                            {!generalEdit && this.isEditable() && !isReadOnlyRole() &&
                            <div className="d-flex justify-content-right">
                                <div className="ml-auto">
                                    <Button variant="primary" onClick={this.editGeneral}>
                                        <span className="fa fa-square-pen" aria-hidden="true"/> Edit
                                    </Button>
                                </div>
                            </div>
                            }
                            {!generalEdit && <SubjectGeneralDetails subject={subject}/>}
                            {generalEdit &&
                            <SubjectGeneralUpdate subjectId={this.props.match.params.id}
                                                  onFinish={this.editGeneralFinish}/>}
                        </CollapsibleCard>
                        <CollapsibleCard header="Address">
                            <SubjectAddressDetails subject={subject}/>
                        </CollapsibleCard>
                        <CollapsibleCard header="Marital History">
                        <SubjectReportMaritalStatus subject={subject} />
                        </CollapsibleCard>
                        {/*<CollapsibleCard header="Citizenship">*/}
                        {/*    <p>To be implemented...</p>*/}
                        {/*</CollapsibleCard>*/}
                        <CollapsibleCard header="Data Source">
                            <SubjectDatasourceDetails subject={subject}/>
                        </CollapsibleCard>
                        <CollapsibleCard header="Organization">
                            <SubjectOrgDetails subject={subject} handleDelete={this.handleDelete}
                                               handleAdd={this.handleAdd}
                                               editFinish={this.handleAdd}/>
                        </CollapsibleCard>
                        <CollapsibleCard header="Locations">
                            <SubjectFacilityDetails subject={subject}/>
                        </CollapsibleCard>
                        <CollapsibleCard header="Contracts">
                            <SubjectContractDetails subject={subject}/>
                        </CollapsibleCard>
                        <CollapsibleCard header="Documents" defaultOpen>
                            <DocumentManager entityType={EntityType.SUBJECT}
                                             entityId={this.props.match.params.id} editable={this.isEditable()}/>
                        </CollapsibleCard>
                    </Tab>
                    {!isHROnlyRole() &&
                    <Tab eventKey='investigation' title={'Investigation Information'}>
                        <CollapsibleCard header="Clearance">
                            {!clearanceEdit && this.isEditable() && !isReadOnlyRole() &&
                            <div className="d-flex justify-content-right">
                                <div className="ml-auto">
                                    <Button variant="primary" onClick={this.editClearance}>
                                        <span className="fa fa-square-pen" aria-hidden="true"/> Edit
                                    </Button>
                                </div>
                            </div>
                            }
                            {!clearanceEdit && <SubjectClearanceDetails subject={subject}/>}
                            {clearanceEdit &&
                            <SubjectClearanceUpdate subjectId={this.props.match.params.id}
                                                    onFinish={this.editClearanceFinish}/>}
                        </CollapsibleCard>
                        <CollapsibleCard header="Additional Clearance">
                            <SubjectClearanceList subjectId={this.props.match.params.id}/>
                        </CollapsibleCard>
                        <CollapsibleCard header="Fingerprinting">
                            <SubjectFingerprintDetails subject={subject}/>
                        </CollapsibleCard>
                        <CollapsibleCard header="Background Information">
                            <SubjectBackgroundInformation subject={subject}/>
                        </CollapsibleCard>
                        <CollapsibleCard header="Eqip Information">
                            {!eqipInvEdit && this.isEditable() && !isReadOnlyRole() &&
                            <div className="d-flex justify-content-right">
                                <div className="ml-auto">
                                    <Button variant="primary" onClick={this.editEqipInv}>
                                        <span className="fa fa-square-pen" aria-hidden="true"/> Edit
                                    </Button>
                                </div>
                            </div>
                            }
                            {!eqipInvEdit && <SubjectEqipInformation subject={subject}/>}
                            {eqipInvEdit &&
                            <SubjectEqipUpdate subject={subject} onClose={this.editEqipFinish}/>}
                        </CollapsibleCard>
                    </Tab>
                    }
                    {!isHROnlyRole() &&
                    <Tab eventKey='selfReporting' title={'Reportable Information'}>
                        <CollapsibleCard header="Foreign Contacts" defaultOpen={true}>
                        <SubjectReportForeginContacts contactReportables={subject.foreignContactsQuestionnaireReportables} travelReportables={subject.foreignTravels} />
                             
                        </CollapsibleCard>
                        <CollapsibleCard header="Foreign Properties">
                            <SubjectReportForeginProperty foreignProperties={subject.foreignProperties}/>
                        </CollapsibleCard>
                        <CollapsibleCard header="Foreign Banks">
                            <SubjectReportBankAccount foreignBankAccts={subject.foreignBankAccts}/> 
                        </CollapsibleCard>
                        <CollapsibleCard header="Foreign Voting Details">
                        <SubjectReportVoting foreignVotings={subject.foreignVotings}/>
                        </CollapsibleCard>
                        <CollapsibleCard header="Foreign Citizenships">
                        <SubjectReportCitizenShip foreignCitizenships={subject.foreignCitizenships}/>
                        </CollapsibleCard>
                        <CollapsibleCard header="Foreign ID/Cards">
                            <SubjectReportIdCard foreignIdCards={subject.foreignIdCards}/>
                        </CollapsibleCard>
                        <CollapsibleCard header="Foreign Travels">
                            <SubjectReportForeignTravel foreignTravels={subject.foreignTravels} />
                        </CollapsibleCard>
                        <CollapsibleCard header="Foreign Adoptions">
                            <SubjectReportAdoptions foreignChildAdoptions={subject.foreignChildAdoptions}/>
                        </CollapsibleCard>
                        <CollapsibleCard header="Financial Issues & Anomalies">
                            <SubjectReportFinancialIssueAnomaly 
                                financialIssuesAndAnomalies={subject.financialIssuesAndAnomalies}/>
                        </CollapsibleCard>
                        <CollapsibleCard header={"Alcohol/Drug Treatments"}>
                            <SubjectReportAlcoholDrugTreatment
                                alcoholDrugTreatmentReportables={subject.alcoholDrugTreatmentReportables}/>
                        </CollapsibleCard>
                        <CollapsibleCard header={"Reports by Others"}>
                        <SubjectReportOthers reportedByOthersReportables={subject.reportedByOthersReportables}/>
                           
                        </CollapsibleCard>
                        <CollapsibleCard header="Media Contacts">
                            <SubjectReportIMediaContact mediaContacts={subject.mediaContacts}/>
                           
                        </CollapsibleCard>
                        <CollapsibleCard header="Cohabitants">
                        <SubjectReportCohabitant  cohabitants={subject.cohabitants}/> 
                        </CollapsibleCard>
                        <CollapsibleCard header="Arrests">
                        <SubjectReportArrest arrests={subject.arrests} /> 
                        </CollapsibleCard>
                        <CollapsibleCard header="Foreign Intelligence Contacts">
                            <SubjectReportIntelligenceContact
                                foreignIntelligenceEntities={subject.foreignIntelligenceEntities}/>
                        </CollapsibleCard>
                    </Tab>
                    }
                </Tabs>
            </div>
        )
    }
}

function mapStateToProps({person}) {
    return {
        subject: person
    }
}

export default connect(mapStateToProps, {
    fetchPerson,
    showSuccessGrowl,
    showErrorGrowl,
    removeOrgFromSubject,
    deleteDocument,
    reportForeignTravel
})(SubjectDetail);