import React, {Component, Fragment} from "react";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import {DateUtils} from "../../../../../actions/common_utils";
import PropTypes from "prop-types";

export class SubjectCohabitantDetails extends Component {
    getCohabitants() {
        return this.props.cohabitants.map((cohabitant) => {
            return <tr key={cohabitant.id}>
                <td>{cohabitant.firstName} {cohabitant.lastName}</td>
                <td>{cohabitant.citizenCountry}</td>
                <td>{cohabitant.birthPlace}</td>
                <td>{cohabitant.birthDate ? DateUtils.getDateString(cohabitant.birthDate) : "Unknown"}</td>
                <td>{cohabitant.startDate ? DateUtils.getDateString(cohabitant.startDate) : "Unknown"}</td>
                <td>
                    {cohabitant.dateInvalidated ?
                        `${DateUtils.getDateString(cohabitant.dateInvalidated)} (${cohabitant.reasonInvalidated ? cohabitant.reasonInvalidated.displayName : "Unknown"})`
                        : "Present"}
                </td>
            </tr>
        });
    }

    render() {
        const {cohabitants} = this.props;

        if (!cohabitants || cohabitants.length === 0) {
            return <Alert variant="danger">No Cohabitants!</Alert>
        }

        return (
            <Fragment>
                <Table>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Citizenship</th>
                        <th>Birth Place</th>
                        <th>Birth Date</th>
                        <th>From Date</th>
                        <th>To Date</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.getCohabitants()}
                    </tbody>
                </Table>
            </Fragment>
        )
    }
}
