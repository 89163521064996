import { Formik, Field, ErrorMessage } from "formik";
import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { Form, Card, Col, Button, ButtonToolbar, Modal } from "react-bootstrap";
import { FieldMultiSelectDropdown } from "../../../../components/redux_form/field_components_rb";
import { Picky } from 'react-picky';
import { FormikFieldMultiSelectDropDown } from "../../../../components/formik/formik_multiselectdropdown";
import { CommonUtils } from "../../../../actions/common_utils";




export const Service_Filter = ({ filters, onSubmit}) => {

    const [ShowHover, setShowHover] = useState(false);
    const popupRef = useRef(null);

    useEffect(()=>{
        const handleClickOutside = (event) => { 
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setShowHover(false)
            }
        }; 
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    },[])


    const OnSubmitClick = (values) => {
        onSubmit(values)
        setShowHover(false)
    }

    const OnResetClick = () => { 

        onSubmit({ 
            name: ""

        })
        setShowHover(false)
    }

    return (
        <>
            <div class="dropdown filter_menu" ref={popupRef}>
                <div class="btn" onClick={() => { setShowHover(true) }}>
                    <i class="fa-solid fa-filter"></i>
                </div>
                {ShowHover && <div class="w-max dropdown-menu-end filter-dropdown">
                    <Formik
                        initialValues={filters}
                        enableReinitialize={true}
                        onSubmit={OnSubmitClick}
                    >
                        {({
                            isSubmitting,
                            values,
                            handleChange,
                            setFieldValue,
                            setFieldTouched,
                            handleSubmit,
                            touched,
                            resetForm,
                            errors,
                            handleBlur 
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}> 
                                <div className="col-lg-12">
                                    <div className="form-group mb-3">
                                        <label className="form-label col-form-label">Name</label>
                                        <Form.Control
                                            type="text"
                                            name={"name"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder={"Enter User Name"}
                                            value={values.name}
                                        />
                                    </div>
                                    <ButtonToolbar>
                                        <button type="button" class="btn btn-primary" onClick={() => {
                                            resetForm();
                                            OnResetClick();
                                        }}>Reset</button>
                                        <button type="submit" class="btn btn-primary">Filter</button>
                                    </ButtonToolbar>
                                </div> 
                            </Form>
                        )}
                    </Formik>
                </div>}

            </div>
        </>
    )
}