import React from "react";
import {connect} from "react-redux";
import Modal from "react-bootstrap/Modal";
import {Field, Formik} from "formik";
import * as Yup from "yup";
import {Button, ButtonToolbar, Card, Col, Form} from "react-bootstrap";
import {FormikFieldDropzone} from "../../../../../components/formik/formik_field_dropzone";
import {debriefClearance} from "../../../../../actions/clearance_actions";
import {showErrorGrowl} from "../../../../../actions/msg_actions";
import PropTypes from "prop-types";
import {FormikDatePickerComponent} from "../../../../../components/formik/formik_date_picker";
import DeleteConfirmationModal from "../../../../../components/delete_confirm_modal";

class ClearanceDebriefComp extends React.Component {
    constructor(props) {
        super(props);

        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
        this.state = {
            showModal: false
        }
    }

    open() {
        this.setState({showModal: true});
    }

    close() {
        this.setState({showModal: false});
    }

    onSubmit = async (values) => {
        let {clearance, showErrorGrowl, onFinish} = this.props;
        console.log('Debrief are ', values);
        try {
            await debriefClearance(values, clearance.id);
            this.close();
            onFinish();
        } catch (error) {
            showErrorGrowl("Unexpected error occurred", "Error Debriefing clearance.");
            console.log(error);
        }
    }

    render() {
        let {clearance} = this.props;
        return (
            <React.Fragment>
                {!clearance.debriefInfo &&
                <Button variant={'link'} className="close" onClick={this.open}>
                        <span aria-hidden="true" style={{paddingRight: 10}} title={"Debrief Clearance"} aria-label="Debrief">
                            <i className="fa fa-person-circle-xmark"></i>
                        </span>
                </Button>
                }
                <Modal size="lg" show={this.state.showModal} onHide={this.close}>
                    <Modal.Header closeButton>
                        <Modal.Title>Debrief Clearance</Modal.Title>
                    </Modal.Header>
                    <Formik
                        initialValues={{
                            documents: "",
                            date: '',
                            comments: ""
                        }}
                        enableReinitialize={true}
                        onSubmit={this.onSubmit}
                        validationSchema={
                            Yup.object().shape({
                                date: Yup.date().required('Debriefing date is required')
                            })

                        }
                    >
                        {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur}) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Card>
                                    <Card.Header>Debriefing document (Optional)</Card.Header>
                                    <Card.Body>
                                        <Form.Row>
                                            <Form.Group as={Col} md="12" controlId={'documents'}>
                                                <Field
                                                    name="documents"
                                                    component={FormikFieldDropzone}
                                                    // accept={accept}
                                                    multiple={false}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} md="8">
                                                <Form.Label>Comments</Form.Label>
                                                <Form.Control required type="text" name={'comments'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur} placeholder={'Comments'}
                                                              isInvalid={touched.comments && !!errors.comments}
                                                              value={values.comments}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.comments}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="4">
                                                <Form.Label>Debrief Date</Form.Label>
                                                <Field
                                                    id="date"
                                                    name="date"
                                                    component={FormikDatePickerComponent}
                                                    placeholder={"Date subject debriefed"}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                        <ButtonToolbar>
                                            <Button variant="primary" type="submit">Complete</Button>
                                            <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="Debriefing"
                                                  handleDeleteCallback={this.close}/>
                                        </ButtonToolbar>
                                    </Card.Body>
                                </Card>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </React.Fragment>);
    }
}

export const ClearanceDebrief = connect(null, {showErrorGrowl})(ClearanceDebriefComp);

ClearanceDebrief.propTypes = {
    clearance: PropTypes.object.isRequired,
    onFinish: PropTypes.func.isRequired
}