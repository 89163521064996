import React, {Component, Fragment} from "react";
import {DateUtils} from "../../../../../actions/common_utils";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";

export class SubjectForeignBankDetails extends Component {
    getForeignBanks() {
        return this.props.foreignBankAccts.map((bank) => {
            return <tr key={bank.id}>
                <td>{bank.instName}</td>
                <td>{bank.country}</td>
                <td>{bank.accountNeedOrUsedFor}</td>
                <td>{bank.startDate ? DateUtils.getDateString(bank.startDate) : "Unknown"}</td>
                <td>
                    {bank.dateInvalidated ?
                        `${DateUtils.getDateString(bank.dateInvalidated)} (${bank.reasonInvalidated ? bank.reasonInvalidated.displayName : "Unknown"})`
                        : "N/A"}
                </td>
            </tr>
        })
    }

    render() {
        const {foreignBankAccts} = this.props;

        if (!foreignBankAccts || foreignBankAccts.length === 0) {
            return <Alert variant="danger">No Foreign Banks</Alert>
        }

        return (
            <Fragment>
                <Table>
                    <thead>
                    <tr>
                        <th>Bank Name</th>
                        <th>Country</th>
                        <th>Purpose</th>
                        <th>Account Opened Date</th>
                        <th>Account Closed Date</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.getForeignBanks()}
                    </tbody>
                </Table>
            </Fragment>
        );
    }
}