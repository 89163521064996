import React, {Component, Fragment} from "react";
import {DateUtils} from "../../../../../actions/common_utils";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";

export class SubjectForeignCitizenshipDetails extends Component {
    getForeignCitizenships() {
        return this.props.foreignCitizenships.map((citizenship) => {
            return <tr key={citizenship.id}>
                <td>{citizenship.country}</td>
                <td>{citizenship.basis}</td>
                <td>{citizenship.startDate ? DateUtils.getDateString(citizenship.startDate) : "Unknown"}</td>
                <td>{citizenship.dateInvalidated ? DateUtils.getDateString(citizenship.dateInvalidated) : "Present"}</td>
            </tr>
        })
    }

    render() {
        const {foreignCitizenships} = this.props;

        if (!foreignCitizenships || foreignCitizenships.length === 0) {
            return <Alert variant="danger">No Foreign Citizenships!</Alert>
        }

        return (
            <Fragment>
                <Table>
                    <thead>
                    <tr>
                        <th>Country</th>
                        <th>Basis of Citizenship</th>
                        <th>Date Acquired</th>
                        <th>Date Relinquished</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.getForeignCitizenships()}
                    </tbody>
                </Table>
            </Fragment>
        );
    }
}