import React from "react";
import {Field, Formik} from "formik";
import * as Yup from "yup";
import {connect} from "react-redux";
import {showErrorGrowl, showSuccessGrowl} from "../../../../../actions/msg_actions";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Form from "react-bootstrap/Form";
import {CommonUtils, DateUtils, MARRIED_STATUS, SINGLE_STATUS} from "../../../../../actions/common_utils";
import {Card} from "react-bootstrap";
import {approveTask, fetchTask, fetchTaskAsync, rejectTask} from "../../../../../actions/task_actions";
import {
    downloadDocument,
    fetchDocumentsUploadedForTask,
    fetchDocumentsUploadedForTaskAsync
} from "../../../../../actions/doc_actions";
import filterFactory from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import {getDocTypesByEntityType, getEmployeeTypes,getCitizenshipAcquiredTypes} from "../../../../../actions/fieldValue_actions";
import {FormikFieldDualListBox} from "../../../../../components/formik/formik_field_duallistbox";
import {FormikDatePickerComponent} from "../../../../../components/formik/formik_date_picker";
import MaskedInput from "react-input-mask";
import {getActiveOrgContractsAsync} from "../../../../../actions/contract_actions";
import {
    approveNewHire,
    createNewHireRequest,
    notifySubject,
    rejectDocs,
    remindSubject
} from "../../../../../actions/new_hire_actions";
import Alert from "react-bootstrap/Alert";
import {FormikFieldDropzone} from "../../../../../components/formik/formik_field_dropzone";

class SubjectNewHireTaskReviewComp extends React.Component {
    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
        this.state = {
            docs: []
        }

    }

    initializeValuesOfIssue = (task) => {
        let newHireDetails = task.subjectHireDetailsUI;
        if (newHireDetails.employeeTypes){
            let employeeTypes = [];
            for (let employeeType of newHireDetails.employeeTypes){
                if (employeeType.id) {
                    employeeTypes.push(employeeType.id);
                }
            }
            newHireDetails.employeeTypes = employeeTypes;
        }
        if (task.requiredDocs){
            let requiredDocs = [];
            for (let requiredDoc of task.requiredDocs){
                if (requiredDoc.id) {
                    requiredDocs.push(requiredDoc.id);
                }
            }
            task.requiredDocs = requiredDocs;
        }
        if (task.subjectHireDetailsUI && task.subjectHireDetailsUI.citizenshipAcquiredType && task.subjectHireDetailsUI.citizenshipAcquiredType.id){
            task.subjectHireDetailsUI.citizenshipAcquiredType = task.subjectHireDetailsUI.citizenshipAcquiredType.id;
        }
        if (task.subjectHireDetailsUI && task.subjectHireDetailsUI.citizenshipAcquiredType) {
            task.subjectHireDetailsUI.citizenshipAcquiredType = task.subjectHireDetailsUI.citizenshipAcquiredType;
        }
    }

    fetchTask = async (id) => {
        const task = await fetchTaskAsync(id);
        this.initializeValuesOfIssue(task);
        this.setState({
            task
        })
    }

    fetchDocumentsUploadedForTask = async (id) => {
        const response = await fetchDocumentsUploadedForTaskAsync(id);
        this.setState({
            docs : response.data
        })
    }

    componentDidMount() {
        this.fetchTask(this.props.match.params.id);
        try {
            this.fetchDocumentsUploadedForTask(this.props.match.params.id);
        } catch (e) {
            console.log("Error is ", e)
        }
        getDocTypesByEntityType('SUBJECT', (response) => {
            this.setState({
                avail_doc_types: response.data.filter(docType=>docType.onBoardingDoc).map(docType => {
                    return {
                        value: docType.id,
                        label: docType.type
                    }
                })
            });
        });
        this.getEmpTypes();
        this.getContracts();
        this.getCitizenshipTypes();
    }

    getEmpTypes = async () => {
        const response = await getEmployeeTypes();
        this.setState({
            avail_employee_types: response.data.filter((empType)=>empType.onBoardingType).map((empType) => {
                return {
                    value: empType.id,
                    label: empType.longName
                }
            })
        });
    }

    getContracts = async () => {
        const contracts = await getActiveOrgContractsAsync((error) => {
            this.props.showErrorGrowl("Cannot fetch contracts available", error.message);
        });
        if (contracts) {
            this.setState({
                avail_contracts: contracts.map((contract) => {
                    return {
                        value: contract.id,
                        label: contract.name
                    }
                })
            });
        }
    }

    onNotifySubject = async (values) => {
        this.setState({disableSubmit: true});
        this.resetEnums(values);
        console.log('Notifying using ', values)
        try {
            const response = await notifySubject(values);
            this.props.showSuccessGrowl("New Hire Subject Notification", "Subject Notified successfully");
            this.props.history.push("/org/dash/task");
        } catch (e) {
            console.log(e);
            this.props.showErrorGrowl("Unable to notify subject", "Unexpected error");
        }
        this.setState({disableSubmit: false});
    }

    onRejectDocs = async (values) => {
        this.setState({disableSubmit: true});
        this.resetEnums(values);
        try {
            const response = await rejectDocs(values);
            this.props.showSuccessGrowl("New Hire Subject Notification", "Subject notified about the document rejection");
            this.props.history.push("/org/dash/task");
        } catch (e) {
            console.log(e);
            this.props.showErrorGrowl("Unable to notify subject", "Unexpected error");
        }
        this.setState({disableSubmit: false});
    }

    onRemindSubject = async (values) => {
        this.setState({disableSubmit: true});
        this.resetEnums(values);
        try {
            const response = await remindSubject(values);
            this.props.showSuccessGrowl("New Hire Subject Notification", "Subject reminded successfully");
            this.props.history.push("/org/dash/task");
        } catch (e) {
            console.log(e);
            this.props.showErrorGrowl("Unable to notify subject", "Unexpected error");
        }
        this.setState({disableSubmit: false});
    }

    resetEnums(values) {
        if (values.entityType && values.entityType.id) {
            values.entityType = values.entityType.id;
        }
        if (values.issueType && values.issueType.id) {
            values.issueType = values.issueType.id;
        }
        if (values.docSubmitWorkflowStatus && values.docSubmitWorkflowStatus.id) {
            values.docSubmitWorkflowStatus = values.docSubmitWorkflowStatus.id;
        }
        if (values.clearanceCheckIssue && values.clearanceCheckIssue.id) {
            if (values.clearanceCheckIssue.issueType && values.clearanceCheckIssue.issueType.id) {
                values.clearanceCheckIssue.issueType = values.clearanceCheckIssue.issueType.id;
            }
            if (values.clearanceCheckIssue.entityType && values.clearanceCheckIssue.entityType.id) {
                values.clearanceCheckIssue.entityType = values.clearanceCheckIssue.entityType.id;
            }
            if (values.clearanceCheckIssue.docSubmitWorkflowStatus && values.clearanceCheckIssue.docSubmitWorkflowStatus.id) {
                values.clearanceCheckIssue.docSubmitWorkflowStatus = values.clearanceCheckIssue.docSubmitWorkflowStatus.id;
            }
            if (values.clearanceCheckIssue.subjectHireDetailsUI && values?.clearanceCheckIssue.subjectHireDetailsUI?.citizenshipAcquiredType) {
                if (values?.clearanceCheckIssue.subjectHireDetailsUI?.citizenshipAcquiredType?.id) {
                    values.clearanceCheckIssue.subjectHireDetailsUI.citizenshipAcquiredType = values?.clearanceCheckIssue.subjectHireDetailsUI?.citizenshipAcquiredType?.id || ' ';
                } else {
                    values.clearanceCheckIssue.subjectHireDetailsUI.citizenshipAcquiredType = values?.clearanceCheckIssue.subjectHireDetailsUI?.citizenshipAcquiredType || ' ';
                }
            } else {
                delete values.clearanceCheckIssue.subjectHireDetailsUI.citizenshipAcquiredType;
                delete values.clearanceCheckIssue.subjectHireDetailsUI.citizenshipDate;
            }
            if (values.clearanceCheckIssue.subjectHireDetailsUI && values?.clearanceCheckIssue.subjectHireDetailsUI?.requiredClearanceLevel) {
                values.clearanceCheckIssue.subjectHireDetailsUI.requiredClearanceLevel = values?.clearanceCheckIssue.subjectHireDetailsUI?.requiredClearanceLevel?.id || '';
            } else {
                delete values.clearanceCheckIssue.subjectHireDetailsUI.requiredClearanceLevel;
            }
        }
        if (values.subjectHireDetailsUI && values?.subjectHireDetailsUI?.citizenshipAcquiredType) {
            if (values?.subjectHireDetailsUI?.citizenshipAcquiredType?.id) {
                values.subjectHireDetailsUI.citizenshipAcquiredType = values?.subjectHireDetailsUI?.citizenshipAcquiredType?.id || ' ';
            } else {
                values.subjectHireDetailsUI.citizenshipAcquiredType = values?.subjectHireDetailsUI?.citizenshipAcquiredType || ' ';
            }
        } else {
            delete values.subjectHireDetailsUI.citizenshipAcquiredType;
            delete values.subjectHireDetailsUI.citizenshipDate;
        }
        if (values.subjectHireDetailsUI && values?.subjectHireDetailsUI?.requiredClearanceLevel) {
            values.subjectHireDetailsUI.requiredClearanceLevel = values?.subjectHireDetailsUI?.requiredClearanceLevel?.id || '';
        } else {
            delete values.subjectHireDetailsUI.requiredClearanceLevel;
        }
    
    }

    async onSubmit(values) {
        console.log("post data",values) 
        this.resetEnums(values);
        try {
            const response = await approveNewHire(values);
            console.log('Response is ', response);
            this.props.showSuccessGrowl("New Hire Approved", "New Hire has been approved");
            this.props.history.push("/org/dash/task");
        } catch (e) {
            console.log(e);
            this.props.showErrorGrowl("Unable to approve new hire", "Unexpected error");
        }
    }

    getColumns() {
        return [{
            dataField: "name",
            text: "Document Name",
            formatter: (cell, row) => cell,
        }, {
            dataField: "type",
            text: "Type",
            formatter: (cell, row) => row.type ? (row.type.id === "OTHER" ? "Other: " + row.otherType : row.type.type) : "",
        }, {
            dataField: "dateUploaded",
            text: "Uploaded Date",
            formatter: (cell, row) => DateUtils.getDateString(cell),
        }, {
            dataField: "comments",
            text: "Comments",
            formatter: (cell, row) => cell,
        }, {
            dataField: "actions",
            text: "Actions",
            formatter: (cell, row) => (
                <ButtonToolbar style={{flexWrap: "nowrap"}}>
                    <Button variant={'link'} className="close" onClick={() => downloadDocument(row.awsS3ObjectKey)}>
                        <span aria-hidden="true" title={"Download Document"} style={{padding: 10}} aria-label="Download Document">
                            <i className="fa fa-circle-down"/>
                        </span>
                    </Button>
                </ButtonToolbar>
            )
        }]
    }

    getDocumentsRequired = (requiredDocs) => {
        let retStr = '';
        if (requiredDocs){
            requiredDocs.forEach((requiredDoc)=>{
                retStr = requiredDoc.type + ', ';
            })
            return retStr.substr(0, retStr.length-2);
        }
        return '';
    }
    getCitizenshipTypes = async () => {
        const response = await getCitizenshipAcquiredTypes();
        let newCitizenshipTypes = [];
        const chosenData = this.state.props?.subjectHireDetailsUI?.citizenshipAcquiredType;
        response.data.forEach((citizenType) => {
          if (citizenType.displayName.toLowerCase() !== "user") {
            newCitizenshipTypes.push({
              id: citizenType.id,
              name: citizenType.displayName,
            });
          }
        });
        // Find the chosen data item and remove it from the array
        const chosenItem = newCitizenshipTypes.find(
          (item) => item?.id === chosenData?.id
        );
      
        if (chosenItem) {
          newCitizenshipTypes = newCitizenshipTypes.filter(
            (item) => item?.id !== chosenData?.id
          );
        }
        // Sort the remaining items by id
        newCitizenshipTypes.sort((a, b) => a?.id - b?.id);
      
        if (chosenItem) {
          newCitizenshipTypes?.unshift(chosenItem);
        }      
        this.setState({
          citizenshipTypes: newCitizenshipTypes,
        });
      };
     
    render() {

        let {avail_doc_types, avail_employee_types, avail_contracts, disableSubmit, task, docs} = this.state;
        if (!task) {
            return <div>Loading...</div>;
        } 

        task.rejectionReason = '';
        return (
            <React.Fragment>
                <Card>
                    <Card.Header>{(task.dateCompleted === null)?'Task Review':'Task Detail'}</Card.Header>
                    <Formik
                        initialValues={task}
                        enableReinitialize={true}
                        onSubmit={this.onSubmit}
                        validationSchema={
                            Yup.object().shape({})
                        }
                    >
                        {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur,setValues}) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Card.Body>
                                    {(values.docSubmitWorkflowStatus === 'SUBJECT_NOTIFIED' || values.docSubmitWorkflowStatus.id === 'SUBJECT_NOTIFIED') &&
                                    <Alert variant={'info'}>
                                        Subject has been notified to complete the forms. Once they complete you will be notified and can approve or reject
                                    </Alert>
                                    }
                                    <Form.Row>
                                        <Form.Group as={Col} md="4" controlId="name">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.name && !!errors.name}
                                                value={values.name}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.name}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="description">
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="description"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.description && !!errors.description}
                                                value={values.description}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.description}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="docSubmitWorkflowStatus">
                                            <Form.Label>Workflow Status</Form.Label>
                                            <Form.Control
                                                required
                                                disabled
                                                type={'text'}
                                                name="docSubmitWorkflowStatus"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.docSubmitWorkflowStatus && !!errors.docSubmitWorkflowStatus}
                                                value={values.docSubmitWorkflowStatus?values.docSubmitWorkflowStatus.displayName:''}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.docSubmitWorkflowStatus}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control required type="text" name={'subjectHireDetailsUI.personFirstName'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          isInvalid={touched.subjectHireDetailsUI && touched.subjectHireDetailsUI.personFirstName && !!(errors.subjectHireDetailsUI && errors.subjectHireDetailsUI.personFirstName)}
                                                          value={values.subjectHireDetailsUI.personFirstName}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.subjectHireDetailsUI?errors.touched.subjectHireDetailsUI.personFirstName:''}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Middle Name</Form.Label>
                                            <Form.Control required type="text" name={'subjectHireDetailsUI.personMiddleName'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          isInvalid={touched.subjectHireDetailsUI && touched.subjectHireDetailsUI.personMiddleName && !!(errors.subjectHireDetailsUI && errors.subjectHireDetailsUI.personMiddleName)}
                                                          value={values.subjectHireDetailsUI.personMiddleName}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.subjectHireDetailsUI?errors.touched.subjectHireDetailsUI.personMiddleName:''}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control required type="text" name={'subjectHireDetailsUI.personLastName'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          isInvalid={touched.subjectHireDetailsUI && touched.subjectHireDetailsUI.personLastName && !!(errors.subjectHireDetailsUI && errors.subjectHireDetailsUI.personLastName)}
                                                          value={values.subjectHireDetailsUI.personLastName}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.subjectHireDetailsUI?errors.touched.subjectHireDetailsUI.personLastName:''}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Suffix</Form.Label>
                                            <Form.Control required type="text" name={'subjectHireDetailsUI.suffix'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          isInvalid={touched.subjectHireDetailsUI && touched.subjectHireDetailsUI.suffix && !!(errors.subjectHireDetailsUI && errors.subjectHireDetailsUI.suffix)}
                                                          value={values.subjectHireDetailsUI.suffix}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.subjectHireDetailsUI?errors.touched.subjectHireDetailsUI.suffix:''}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId={'subjectHireDetailsUI.personBirthDate'}>
                                            <Form.Label>Date of Birth</Form.Label>
                                            <Field
                                                id="subjectHireDetailsUI.personBirthDate"
                                                name="subjectHireDetailsUI.personBirthDate"
                                                component={FormikDatePickerComponent}
                                                placeholder={"Date of Birth"}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="socialSecurityNumber">
                                            <Form.Label>SSN</Form.Label>
                                            <Form.Control
                                                required
                                                type={"text"}
                                                name="subjectHireDetailsUI.socialSecurityNumber"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.subjectHireDetailsUI && touched.subjectHireDetailsUI.socialSecurityNumber && !!(errors.subjectHireDetailsUI && errors.subjectHireDetailsUI.socialSecurityNumber)}
                                                value={values.subjectHireDetailsUI.socialSecurityNumber}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.subjectHireDetailsUI?errors.touched.subjectHireDetailsUI.socialSecurityNumber:''}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md={"4"} controlId="workEmailAddress">
                                            <Form.Label>Email Address</Form.Label>
                                            <Form.Control
                                                type="email"
                                                name="subjectHireDetailsUI.workEmailAddress"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Work Email Address"
                                                isInvalid={touched.subjectHireDetailsUI && touched.subjectHireDetailsUI.workEmailAddress && !!(errors.subjectHireDetailsUI && errors.subjectHireDetailsUI.workEmailAddress)}
                                                value={values.subjectHireDetailsUI.workEmailAddress}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.subjectHireDetailsUI?errors.touched.subjectHireDetailsUI.workEmailAddress:''}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="homePhone">
                                            <Form.Label>Phone Number</Form.Label>
                                            <Form.Control
                                                required
                                                as={MaskedInput}
                                                name="subjectHireDetailsUI.homePhone"
                                                mask="999-999-9999"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.subjectHireDetailsUI && touched.subjectHireDetailsUI.homePhone && !!(errors.subjectHireDetailsUI && errors.subjectHireDetailsUI.homePhone)}
                                                value={values.subjectHireDetailsUI.homePhone}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.subjectHireDetailsUI?errors.touched.subjectHireDetailsUI.homePhone:''}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId={'startDate'}>
                                            <Form.Label>Start Date</Form.Label>
                                            <Field
                                                id="subjectHireDetailsUI.startDate"
                                                name="subjectHireDetailsUI.startDate"
                                                component={FormikDatePickerComponent}
                                                placeholder={"First day of work"}
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                           {values.subjectHireDetailsUI.citizenshipAcquiredType &&
                                            <Form.Group as={Col} md="4" controlId={'subjectHireDetailsUI.citizenshipAcquiredType'}>
                                                <Form.Label>Citizenship Type</Form.Label>
                                                <Form.Control required as="select" name={'subjectHireDetailsUI.citizenshipAcquiredType'}
                                                         onChange={handleChange}
                                                         onBlur={handleBlur}
                                                         isInvalid={touched.subjectHireDetailsUI && touched.subjectHireDetailsUI.citizenshipAcquiredType && !!(errors.subjectHireDetailsUI && errors.subjectHireDetailsUI.citizenshipAcquiredType)}
                                                         value={values.subjectHireDetailsUI.citizenshipAcquiredType}
                                                 >
                                                        {CommonUtils.getOptionsForSelect(this.state.citizenshipTypes)}
                                               </Form.Control>
                                                <Form.Control.Feedback type="invalid">
                                                {errors.subjectHireDetailsUI?errors.touched.subjectHireDetailsUI.citizenshipAcquiredType:''}
                                                </Form.Control.Feedback>
                                             </Form.Group>}
                                             {values.subjectHireDetailsUI.citizenshipDate &&
                                              <Form.Group as={Col} md="4" controlId={'citizenshipDate'}>
                                                   <Form.Label> Citizenship Date</Form.Label>
                                                       <Field
                                                         id="subjectHireDetailsUI.citizenshipDate"
                                                        name="subjectHireDetailsUI.citizenshipDate"
                                                        component={FormikDatePickerComponent}
                                                        placeholder={"citizen Ship Date"}
                                                        />
                                              </Form.Group>}
                                                    <Form.Control.Feedback type="invalid">
                                                         {errors.citizenshipDate}
                                                </Form.Control.Feedback>
                                    </Form.Row>
                                    {task.clearanceCheckIssue && <div class="card  mt-3 mb-3">
                                        <div class="card-body">
                                            <h5 class="card-title mt-4 mb-4">Clearance Check</h5>
                                            <Form.Row>
                                                <Form.Group as={Col} md="4" controlId="clearanceCheckIssue.eligibilityLevel">
                                                    <Form.Label>Eligibility Level</Form.Label>
                                                    <Form.Control
                                                        required
                                                        name="clearanceCheckIssue.eligibilityLevel"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={touched.clearanceCheckIssue && touched.clearanceCheckIssue.eligibilityLevel && !!(errors.clearanceCheckIssue && errors.clearanceCheckIssue.eligibilityLevel)}
                                                        value={values.clearanceCheckIssue.eligibilityLevel}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.clearanceCheckIssue && errors.clearanceCheckIssue.eligibilityLevel}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} md="4" controlId="clearanceCheckIssue.eligibilityDeterminationDetails">
                                                    <Form.Label>Eligibility Determination</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        name="clearanceCheckIssue.eligibilityDeterminationDetails"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={touched.clearanceCheckIssue && touched.clearanceCheckIssue.eligibilityDeterminationDetails && !!(errors.clearanceCheckIssue && errors.clearanceCheckIssue.eligibilityDeterminationDetails)}
                                                        value={values.clearanceCheckIssue.eligibilityDeterminationDetails}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.clearanceCheckIssue && errors.clearanceCheckIssue.eligibilityDeterminationDetails}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} md="4" controlId="clearanceCheckIssue.exceptionCode">
                                                    <Form.Label>Exception Code</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        name="clearanceCheckIssue.exceptionCode"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.clearanceCheckIssue.exceptionCode}
                                                    />
                                                </Form.Group>
                                            </Form.Row>

                                            <Form.Row>
                                                <Form.Group as={Col} md="4" controlId="clearanceCheckIssue.investigation">
                                                    <Form.Label>Supporting Investigation</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        name="clearanceCheckIssue.investigation"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={touched.clearanceCheckIssue && touched.clearanceCheckIssue.investigation && !!(errors.clearanceCheckIssue && errors.clearanceCheckIssue.investigation)}
                                                        value={values.clearanceCheckIssue.investigation}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.clearanceCheckIssue && errors.clearanceCheckIssue.investigation}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} md="4" controlId="clearanceCheckIssue.cvEnrollmentType">
                                                    <Form.Label>CV Enrollment Type</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        name="clearanceCheckIssue.cvEnrollmentType"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={touched.clearanceCheckIssue && touched.clearanceCheckIssue.cvEnrollmentType && !!(errors.clearanceCheckIssue &&errors.clearanceCheckIssue.cvEnrollmentType)}
                                                        value={values.clearanceCheckIssue.cvEnrollmentType}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.clearanceCheckIssue && errors.clearanceCheckIssue.cvEnrollmentType}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} md="4" controlId="clearanceCheckIssue.cvEnrollmentDate">
                                                    <Form.Label>Effective Date</Form.Label>
                                                    <Field
                                                        id="clearanceCheckIssue.cvEnrollmentDate"
                                                        name="clearanceCheckIssue.cvEnrollmentDate"
                                                        component={FormikDatePickerComponent}
                                                    />
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} md="4" controlId="clearanceCheckIssue.comments">
                                                    <Form.Label>Comments</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        name="clearanceCheckIssue.comments"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.clearanceCheckIssue.comments}
                                                    />

                                                </Form.Group>
                                            </Form.Row>

                                        </div>
                                    </div> }   
                                    <Form.Row>
                                        <Form.Group as={Col} md="12">
                                            <Form.Label>Employee Types</Form.Label>
                                            <Field
                                                id="subjectHireDetailsUI.employeeTypes"
                                                name="subjectHireDetailsUI.employeeTypes"
                                                component={FormikFieldDualListBox}
                                                options={avail_employee_types}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.subjectHireDetailsUI?errors.touched.subjectHireDetailsUI.employeeTypes:''}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    {avail_contracts && avail_contracts.length > 0 &&
                                    <Form.Row>
                                        <Form.Group as={Col} md="12">
                                            <Form.Label>Contracts</Form.Label>
                                            <Field
                                                id="subjectHireDetailsUI.supportingContractIds"
                                                name="subjectHireDetailsUI.supportingContractIds"
                                                component={FormikFieldDualListBox}
                                                options={avail_contracts}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.subjectHireDetailsUI?errors.touched.subjectHireDetailsUI.supportingContractIds:''}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    }
                                    <Form.Row>
                                        <Form.Group as={Col} md="12">
                                            <Form.Label>Onboarding Document Types</Form.Label>
                                            <Field
                                                id="requiredDocs"
                                                name="requiredDocs"
                                                component={FormikFieldDualListBox}
                                                options={avail_doc_types}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.requiredDocs}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    {this.displayInstructionDocsUpload(values) &&
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" controlId={'instructionDocs'}>
                                            <Form.Label>User Instruction Documents</Form.Label>
                                            <Field
                                                name="instructionDocs"
                                                component={FormikFieldDropzone}
                                                multiple={true}/>
                                        </Form.Group>
                                    </Form.Row>
                                    }
                                    {docs && docs.length > 0 &&
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" controlId="docs">
                                        <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="id"
                                                        data={docs}
                                                        columns={this.getColumns()} filter={filterFactory()}
                                        />
                                        </Form.Group>
                                    </Form.Row>
                                    }
                                    {this.shouldDisplayApproveOrReject(task, values) &&
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" controlId="rejectionReason">
                                            <Form.Label>Rejection Reason</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="rejectionReason"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Enter a rejection reason if rejecting"
                                                isInvalid={touched.rejectionReason && !!errors.rejectionReason}
                                                value={values.rejectionReason}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.rejectionReason}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    }
                                </Card.Body>
                                {this.shouldDisplayNotifySubject(values) &&
                                <Card.Footer>
                                    <ButtonToolbar>
                                        <Button variant="primary" onClick={()=>{
                                            this.onNotifySubject(values);
                                        }}
                                                disabled={isSubmitting || !values.requiredDocs || values.requiredDocs.length === 0 || disableSubmit}>Notify Subject</Button>
                                    </ButtonToolbar>
                                </Card.Footer>
                                }
                                {this.displayResendEmail(values) &&
                                <Card.Footer>
                                    <ButtonToolbar>
                                        <Button variant="primary" onClick={()=>{
                                            this.onRemindSubject(values);
                                        }}
                                                disabled={disableSubmit}>Resend Email</Button>
                                    </ButtonToolbar>
                                </Card.Footer>
                                }
                                {this.shouldDisplayApproveOrReject(task, values) &&
                                <Card.Footer>
                                    <ButtonToolbar>
                                        <Button variant="primary" type="submit"
                                                disabled={isSubmitting || values.rejectionReason}>Approve</Button>
                                        <Button variant="danger" onClick={()=>{
                                            this.onRejectDocs(values);
                                        }} disabled={isSubmitting || !values.rejectionReason}>Reject</Button>
                                    </ButtonToolbar>
                                </Card.Footer>
                                }
                            </Form>
                        )}
                    </Formik>
                </Card>
            </React.Fragment>
        )
    }

    displayResendEmail(values) {
        return values.docSubmitWorkflowStatus === 'SUBJECT_NOTIFIED' || values.docSubmitWorkflowStatus.id === 'SUBJECT_NOTIFIED' ||
            values.docSubmitWorkflowStatus === 'REJECTED' || values.docSubmitWorkflowStatus.id === 'REJECTED';
    }

    displayInstructionDocsUpload(values) {
        return values.docSubmitWorkflowStatus === 'PENDING' || values.docSubmitWorkflowStatus.id === 'PENDING';
    }

    shouldDisplayApproveOrReject(task, values) {
        return (task.dateCompleted === null) && (
            ((!values.requiredDocs || values.requiredDocs.length === 0) && (values.docSubmitWorkflowStatus === 'PENDING' || values.docSubmitWorkflowStatus.id === 'PENDING')) ||
            (values.requiredDocs && values.requiredDocs.length > 0 && (values.docSubmitWorkflowStatus === 'DOCS_SUBMITTED' || values.docSubmitWorkflowStatus.id === 'DOCS_SUBMITTED')));
    }

    shouldDisplayNotifySubject(values) {
        return (values.dateCompleted === null && values.requiredDocs && values.requiredDocs.length > 0) &&
            (values.docSubmitWorkflowStatus === 'PENDING' || values.docSubmitWorkflowStatus.id === 'PENDING');
    }
}

export const
    SubjectNewHireTaskReview = connect(null, {
        approveTask,
        rejectTask,
        showSuccessGrowl,
        showErrorGrowl,
        fetchTask,
        fetchDocumentsUploadedForTask
    })(SubjectNewHireTaskReviewComp);

