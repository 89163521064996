import React, {Component, Fragment} from "react";
import filterFactory, {textFilter} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import BootstrapTable from "react-bootstrap-table-next";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Field, Formik} from "formik";
import * as Yup from "yup";
import {Card, Col, Form, Modal, Button, Alert} from "react-bootstrap";
import {AVAILABLE_COUNTRIES, CommonUtils, DateUtils} from "../../../../../actions/common_utils";
import {FormikDatePickerComponent} from "../../../../../components/formik/formik_date_picker";

class ForeignCountryVisitListComponent extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {addedForeignCountryVisits} = this.props;

        const columns = [{
            dataField: 'country',
            text: 'Country',
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'carrierNumber',
            text: 'Carrier Number',
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'modeOfTransport',
            text: 'Mode of Transport',
            sort: true,
            filter: textFilter()
        }, {
            dataField: "arrivalDate",
            text: "Arrival Date",
            formatter: (cell, row) => DateUtils.getDateString(cell),
            sort: true,
        }, {
            dataField: "departureDate",
            text: "Departure Date",
            formatter: (cell, row) => DateUtils.getDateString(cell),
            sort: true,
        }];

        const pagination = paginationFactory({
            sizePerPageList: [
                {text: '10', value: 10},
                {text: '20', value: 20},
                {text: '30', value: 30}
            ]
        });

        return (
            <Fragment>
                <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="country"
                                data={addedForeignCountryVisits}
                                columns={columns} pagination={pagination}
                                filter={filterFactory()}/>
            </Fragment>
        )
    }

}

ForeignCountryVisitListComponent.propTypes = {
    addedForeignCountryVisits: PropTypes.array.isRequired
}

export const ForeignCountryVisitList =  connect(null, {})(ForeignCountryVisitListComponent);