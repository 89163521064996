import React, { Component, Fragment } from "react";
import { connect } from "react-redux"; 
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import { fetchPersonAsync, reportForSubjectBySO } from "../../../../../../actions/subject_actions.js";
import { showErrorGrowl, showSuccessGrowl } from "../../../../../../actions/msg_actions.js"; 
import { ConditionalDisplay } from "../../../../../util/shouldDisplay.js";
import Modal from "react-bootstrap/Modal"; 
import { SubjectForeignCitizenshipDetails } from "../subject_foreign_citizenship_details.js";
import { CommonForeignCitizenShip  } from "../../../../../reporting/subject/commonInfo/common_foreign_citizenship.js";
import { getDocDetail } from "../../../../../../actions/doc_actions.js";
import { CommonUtils } from "../../../../../../actions/common_utils.js";


class SubjectReportCitizenShipComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModel: false,
            foreignCitizenships:[]
        }

    }
    
    componentDidMount() {
        const { foreignCitizenships } = this.props;
        this.setState({ foreignCitizenships: foreignCitizenships })
    }

    async onSubmit(values) {
        const { subject } = this.props; 
        values.subjectId = subject.id;  
        values.type = "FOREIGN_CITIZENSHIP";   
        values.jsonClassType="ForeignCitizenshipReportable";  
        if (values.supportingDocs){
            for (let eachDoc of values.supportingDocs){
                eachDoc.docType = 'FOREIGN_CITIZEN_VERIFICATION';
            }
        }

        let response = await reportForSubjectBySO(values) 
        if (response) {
            this.props.showSuccessGrowl("Voting Reported");
            let response = await fetchPersonAsync(subject.id); 
            if (response != null && response.foreignCitizenships ) {
                this.setState({ foreignCitizenships : response.foreignCitizenships }) 
            } 
            this.setState({ showModel: false })
        } else {
            if (error.response.status === 404) {
                this.props.showErrorGrowl("Subject Not Found", "Please check the identity information.")
            }
        }
    }

    handleCancel = () => {
        this.setState({ showModel: false })
    }
    viewAdd = () => {
        this.setState({ showModel: true })
    } 
    render() { 
        const { showModel,foreignCitizenships } = this.state; 
        return (
            <Fragment>
                {ConditionalDisplay(
                    <div className="align-right">
                        <ButtonToolbar>
                            <Button variant="primary" className="mb-3" onClick={this.viewAdd}>Add Citizenship</Button>
                        </ButtonToolbar>
                    </div>
                )}
                <Modal size="lg" show={showModel} onHide={this.handleCancel} >
                    <Modal.Header closeButton>
                        <Modal.Title><h3>Foreign Citizenship Information</h3></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <CommonForeignCitizenShip onSubmit={this.onSubmit.bind(this)} onCancel={this.handleCancel} />
                    </Modal.Body>
                </Modal>
                <SubjectForeignCitizenshipDetails foreignCitizenships={foreignCitizenships}/> 
            </Fragment>
        )
    }
}

function mapStateToProps({ person }) {
    return {
        subject: person
    }
}
export const SubjectReportCitizenShip = connect(mapStateToProps, {showSuccessGrowl, showErrorGrowl})(SubjectReportCitizenShipComp);

 