/**
 * Created by shiva on 5/29/18.
 */

import React, {Fragment} from "react"
import {connect} from "react-redux"
import SubjectUploadHeader from "./subject_upload_header";
import {showSuccessGrowl, showErrorGrowl} from "../../../../actions/msg_actions";
import {fetchCurOrg} from "../../../../actions/org_actions"
import excel2PropMap from '../../../../mappingFiles/excel2PropertyMap.json'
import {
    DISS_ELIGIBILITY_DOC,
    HR_IMPORT_DOC,
    JPAS_ELIGIBILITY_DOC,
    EMAIL_DOC,
    uploadPersons, BADGE_AND_BACKGROUND_DOC, DONNA_MASTER_ROSTER_DOC
} from "../../../../actions/person_csvupload_action"
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link"
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import {Col, Container, Row} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import {reduxForm} from "redux-form";
import Form from "react-bootstrap/Form";
import {getOrgContracts} from "../../../../actions/contract_actions";
import {fetchSubjectsNotInBulkUpload} from "../../../../actions/subject_actions";
import {CUR_ORG} from "../../../../actions/session_constants";
import paginationFactory from "react-bootstrap-table2-paginator";
import {CommonUtils} from "../../../../actions/common_utils";

const uploadDataStr = 'Upload Data';
export const BULK_UPLOAD_ACCEPT_OPTION = 'Accept';
export const BULK_UPLOAD_IGNORE_OPTION = 'Ignore';
class SubjectUploadList extends React.Component {
    constructor(props) {
        super(props);
        this.renderHeader = this.renderHeader.bind(this);
        this.formatContractSelection = this.formatContractSelection.bind(this);

        this.state = {
            selected: [],
            initializeSelect: false,
            avail_contracts: [{id: "", name: "None"}],
            recvd_contracts: false,
            columnPreferences: {}
        }
    }

    componentDidMount() {
        getOrgContracts(sessionStorage.getItem(CUR_ORG)).then((response) => {
            const avail_contracts = response.data.map((con) => {
                return {
                    id: con.contract.id,
                    name: con.contract.name
                }
            });
            avail_contracts.unshift({
                id: "", name: "None"
            });

            this.setState({
                avail_contracts: avail_contracts,
                recvd_contracts: true
            });
        })
    }

    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/org/dash" onClick={() => fetchCurOrg()}>
    //                 Organization {this.props.cur_org.name}
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 Subjects
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    rearrangeDataBasedOnOverrideIfAny = (filteredSubjects) => {
        let {columnPreferences} = this.state;
        for (let fieldValArr of Object.entries(columnPreferences)) {
            let fieldName = fieldValArr[0];
            if (fieldValArr[1] !== BULK_UPLOAD_ACCEPT_OPTION){
                // this field is an override. Go thru the subjects and replace the value uploaded with the value from DB
                // Only if there is data from the DB. If not then make it null
                for (let eachSubject of filteredSubjects){
                    if (eachSubject.fromDB){
                        eachSubject[fieldName] = eachSubject.fromDB[fieldName];
                    } else {
                        eachSubject[fieldName] = null;
                    }
                }
            }
        }
    }

    // Clear out employee types to just string
    renameEnumTypesForDeserialization = (subjects) => {
        for (let subject of subjects){
            if (subject.subjectFromDB && subject.subjectFromDB.orgs){
                for (let orgRel of subject.subjectFromDB.orgs){
                    if (orgRel.employeeTypes){
                        let newEmployeeTypes = [];
                        for (let employeeType of orgRel.employeeTypes){
                            if (employeeType && employeeType.id) {
                                newEmployeeTypes.push(employeeType.id);
                            }
                        }
                        orgRel.employeeTypes = newEmployeeTypes;
                    }
                }
            }
            if (subject.subjectFromDB && subject.subjectFromDB.contracts){
                for (let contractRel of subject.subjectFromDB.contracts){
                    if (contractRel.reqDocTypes){
                        let idDocTypes = [];
                        for (let docType of contractRel.reqDocTypes){
                            if (docType && docType.id) {
                                idDocTypes.push(docType.id);
                            }
                        }
                        contractRel.reqDocTypes = idDocTypes;
                    }
                }
            }
        }
    }

    uploadData = () => {
        const {selected} = this.state;
        if (selected && selected.length !== 0) {
            let filteredSubjects = this.props.subjects.filter(x => selected.includes(x.socialSecurityNumber));
            for (let eachSubject of filteredSubjects){
                if (eachSubject.placeOfBirth){
                    eachSubject.placeOfBirth = CommonUtils.reformatPlaceOfBirthWithFullCountryName(eachSubject.placeOfBirth);
                }
            }
            this.rearrangeDataBasedOnOverrideIfAny(filteredSubjects);
            this.renameEnumTypesForDeserialization(filteredSubjects);
            this.props.uploadPersons(this.props.subjects, filteredSubjects, this.props.docType, this.props.match.params.facilityId).then((whereToGo) => {
                // Pull the active subjects not part of the upload
                // then after subject upload, show the list of subjects that may need to be terminated
                // After selection, terminate those subjects
                this.props.history.push(whereToGo);
                this.props.showSuccessGrowl("Subjects Uploaded Successfully", "The Subjects were updated successfully!")
            }).catch((message) => {
                this.props.showErrorGrowl("Error", message);
            });
        } else {
            this.props.showErrorGrowl("No Subjects Selected", "Select at least 1 Subject to upload.");
        }
    }

    formatContractSelection(cell, row){
        const {avail_contracts} = this.state;

        if (row.orgRelationshipIndicator === "S") {
            let selectOptions = [];
            for (let opt in avail_contracts) {
                selectOptions.push(<option key={opt}
                                           value={avail_contracts[opt].id}>{avail_contracts[opt].name}</option>)
            }

            const changeFunc = (val) => {
                row.contractIdForServicing = val.target.value;
            };

            let valueAssigned = cell;
            if (!valueAssigned){
                valueAssigned = '';
            }
            return (
                <Fragment>
                    <Form.Control name={`${row.socialSecurityNumber}-contractId`} as="select" value={valueAssigned} onChange={changeFunc}>
                        {selectOptions}
                    </Form.Control>
                </Fragment>
            )
        } else {
            return "N/A";
        }
    }

    getDataSourceOptionsForField = (fieldName) => {
        let {subjects} = this.props;
        if (subjects){
            let retOptions = [];
            // go for all the subjects and check the data source types of the field passed and add them
            for (let eachSubject of subjects) {
                if (eachSubject.fromDB && eachSubject.fromDB.fieldsAndTheirSources && eachSubject.fromDB.fieldsAndTheirSources[fieldName] &&
                    eachSubject.fromDB[fieldName] !== eachSubject[fieldName]){
                    if (!retOptions.includes(eachSubject.fromDB.fieldsAndTheirSources[fieldName])){
                        retOptions.push(eachSubject.fromDB.fieldsAndTheirSources[fieldName]);
                    }
                }
            }
            if (retOptions.length > 0){
                return retOptions;
            }
        }
    }

    headerFormatterCallback1 = (column, colIndex, {sortElement, filterElement}) => {

        let selectOptions = [];
        let columnOptions = [BULK_UPLOAD_ACCEPT_OPTION, BULK_UPLOAD_IGNORE_OPTION];
        for (let opt of columnOptions) {
            selectOptions.push(<option key={opt}
                                       value={opt}>{opt}</option>)
        }

        const changeFunc = (val) => {
            let {columnPreferences} = this.state;
            columnPreferences[column.dataField] = val.target.value;
            this.setState({columnPreferences: columnPreferences});
        };
        return (
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <div>
                    {column.text}
                    {/*{sortElement}*/}
                </div>
                <div>
                    <Form.Control name={`${column.text}`} as="select" onChange={changeFunc}>
                        {selectOptions}
                    </Form.Control>
                </div>
            </div>
        );
    }

    renderHeader() {
        let header = [];
        let {columnPreferences} = this.state;
        let titleFunction = (cell, row, rowIndex, colIndex) => {
            console.log('Inside title function');
            if (row.notSelectableReason){
                return row.notSelectableReason;
            } else {
                return null;
            }
        }
        if (this.props.docType === DISS_ELIGIBILITY_DOC) {
            header = SubjectUploadHeader({fieldMapObject: excel2PropMap.dissEligMap, headerFormatterCallback: this.headerFormatterCallback1, columnPreferences: columnPreferences, titleFunc: titleFunction});
            header.unshift(
                {
                    dataField: "contractIdForServicing",
                    text: "Servicing Contract",
                    formatter: this.formatContractSelection,
                    sort: false,
                    style: {'whiteSpace': 'nowrap'}
                }
            );
        } else if (this.props.docType === JPAS_ELIGIBILITY_DOC) {
            header = SubjectUploadHeader({fieldMapObject: excel2PropMap.jpasEligMap, headerFormatterCallback: this.headerFormatterCallback1, columnPreferences: columnPreferences, titleFunc: titleFunction});
            header.unshift(
                {
                    dataField: "contractIdForServicing",
                    text: "Servicing Contract",
                    formatter: this.formatContractSelection,
                    sort: false,
                    style: {'whiteSpace': 'nowrap'}
                }
            );
        } else if (this.props.docType === EMAIL_DOC) {
            header = SubjectUploadHeader({fieldMapObject: excel2PropMap.emailMap, headerFormatterCallback: this.headerFormatterCallback1, columnPreferences: columnPreferences, titleFunc: titleFunction});
        } else if (this.props.docType === BADGE_AND_BACKGROUND_DOC) {
            header = SubjectUploadHeader({fieldMapObject: excel2PropMap.badgeAndBGMap, headerFormatterCallback: this.headerFormatterCallback1, columnPreferences: columnPreferences, titleFunc: titleFunction});
        } else if (this.props.docType === DONNA_MASTER_ROSTER_DOC) {
            header = SubjectUploadHeader({fieldMapObject: excel2PropMap.donnaMasterRosterMap, headerFormatterCallback: this.headerFormatterCallback1, columnPreferences: columnPreferences, titleFunc: titleFunction});
        } else if (this.props.docType === HR_IMPORT_DOC) {
            header = SubjectUploadHeader({fieldMapObject: excel2PropMap.hrDataMap, headerFormatterCallback: this.headerFormatterCallback1, columnPreferences: columnPreferences, titleFunc: titleFunction});
        } else {
            return [{
                dataField: 'placeholder',
                text: 'Loading'
            }];
        }

        return header;
    }

    setInitialSelection = (subjects) => {
        const {docType} = this.props;
        if (!subjects || this.state.initializeSelect) {
            return;
        }
        let fieldMapObject = excel2PropMap.dissEligMap;
        if (docType === JPAS_ELIGIBILITY_DOC) {
            fieldMapObject = excel2PropMap.jpasEligMap;
        } else if (docType === HR_IMPORT_DOC) {
            fieldMapObject = excel2PropMap.hrDataMap;
        } else if (docType === EMAIL_DOC) {
            fieldMapObject = excel2PropMap.emailMap;
        }

        let initSelected = [];
        for (let subject of subjects) {
            if (subject.orgRelationshipIndicator !== 'S' || subject.contractIdForServicing) {
                if (!subject.fromDB) {
                    if (subject.socialSecurityNumber && subject.socialSecurityNumber !== '' && this.isValidSsn(subject.socialSecurityNumber)){
                        if (subject.notSelectableReason === null) {
                            initSelected.push(subject.socialSecurityNumber);
                        }
                    } else {
                        subject.notSelectableReason = "No valid SSN";
                    }
                } else { // check if any columns are different
                    let changes = false;
                    for (let fieldValArr of Object.entries(fieldMapObject)) {
                        let fieldName = fieldValArr[1];
                        if (!subject[fieldName] && !subject.fromDB[fieldName]) {
                            continue;
                        }
                        if ((subject[fieldName] == null && subject.fromDB[fieldName] != null) ||
                            (subject[fieldName] != null && subject.fromDB[fieldName] == null) ||
                            (subject[fieldName].toLowerCase() !== subject.fromDB[fieldName].toLowerCase())) {
                            if (subject[fieldName] === 'N/A' && subject.fromDB[fieldName] === null) {
                                continue;
                            }
                            changes = true;
                            break;
                        }
                    }
                    if (changes && subject.socialSecurityNumber && subject.socialSecurityNumber !== '' &&
                        this.isValidSsn(subject.socialSecurityNumber)){
                        if (subject.notSelectableReason === null) {
                            initSelected.push(subject.socialSecurityNumber);
                        }
                    } else {
                        subject.notSelectableReason = "No valid SSN";
                    }
                }
            }
        }

        this.setState({
            selected: initSelected,
            initializeSelect: true
        });
    }

    isValidSsn = (ssn) => {
        return this.isValidPseudoSsn(ssn) || this.isValidProperSsn(ssn);
    }

    isValidProperSsn = (ssn) => {
        let isSsnWithDashes = new RegExp(/^\d{3}-\d{2}-\d{4}$/).test(ssn);
        let isSsnWithoutDashes = new RegExp(/^\d{9}$/).test(ssn);
        return (isSsnWithDashes || isSsnWithoutDashes);
    }

    isValidPseudoSsn = (ssn) => {
        let isSsnWithDashes = new RegExp(/^[A-Za-z]\d{2}-\d{2}-\d{4}$/).test(ssn);
        let isSsnWithoutDashes = new RegExp(/^[A-Za-z]\d{8}$/).test(ssn);
        return (isSsnWithDashes || isSsnWithoutDashes);
    }

    getNonSelectable = (subjects) => {
        let retList = [];
        for (let subject of subjects){
            if (!this.isValidSsn(subject.socialSecurityNumber) || subject.notSelectableReason !== null){
                retList.push(subject.socialSecurityNumber);
            }
        }
        return retList;
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const {subjects} = nextProps;
        if (subjects) {
            this.setInitialSelection(subjects);
        }
    }

    render() {
        const {subjects} = this.props;
        if (!subjects || !this.state.recvd_contracts){
            return <div>Loading...</div>
        }
        // this.setInitialSelection();
        const selectRow = {
            mode: 'checkbox',
            clickToSelect: true,
            selected: this.state.selected,
            nonSelectable: this.getNonSelectable(subjects),
            onSelect: (row, isSelect, rowIndex, e) => {
                if (isSelect && (row.orgRelationshipIndicator !== 'S' || row.contractIdForServicing)) {
                    if (row.socialSecurityNumber && (row.socialSecurityNumber !== '') && this.isValidSsn(row.socialSecurityNumber)){
                        this.setState(() => ({
                            selected: [...this.state.selected, row.socialSecurityNumber]
                        }));
                    }
                } else {
                    this.setState(() => ({
                        selected: this.state.selected.filter(x => x !== row.socialSecurityNumber)
                    }));
                }
            },
            onSelectAll: (isSelect, rows) => {
                const ids = rows.map(r => r.socialSecurityNumber).filter(ssn => (ssn !== null && ssn !== '' && this.isValidSsn(ssn)));
                if (isSelect) {
                    this.setState(() => ({
                        selected: ids
                    }));
                } else {
                    this.setState(() => ({
                        selected: []
                    }));
                }
            }
        };
        const pagination = paginationFactory({
            sizePerPageList: [
                {text: '10', value: 10},
                {text: '20', value: 20},
                {text: '30', value: 30}
            ]
        });

        return (
            <div>
                {/* {this.getBreadCrumb()} */}
                <div className="pull-right">
                    <Container>
                        <Row>
                            <Col md={'auto'} style={{color: "black"}}>No Difference</Col>
                            <Col/>
                            <Col md={'auto'} style={{background: "blue", color: "white"}}>From JPAS</Col>
                            <Col/>
                            <Col md={'auto'} style={{background: "green", color: "white"}}>From DISS</Col>
                            <Col/>
                            <Col md={'auto'} style={{background: "cyan", color: "white"}}>From HR</Col>
                            <Col/>
                            <Col md={'auto'} style={{background: "orange", color: "white"}}>From Email</Col>
                            <Col/>
                            <Col md={'auto'} style={{background: "pink", color: "white"}}>From DT</Col>
                            <Col/>
                            <Col md={"auto"} style={{background: "yellow", color: "red", justifyContent: 'center'}}>From Upload</Col>
                            <Col md={"auto"}>
                                <Button variant="primary" onClick={this.uploadData}>{uploadDataStr}</Button>
                            </Col>
                        </Row>
                    </Container>
                    {/*<Link className="btn btn-primary" to={`/persons/new/org/${sessionStorage.getItem(CUR_ORG)}`}>Upload Data</Link>*/}
                </div>
                <h3>Uploaded Data</h3>
                <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="socialSecurityNumber"
                                data={subjects ? subjects : []} columns={this.renderHeader()} pagination={pagination}
                                selectRow={selectRow} wrapperClasses={"table-responsive"}/>
            </div>
        )
    }
}

function mapStateToProps({subjectAndFacilities}) {
    return {
        subjects: subjectAndFacilities.data,
        docType: subjectAndFacilities.documentType
    }
}

export default connect(mapStateToProps, {
    uploadPersons,
    fetchSubjectsNotInBulkUpload,
    showSuccessGrowl,
    showErrorGrowl
})(SubjectUploadList);

// export default reduxForm({
//     form: 'BulkUploadConfirmForm'
// })(connect(mapStateToProps, {, uploadPersons, showSuccessGrowl, showErrorGrowl})(SubjectUploadList));