import React, {Component, Fragment} from "react";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";

export class SubjectAlcoholDrugTreatmentDetails extends Component {
    getALcoholDrugIssues() {
        return this.props.alcoholDrugTreatmentReportables.map((alcoholDrugTreatment) => {
            return <tr key={alcoholDrugTreatment.id}>
                <td>{alcoholDrugTreatment.reason}</td>
                <td>{alcoholDrugTreatment.dates}</td>
                <td>{alcoholDrugTreatment.treatmentProviderName}</td>
                <td>{alcoholDrugTreatment.treatmentProvideContactInformation}</td>
            </tr>
        })
    }

    render() {
        const {alcoholDrugTreatmentReportables} = this.props;

        if (!alcoholDrugTreatmentReportables || alcoholDrugTreatmentReportables.length === 0) {
            return <Alert variant="danger">No Alcohol/Drug treatments</Alert>
        }

        return (
            <Fragment>
                <Table>
                    <thead>
                    <tr>
                        <th>Reason</th>
                        <th>Date(s) of Treatment</th>
                        <th>Provider Name</th>
                        <th>Provider Contact</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.getALcoholDrugIssues()}
                    </tbody>
                </Table>
            </Fragment>
        );
    }
}