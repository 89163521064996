import {Formik, Field} from "formik";
import React, {Component, Fragment, useEffect, useState} from "react";
import {FormikDatePickerComponent} from "../../../../components/formik/formik_date_picker";
import {FormikFieldDualListBox} from "../../../../components/formik/formik_field_duallistbox";
import DeleteConfirmationModal from "../../../../components/delete_confirm_modal";
import * as Yup from "yup";
import {Form, Card, Col, Button, ButtonToolbar, Modal} from "react-bootstrap";
import {CommonUtils} from "../../../../actions/common_utils";

export const NewTask_FirstStep = ({
                                      formData,
                                      FormSubmitStep1,
                                      onMassEmailChange,
                                      massEmailOptions,
                                      documentTypes,
                                      handleCancel,
                                  }) => {
    const OnSubmitClick = (values, actions) => {
        actions.setSubmitting(false);
        FormSubmitStep1(values);
    };

    return (
        <Formik
            initialValues={formData}
            enableReinitialize={true}
            onSubmit={OnSubmitClick}
            validationSchema={Yup.object().shape({
                name: Yup.string().required("Enter a name"),
                desc: Yup.string().required("Please enter a description"),
                dueDate: Yup.date().required("Enter a date"),
                requiredDocs: Yup.array()
                    .required("Select at least one document type")
                    .test(
                        "required-docs-test",
                        "Please select a document",
                        function (value) {
                            return value && value.length > 0;
                        }
                    ),
            })}
        >
            {({
                  isSubmitting,
                  values,
                  handleChange,
                  setFieldValue,
                  setFieldTouched,
                  handleSubmit,
                  touched,
                  resetForm,
                  errors,
                  handleBlur,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    {/* <h3>New Task</h3> */}

                    <Card>
                        <Card.Body>
                            <Form.Row>
                                <Form.Group as={Col} md="6" controlId={"name"}>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        name={"name"}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder={"Enter the name"}
                                        isInvalid={touched.name && !!errors.name}
                                        value={values.name}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.name}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId={"dueDate"}>
                                    <Form.Label>Due Date</Form.Label>
                                    <Field
                                        id="dueDate"
                                        name="dueDate"
                                        component={FormikDatePickerComponent}
                                        placeholder={"Due date"}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} md="12" controlId={"requiredDocs"}>
                                    <Form.Label>Required Documents</Form.Label>
                                    <Field
                                        id="requiredDocs"
                                        name="requiredDocs"
                                        component={FormikFieldDualListBox}
                                        options={documentTypes}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.requiredDocs}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} md="6" controlId={"desc"}>
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        name={"desc"}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder={"Enter Description"}
                                        isInvalid={touched.desc && !!errors.desc}
                                        value={values.desc}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.desc}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId={"massEmailIssue"}>
                                    <Form.Label>Is it bulk email notification?</Form.Label>
                                    <Form.Control
                                        required
                                        as="select"
                                        name={"massEmailIssue"}
                                        onChange={(value, e) => {
                                            onMassEmailChange(value);
                                            handleChange(value);
                                        }}
                                        onBlur={handleBlur}
                                        isInvalid={
                                            touched.massEmailIssue && !!errors.massEmailIssue
                                        }
                                        value={values.massEmailIssue}
                                    >
                                        {CommonUtils.getOptionsForSelect(massEmailOptions)}
                                    </Form.Control>
                                </Form.Group>
                            </Form.Row>
                        </Card.Body>
                        <Card.Footer>
                            <div className={"pull-right1"}>
                                <ButtonToolbar>
                                    <Button variant="primary" disabled={false} type="submit">
                                        Next
                                    </Button>
                                    {/* <Button variant="danger" onClick={this.props.onCancel}>Cancel</Button> */}
                                    <DeleteConfirmationModal
                                        buttonText={"Cancel"}
                                        actionText={"cancel"}
                                        deleteEntity="New Task Creation Form"
                                        handleDeleteCallback={handleCancel}
                                    />
                                </ButtonToolbar>
                            </div>
                        </Card.Footer>
                    </Card>


                </Form>
            )}
        </Formik>
    );
};
