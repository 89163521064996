import React, {Component, Fragment} from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {showErrorGrowl, showSuccessGrowl} from "../../../../../actions/msg_actions";
import {updateDocumentMetadata} from "../../../../../actions/doc_actions";
import {connect} from "react-redux";
import {Field, Formik, getIn} from "formik";
import * as Yup from "yup";
import {Col, Form} from "react-bootstrap";
import {FormikDatePickerComponent} from "../../../../../components/formik/formik_date_picker";
import PropTypes from 'prop-types';
import {createClearance, updateClearance} from "../../../../../actions/clearance_actions";
import {ConditionalDisplay} from "../../../../util/shouldDisplay";
import {CommonUtils} from "../../../../../actions/common_utils";
import {
    NON_SCI_ACCESS_OPTIONS,
    SCI_ACCESS_OPTIONS_ARR,
    SPECIAL_ACCESS_OPTIONS_ARR
} from "../details/subject_clearance_update";
import {getClearanceOrgs} from "../../../../../actions/fieldValue_actions";
import DeleteConfirmationModal from "../../../../../components/delete_confirm_modal";

class SubjectAddlClearanceNewModalComp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false
        }
    }

    componentDidMount() {
        this.getClearanceOrgs();
    }

    getClearanceOrgs = async () => {
        const data = await getClearanceOrgs();
        let agencyTypes = [];
        for (let eachType of data){
            agencyTypes.push(eachType.id);
        }
        this.setState({
            agencyTypes
        })
    }

    open = () => {
        this.setState({
            showModal: true,
        });
    }

    close = () => {
        this.setState({
            showModal: false,
        });
    }

    onSubmit = async (values) => {
        let {existingClearance, subjectId} = this.props;
        values.subjectId = subjectId;
        if (values.sciAccesses){
            values.sciAccesses = values.sciAccesses.join(',');
        }
        if (values.specialAccesses){
            values.specialAccesses = values.specialAccesses.join(',');
        }
        try {
            console.log('Submitted is ', values)
            if (existingClearance){
                await updateClearance(values);
            } else {
                await createClearance(values);
            }
            this.props.onFinish();
            this.close();
        } catch (error){
            console.log(error);
            if (existingClearance){
                this.props.showErrorGrowl(`Update Clearance", "Unexpected error updating clearance`);
            } else {
                this.props.showErrorGrowl(`Create Clearance", "Unexpected error creating clearance`);
            }
        }
    }

    initValues = () => {
        let {existingClearance} = this.props;
        let clearance = {
            investigationBeginDate: "",
            investigationType: "",
            investigationDate: "",
            eligibility: "",
            eligibilityDate: "",
            nonSciAccesses: "",
            sciAccesses: "",
            specialAccesses: "",
            ceStatus: "",
            ceDateEnrolled: "",
            determination: "",
            clearanceOrgType: "DOD",
            polygraph: {
                date: undefined,
                type: "",
                agency: ""
            }
        }
        if (existingClearance) {
            for (const [key, value] of Object.entries(existingClearance)){
                if (value){
                    clearance[key] = value;
                }
            }
            if (existingClearance.sciAccesses){
                clearance.sciAccesses = existingClearance.sciAccesses.split(',');
            }
            if (existingClearance.specialAccesses){
                clearance.specialAccesses = existingClearance.specialAccesses.split(',');
            }
            if (existingClearance.nonSciAccesses){
                clearance.nonSciAccesses = existingClearance.nonSciAccesses.split(',');
            }
            if (existingClearance.clearanceOrgType && clearance.clearanceOrgType.id){
                clearance.clearanceOrgType = existingClearance.clearanceOrgType.id;
            }
            if (!existingClearance.polygraph.date){
                existingClearance.polygraph.date = undefined;
            }
            console.log('Existing values are ', clearance)
        }
        return clearance;
    }

    render() {
        const {showModal, agencyTypes} = this.state;
        let {existingClearance} = this.props;
        let polyAgencyTypes = [''];
        if (!agencyTypes){
            return <div>Loading...</div>
        }
        polyAgencyTypes.push(...agencyTypes);
        return (
            <Fragment>
                {!existingClearance && ConditionalDisplay(
                    <Button onClick={this.open} variant="primary">Add Clearance</Button>
                )}
                {existingClearance && ConditionalDisplay(
                    <Button variant={'link'} className="close" onClick={this.open}>
                        <span aria-hidden="true" title={"Edit Clearance"} style={{padding: 10}} aria-label="Details">
                        <i className="fa fa-square-pen"></i>
                        </span>
                    </Button>
                )}

                <Modal show={showModal} size="lg">
                    <Modal.Header>
                        <Modal.Title>Add Clearance</Modal.Title>
                    </Modal.Header>
                    <Formik
                        initialValues={this.initValues()}
                        enableReinitialize={true}
                        onSubmit={this.onSubmit}
                        validationSchema={
                            Yup.object().shape({
                                polygraph: Yup.object().shape({
                                    date: Yup.date().test(
                                        'date-test',
                                        'Please select a date',
                                        function (value) {
                                            if (this.parent.type || this.parent.agency){
                                                return value;
                                            }
                                            return true;
                                        },
                                    ),
                                    type: Yup.string().test(
                                        'type-test',
                                        'Please select a type',
                                        function (value) {
                                            if (this.parent.date || this.parent.agency){
                                                return value;
                                            }
                                            return true;
                                        }
                                    ),
                                    agency: Yup.string().test(
                                        'type-test',
                                        'Please select an agency',
                                        function (value) {
                                            if (this.parent.date || this.parent.type){
                                                return value;
                                            }
                                            return true;
                                        }
                                    )
                                }),
                                clearanceOrgType: Yup.string().required('Granting organization name is required'),
                                investigationDate: Yup.date().required('Investigation date is required'),
                                investigationType: Yup.string().required('Investigation type is required'),
                                eligibilityDate: Yup.date().required('Eligibiity date is required'),
                                eligibility: Yup.string().required('Eligibility is required'),
                            })
                        }
                    >
                        {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur}) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Modal.Body>
                                    <Form.Row>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Eligibility</Form.Label>
                                            <Form.Control required type="text" name={'eligibility'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur} placeholder={'Eligibility'}
                                                          isInvalid={touched.eligibility && !!errors.eligibility}
                                                          value={values.eligibility}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.eligibility}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Eligibility Date</Form.Label>
                                            <Field
                                                id="eligibilityDate"
                                                name="eligibilityDate"
                                                component={FormikDatePickerComponent}
                                                placeholder={"Eligibility date"}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Non-SCI Access</Form.Label>
                                            <Form.Control required as="select" name={'nonSciAccesses'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          isInvalid={touched.nonSciAccesses && !!errors.nonSciAccesses}
                                                          value={values.nonSciAccesses}
                                            >
                                                {CommonUtils.getOptionsForSelect(CommonUtils.forDropDown(NON_SCI_ACCESS_OPTIONS))}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.nonSciAccesses}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>SCI Access</Form.Label>
                                            <Form.Control
                                                as="select"
                                                multiple
                                                name="sciAccesses"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.sciAccesses && !!errors.sciAccesses}
                                                value={values.sciAccesses}
                                            >
                                                {CommonUtils.getOptionsForSelect(CommonUtils.forDropDown(SCI_ACCESS_OPTIONS_ARR))}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.sciAccesses}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Special Access</Form.Label>
                                            <Form.Control required as="select" multiple name={'specialAccesses'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          isInvalid={touched.specialAccesses && !!errors.specialAccesses}
                                                          value={values.specialAccesses}
                                            >
                                                {CommonUtils.getOptionsForSelect(CommonUtils.forDropDown(SPECIAL_ACCESS_OPTIONS_ARR))}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.specialAccesses}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId={'clearanceOrgType'}>
                                            <Form.Label>Granting Org</Form.Label>
                                            <Form.Control required as="select" name={'clearanceOrgType'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          isInvalid={touched.clearanceOrgType && !!errors.clearanceOrgType}
                                                          value={values.clearanceOrgType}
                                            >
                                                {CommonUtils.getOptionsForSelect(CommonUtils.forDropDown(this.state.agencyTypes))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Investigation Type</Form.Label>
                                            <Form.Control required type="text" name={'investigationType'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur} placeholder={'Investigation Type'}
                                                          isInvalid={touched.investigationType && !!errors.investigationType}
                                                          value={values.investigationType}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.investigationType}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Inv. Begin Date</Form.Label>
                                            <Field
                                                id="investigationBeginDate"
                                                name="investigationBeginDate"
                                                component={FormikDatePickerComponent}
                                                placeholder={"Investigation Begin date"}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Investigation Date</Form.Label>
                                            <Field
                                                id="investigationDate"
                                                name="investigationDate"
                                                component={FormikDatePickerComponent}
                                                placeholder={"Investigation date"}
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>CE Date</Form.Label>
                                            <Field
                                                id="ceDateEnrolled"
                                                name="ceDateEnrolled"
                                                component={FormikDatePickerComponent}
                                                placeholder={"Continuous Evaluation Date (Enrolled)"}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>CE Status</Form.Label>
                                            <Form.Control required type="text" name={'ceStatus'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur} placeholder={'Continuous Evaluation Status'}
                                                          isInvalid={touched.ceStatus && !!errors.ceStatus}
                                                          value={values.ceStatus}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.ceStatus}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Determination</Form.Label>
                                            <Form.Control required type="text" name={'determination'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur} placeholder={'Determination'}
                                                          isInvalid={touched.determination && !!errors.determination}
                                                          value={values.determination}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.determination}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Polygraph Date</Form.Label>
                                            <Field
                                                id="polygraph.date"
                                                name="polygraph.date"
                                                component={FormikDatePickerComponent}
                                                placeholder={"Polygraph Date"}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {getIn(errors,'polygraph.date')}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Polygraph Type</Form.Label>
                                            <Form.Control required type="text" name={'polygraph.type'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur} placeholder={'Polygraph Type'}
                                                          isInvalid={getIn(touched,'polygraph.type') && !!getIn(errors,'polygraph.type')}
                                                          value={values.polygraph.type}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {getIn(errors,'polygraph.type')}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId={'polygraph.agency'}>
                                            <Form.Label>Polygraph Org</Form.Label>
                                            <Form.Control required as="select" name={'polygraph.agency'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          isInvalid={getIn(touched,'polygraph.agency') && !!getIn(errors,'polygraph.agency')}
                                                          value={values.polygraph.agency}
                                            >
                                                {CommonUtils.getOptionsForSelect(CommonUtils.forDropDown(polyAgencyTypes))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <ButtonToolbar>
                                        <Button variant="primary" type="submit" disabled={isSubmitting}>{existingClearance?"Update":"Create"}</Button>
                                        
                                        <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="Add Clearance"
                                                  handleDeleteCallback={this.close}/>
                                        
                                    </ButtonToolbar>
                                </Modal.Footer>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </Fragment>
        )
    }
}

export const SubjectAddlClearanceNewModal = connect(null, {
    showSuccessGrowl,
    showErrorGrowl,
    updateDocumentMetadata
})(SubjectAddlClearanceNewModalComp);

SubjectAddlClearanceNewModal.propTypes = {
    subjectId: PropTypes.string.isRequired,
    onFinish: PropTypes.func.isRequired,
    existingClearance: PropTypes.object
}