import React, {Component, Fragment} from 'react';
import {Card} from 'react-bootstrap';
import ForeignContactList from "../../../../reporting/subject/foreignTravel/foreign_contact_list";
import PropTypes from 'prop-types';
import {QuestionnaireQandA} from "../../../../../components/subject/questionnaire_travel_contact";
import Alert from "react-bootstrap/Alert";
import CollapsibleCard from "../../../../../components/bootstrap_addons/collapsible-card";
import filterFactory, {textFilter} from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import Button from "react-bootstrap/Button";
import {SubjectForeignContactQuestionnaireModal} from "../../../../../components/subject/subject_foreign_contact_questionnaire_modal";
import SubjectForeignTravelDetailsModal from "../../../../../components/subject/subject_foreign_travel_details_modal";

export class SubjectForeignContactListDetails extends Component {
    constructor(props) {
        super(props);

        // ID Maps
        this.contactIdToContactRepIdMap = new Map();    // Contact ID => Foreign Contact Reportable ID
        this.contactIdToTravelRepIdMap = new Map();     // Contact ID => Foreign Travel Reportable ID
        this.contactRepIdToObjMap = new Map();          // Foreign Contact Reportable ID => Object
        this.travelRepIdToObjMap = new Map();           // Foreign Travel Reportable ID => Object
    }

    processReportable = (reportable, contacts = [], repIdToObjMap, contactIdToRepMap, repType, i = 0) => {
        // Map Reportable ID to Reportable Object
        repIdToObjMap.set(reportable.id, reportable);

        let contactsList = [];
        // Loop through contacts in reportable
        if (contacts) {
            for (let contact of contacts) {
                // Generate ID if it doesn't have one
                if (!contact.id) {
                    contact.id = `${contact.firstName}${contact.lastName}${i}`;
                    i++;
                }

                // Set whether the contact belongs to a Foreign Contact Reportable or Foreign Travel Reportable
                contact.type = repType;

                // Map Contact ID to Reportable ID
                contactIdToRepMap.set(contact.id, reportable.id);

                contactsList.push(contact);
            }
        }

        return contactsList;
    }

    getForeignContacts() {
        // Reset maps
        this.contactIdToContactRepIdMap = new Map();
        this.contactIdToTravelRepIdMap = new Map();
        this.contactRepIdToObjMap = new Map();
        this.travelRepIdToObjMap = new Map();

        // Get Contact & Travel Reportables from Subject Details
        const {contactReportables, travelReportables} = this.props;
        console.log("Travel Reportables", contactReportables);

        // Generate a list of contacts.
        let contactsList = [];

        // Loop through each reportable (for... of is equivalent to foreach in ES6)
        if (contactReportables) {
            for (const reportable of contactReportables) {
                // Get contacts from reportable
                const repContacts = this.processReportable(
                    reportable,
                    reportable.contacts,
                    this.contactRepIdToObjMap,
                    this.contactIdToContactRepIdMap,
                    "FOREIGN_CONTACT_REPORTABLE",
                    contactsList.length);

                contactsList = contactsList.concat(repContacts);
            }
        }

        if (travelReportables) {
            for (const reportable of travelReportables) {
                // Get contacts from reportable
                const repContacts = this.processReportable(
                    reportable,
                    reportable.foreignContacts,
                    this.travelRepIdToObjMap,
                    this.contactIdToTravelRepIdMap,
                    "FOREIGN_TRAVEL_REPORTABLE",
                    contactsList.length);

                contactsList = contactsList.concat(repContacts);
            }
        }

        return contactsList;
    }

    render() {
        // Get contacts
        const contacts = this.getForeignContacts();

        // Check if list is empty or undefined
        if (contacts.length === 0) {
            return <Alert variant="danger">No Foreign Contacts!</Alert>;
        }

        const columns = [{
            dataField: 'firstName',
            text: 'First Name',
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'lastName',
            text: 'Last Name',
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'citizenCountry',
            text: 'Citizenship Country',
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'placeOfContact',
            text: 'Place of Contact',
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'startDate',
            text: 'Contact Initiated Date',
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'actions',
            text: 'Actions',
            formatter: (cell, row) => {
                if (row.type === "FOREIGN_CONTACT_REPORTABLE") {
                    return (
                        <SubjectForeignContactQuestionnaireModal
                            questionnaire={this.contactRepIdToObjMap.get(this.contactIdToContactRepIdMap.get(row.id))}
                            selectedContactId={row.id}
                        />
                    )
                } else {
                    return (
                        <SubjectForeignTravelDetailsModal
                            foreignTravelDetail={this.travelRepIdToObjMap.get(this.contactIdToTravelRepIdMap.get(row.id))}
                        />
                    )
                }
            }
        }];

        return (
            <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="id"
                            data={this.getForeignContacts()}
                            columns={columns}
                            filter={filterFactory()}/>
        )
    }
}

SubjectForeignContactListDetails.propTypes = {
    contactReportables: PropTypes.object.isRequired,
    travelReportables: PropTypes.object.isRequired
}


// TODO: Check if any of the following is required anymore.
export function ForeignContactWithQuestionnaire(props) {
    let {contactWithQuesObjList} = props;
    if (!contactWithQuesObjList || contactWithQuesObjList.length === 0) {
        return <Alert variant="danger">No Foreign Contacts!</Alert>
    }

    return (
        <Fragment>
            {getEachContactsWithQues(contactWithQuesObjList)}
        </Fragment>
    )
}

export function getForeignContactWithQuesCard(contactWithQues, defaultOpen) {
    return <CollapsibleCard header={'Foreign Contact Details'} defaultOpen={defaultOpen}>
        <Card.Body>
            <ForeignContactList updateParentStateWhenSubmitting={() => {
            }}
                                addedContacts={contactWithQues.contacts}
                                contactAdded={() => {
                                }}
                                contactRemoved={() => {
                                }} viewOnly={true}/>
        </Card.Body>
        <Card>
            <Card.Header>Questionnaire Responses</Card.Header>
            <QuestionnaireQandA questionnaireResp={contactWithQues}/>
        </Card>
    </CollapsibleCard>;
}

function getEachContactsWithQues(contactsWithQuesList) {

    const retList = contactsWithQuesList.map(contactWithQues => {
        return (
            getForeignContactWithQuesCard(contactWithQues, false)
        )
    });
    return retList;
}

ForeignContactWithQuestionnaire.propTypes = {
    contactWithQuesObjList: PropTypes.object.isRequired
}