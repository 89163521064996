import React, {Component, Fragment} from "react";
import {DateUtils} from "../../../../../actions/common_utils";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";

export class SubjectForeignVotingDetails extends Component {
    getForeignVotings() {
        return this.props.foreignVotings.map((voting) => {
            return <tr key={voting.id}>
                <td>{voting.electionDescription}</td>
                <td>{voting.country}</td>
                <td>{voting.date ? DateUtils.getDateString(voting.date) : "Unknown"}</td>
            </tr>
        })
    }

    render() {
        const {foreignVotings} = this.props;

        if (!foreignVotings || foreignVotings.length === 0) {
            return <Alert variant="danger">No Foreign Votings!</Alert>
        }

        return (
            <Fragment>
                <Table>
                    <thead>
                    <tr>
                        <th>Election Description</th>
                        <th>Country</th>
                        <th>Date of Voting</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.getForeignVotings()}
                    </tbody>
                </Table>
            </Fragment>
        );
    }
}