import React, {Component, Fragment} from "react";
import {DateUtils} from "../../../../../../actions/common_utils.js";
import Alert from "react-bootstrap/Alert";
import filterFactory, {textFilter} from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import SubjectForeignTravelDetailsModal from "../../../../../../components/subject/subject_foreign_travel_details_modal.js";
import { ConditionalDisplay } from "../../../../../../containers/util/shouldDisplay.js";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {CommonForeignTravel} from '../../../../../reporting/subject/commonInfo/common_foreign_travel.js'
import { showErrorGrowl, showSuccessGrowl } from "../../../../../../actions/msg_actions.js";
import {connect} from "react-redux";
import { fetchPersonAsync, reportForSubjectBySO } from "../../../../../../actions/subject_actions.js";
import {SubjectForeignTravelDetails} from '../../../subject/details/subject_foreign_travel_details.js'

export class SubjectReportForeignTravelComp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            foreignTravels: []
        }
    }

    handleViewAdd = () => {
        this.setState({showModal: true});
    }
    async onSubmit(values) {
        const { subject } = this.props;
        values.subjectId = subject.id;
        values.type = "FOREIGN_TRAVEL";
        values.jsonClassType = "ForeignTravelReportable";
        values.ReportableActivityType="FOREIGN_TRAVEL";
        if (values.foreignContacts) {
            var uploadedfiles = [];
            for (let contact of values.foreignContacts) {
                contact.type = "FOREIGN_CONTACT";
                contact.jsonClassType = "ForeignContact";
                contact.subjectId = subject.id;
                if (contact.files) {
                    for (let eachDoc of contact.files) {
                        eachDoc.docType = "FOREIGN_TRAVEL_POST_DEP";
                        uploadedfiles.push(eachDoc);
                    } 
                }
            }
            values.supportingDocs = uploadedfiles;
        }
        console.log("foreign travel data", values);
        let response = await reportForSubjectBySO(values)
        if (response) {
            this.props.showSuccessGrowl("Foreign Travel Reported Successfully.");
            let response = await fetchPersonAsync(subject.id);
            if(response != null && response.foreignTravels ) {
            this.setState({ foreignTravels: response.foreignTravels })
            }
            this.setState({ showModal: false })
        } else {
            if (error.response.status === 404) {
                this.props.showErrorGrowl("Subject Not Found", "Please check the identity information.")
            }
        }
    }

    close = () => {
        this.setState({
            showModal: false,
        });
    }
  
    componentDidMount() {
        const { foreignTravels } = this.props;
        this.setState({ foreignTravels: foreignTravels })
    }

    render() {
        const {showModal,foreignTravels} = this.state;

        return (
            <Fragment>
                 {ConditionalDisplay(
                    <div className="align-right">
                        <ButtonToolbar>
                            <Button variant="primary" className="mb-3" onClick={this.handleViewAdd}>Add Foreign Travel</Button>
                        </ButtonToolbar>
                    </div>
                )}
                <Modal size="lg" show={showModal} onHide={this.close} >
                    <Modal.Header closeButton>
                        <Modal.Title><h3>Foreign Travel Reporting</h3></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <CommonForeignTravel  onSubmit={this.onSubmit.bind(this)} onCancel={this.close} />
                    </Modal.Body>
                </Modal>
                <SubjectForeignTravelDetails foreignTravels={foreignTravels}/>
            </Fragment>
        );
    }
}
function mapStateToProps({ person }) {
    return {
        subject: person
    }
}

export const SubjectReportForeignTravel = connect(mapStateToProps, { showSuccessGrowl, showErrorGrowl })(SubjectReportForeignTravelComp);

