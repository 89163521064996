import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

class SubjectFingerprintDetails extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {subject} = this.props;

        console.log('Finger print detail is ', subject.fingerPrinting)
        return (
            <Fragment>
                {subject.fingerPrinting &&
                <Fragment>
                    <Row className={'mb-3'}>
                        <Col as={Form.Label} sm={3} md={2}>
                            Fingerprinting Required
                        </Col>
                        <Col sm={3} md={4}>
                            {subject.fingerPrinting.fingerPrintingRequired?'Yes':'No'}
                        </Col>
                        <Col as={Form.Label} sm={3} md={2}>
                            Fingerprinting Type
                        </Col>
                        <Col sm={3} md={4}>
                            {subject.fingerPrinting.fingerPrintingType}
                        </Col>
                    </Row>
                    <Row className={'mb-3'}>
                        <Col as={Form.Label} sm={3} md={2}>
                            Fingerprinting Status
                        </Col>
                        <Col sm={3} md={4}>
                            {subject.fingerPrinting.fingerPrintingStatus}
                        </Col>
                    </Row>
                </Fragment>
                }
            </Fragment>
        )
    }
}

SubjectFingerprintDetails.propTypes = {
    subject: PropTypes.object.isRequired
};

export default SubjectFingerprintDetails;