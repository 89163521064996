import React, {Component, Fragment} from "react";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";

export class SubjectForeignIntelligenceContactDetails extends Component {
    getForeignIntelligenceContacts() {
        return this.props.foreignIntelligenceEntities.map((intContact) => {
            return <tr key={intContact.id}>
                <td>{intContact.serviceInvolved}</td>
                <td>{intContact.namesContactedAndDates}</td>
                <td>{intContact.natureOfContact}</td>
                <td>
                    {intContact.futureContactsLikelihood ? 'Yes' : 'No'}
                </td>
            </tr>
        })
    }

    render() {
        const {foreignIntelligenceEntities} = this.props;

        if (!foreignIntelligenceEntities || foreignIntelligenceEntities.length === 0) {
            return <Alert variant="danger">No Foreign Intelligence Contacts!</Alert>
        }

        return (
            <Fragment>
                <Table>
                    <thead>
                    <tr>
                        <th>Service Involved</th>
                        <th>Contact Names/Dates</th>
                        <th>Nature of Contact</th>
                        <th>Is Future Contact Likely</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.getForeignIntelligenceContacts()}
                    </tbody>
                </Table>
            </Fragment>
        );
    }
}