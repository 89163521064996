import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import Alert from "react-bootstrap/Alert";
import filterFactory, {textFilter} from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import {DateUtils} from "../../../../../actions/common_utils";

export class SubjectDatasourceDetails extends Component {
    render() {
        const {subject} = this.props;

        if (!subject.personSourceData || subject.personSourceData.length === 0) {
            return <Alert variant="danger">No Data Source. Data entered manually</Alert>
        } else {
            const columns = [{
                dataField: 'personFirstName',
                text: 'First Name',
                sort: true,
                filter: textFilter()
            }, {
                dataField: 'personLastName',
                text: 'Last Name',
                sort: true,
                filter: textFilter()
            }, {
                dataField: 'personMiddleName',
                text: 'Middle Name',
                sort: true,
                filter: textFilter()
            }, {
                dataField: 'gender',
                text: 'Gender',
                sort: true,
                filter: textFilter()
            }, {
                dataField: 'maritalStatusCode',
                text: 'Marital Status',
                sort: true,
                filter: textFilter()
            }, {
                dataField: 'personFormattedBirthDate',
                text: 'Date of Birth',
                sort: true,
            }, {
                dataField: 'customLogicPlaceOfBirth',
                text: 'Place of Birth',
                sort: true,
                filter: textFilter()
            }, {
                dataField: 'dataSourceType',
                text: 'Data Source',
                sort: true,
                filter: textFilter()
            }, {
                dataField: 'facilityCageCode',
                text: 'Location Cage Code',
                sort: true,
                filter: textFilter()
            }, {
                dataField: 'facilityName',
                text: 'Location Name',
                sort: true,
                filter: textFilter()
            }, {
                dataField: 'ndaFormattedDate',
                text: 'NDA Date',
                sort: true,
            }, {
                dataField: 'eligibility',
                text: 'Eligibility',
                sort: true,
            }, {
                dataField: 'eligFormattedDate',
                text: 'Eligibility Date',
                sort: true,
            }, {
                dataField: 'dateUploaded',
                text: 'Uploaded Date',
                formatter: (cell, row) => DateUtils.getDateTimeString(cell),
                sort: true,
            }];
            return (
                <Fragment>
                    <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="id" data={subject.personSourceData}
                                    columns={columns} filter={filterFactory()}/>
                </Fragment>
            )

        }
    }
}

SubjectDatasourceDetails.propTypes = {
    subject: PropTypes.object.isRequired
};