import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {CUR_ORG} from "../../../../../actions/session_constants";
import Card from "react-bootstrap/Card";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import ListGroup from "react-bootstrap/ListGroup";
import {CommonUtils, DateUtils} from "../../../../../actions/common_utils";
import DeleteConfirmationModal from "../../../../../components/delete_confirm_modal";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import SubjectOrgEditModal from "../../../../../components/subject/subject_org_edit_modal";
import AddSubjectToOrgModal from "../../../../../components/subject/add_subject_to_org_modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RemoveSubjectFromOrgModal from "../../../../../components/subject/remove_subject_from_org_modal";
import {ORG_NAME} from "../../../../../actions/auth_actions";

class SubjectOrgDetails extends Component {
    getOrgRels() {
        let total2BReturned = 0;
        return this.props.subject.orgs
            .filter((rel) => rel.orgId === sessionStorage.getItem(CUR_ORG))
            .map((rel, index) => {
                let roles = null;
                if (rel.employeeTypes) {
                    roles = rel.employeeTypes.map((type) => {
                        if (type) {
                            return (
                                <ListGroupItem key={type.id}>{type.name} - {type.longName}</ListGroupItem>
                            )
                        }
                    });
                }
                if (rel.separationDate !== null){
                    total2BReturned += 1;
                }
                if (total2BReturned < 3 || rel.separationDate === null) {
                    return (
                        <Col key={index} xs={12} md={6} lg={4} className={"mb-3"}>
                            <Card className={"h-100"} border={rel.active ? "success" : "warning"}>
                                <Card.Header>
                                    {sessionStorage.getItem(ORG_NAME)}
                                </Card.Header>
                                <Card.Body>
                                    <Card.Title>{rel.joinDate ? DateUtils.getDateString(rel.joinDate) : "Unknown"} - {rel.separationDate ? DateUtils.getDateString(rel.separationDate) : "Present"}</Card.Title>
                                    <Card.Subtitle
                                        className="mb-2 text-muted">Title: {rel.title ? rel.title : "None"}</Card.Subtitle>
                                    <Card.Text>
                                        {CommonUtils.isValidNonEmptyString(rel.consultantCompany) && `Consulting Company: ${rel.consultantCompany}`}
                                    </Card.Text>
                                </Card.Body>
                                <ListGroup className="list-group-flush">
                                    {roles}
                                </ListGroup>
                            </Card>
                        </Col>
                    )
                }
            });
    }

    getButtons() {
        const buttons = [];
        if (!!this.props.subject.orgs.find((rel) => rel.orgId === sessionStorage.getItem(CUR_ORG) && rel.active)) {
            buttons.push(
                <SubjectOrgEditModal key={"edit"} subjectId={this.props.subject.id} onFinish={this.props.editFinish}/>,
                <RemoveSubjectFromOrgModal key={"delete"} subjectId={this.props.subject.id}
                                           onFinish={this.props.handleDelete} buttonText="Remove"/>
            )
        } else {
            buttons.push(
                <AddSubjectToOrgModal key={"add"} buttonText={"Add to Organization"} onFinish={this.props.handleAdd}
                                      subjectId={this.props.subject.id}/>
            )
        }
        return buttons;
    }

    render() {
        return (
            <Fragment>
                <Row className={"m-auto"}>
                    <ButtonToolbar className={"ml-auto mb-3"}>
                        {this.getButtons()}
                    </ButtonToolbar>
                </Row>
                <Row>
                    {this.getOrgRels()}
                </Row>
            </Fragment>
        )
    }
}

SubjectOrgDetails.propTypes = {
    subject: PropTypes.object.isRequired,
    handleDelete: PropTypes.func.isRequired,
    editFinish: PropTypes.func.isRequired,
    handleAdd: PropTypes.func.isRequired
};

export default SubjectOrgDetails;