import React, {Component} from "react";
import {Field, reduxForm} from "redux-form";
import {
    FieldMultiSelectDropdown,
    FieldRBSelect,
} from "../../../../../components/redux_form/field_components_rb";
import {connect} from "react-redux";
import {fetchPerson} from "../../../../../actions/subject_actions";
import PropTypes from "prop-types";
import {showSuccessGrowl} from "../../../../../actions/msg_actions";
import Form from "react-bootstrap/Form";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import {ClearanceMapping} from "../../../../../actions/common_utils";

class SubjectFingerprintingUpdate extends Component {
    constructor(props) {
        super(props);

        this.cancelUpdate = this.cancelUpdate.bind(this);
    }

    componentDidMount() {
        this.didFormInit = false;
        this.props.fetchPerson(this.props.subjectId);

    }

    componentDidUpdate() {
        const {subject} = this.props;
        if (subject && this.didFormInit === false){
            this.props.initialize(subject);
            this.didFormInit = true;
        }
    }

    onSubmit = (values) => {
        if (values.issues){ // No need to pass this now as there are some serialization issues and we are never going to set issues from frontend
            values.issues = null;
        }
        // ToDo UI REFACTOR
        this.props.updateSubjectClearanceDetails(this.props.subjectId, values, () => {
            this.props.showSuccessGrowl("Subject Updated", "Subject clearance details updated successfully!");
            this.props.onFinish();
        });
    }

    cancelUpdate() {
        this.props.reset();
        this.didFormInit = false;
        this.props.onFinish();
    }

    render() {
        const {handleSubmit, subject} = this.props;
        const {badgingLevels} = this.state;
        if (!subject || !subject.id) {
            return <div>Loading...</div>;
        }

        return (
            <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                <h3>Update Fingerprinting Information</h3>
                <Field name="fingerPrintingStatus" component={FieldRBSelect} horizontal label="Fingerprinting Status"
                       options={this.nonSciAccessValues()}/>
                <Field name="fingerPrintingType" component={FieldMultiSelectDropdown} horizontal label="Fingerprinting Type"
                       options={this.sciAccessOptions()}
                       selectedValues={this.sciAccessSelectedOptions()}
                       onSelect={this.onSciSelect}
                       onRemove={this.onSciRemove}
                       displayValue='name'
                />
                <Field name="fingerPrintingRequired" component={FieldMultiSelectDropdown} horizontal label="Special Access"
                       options={this.specialAccessOptions()}
                       selectedValues={this.specialAccessSelectedOptions()}
                       onSelect={this.onSpecialSelect}
                       onRemove={this.onSpecialRemove}
                       displayValue='name'
                />

                <ButtonToolbar>
                    <Button variant="primary" type="submit">Save</Button>
                    <Button type="button" onClick={this.cancelUpdate}>Cancel</Button>
                </ButtonToolbar>
            </Form>
        )
    }
}

function validate(values) {
    const errors = {
    };
    return errors;
}

function mapStateToProps({person}) {
    return {
        subject: person
    }
}

SubjectFingerprintingUpdate.propTypes = {
    subjectId: PropTypes.string.isRequired,
    onFinish: PropTypes.func.isRequired
};

export default reduxForm({
    validate, // same as validate:validate
    form: 'SubjectFingerprintingUpdateForm',
    initialValues: {
    }
})(
    connect(mapStateToProps, {fetchPerson, showSuccessGrowl})(SubjectFingerprintingUpdate)
);