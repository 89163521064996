import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import Alert from "react-bootstrap/Alert";
import {CommonUtils, DateUtils} from "../../../../../actions/common_utils";
import Table from "react-bootstrap/Table";

export default class SubjectAddressDetails extends Component {
    constructor(props) {
        super(props);
    }

    getAddressHistory() {
        return this.props.subject.pastResidences.map((address) => {
            return <tr key={address.id}>
                <td>{CommonUtils.appendValuesIfNotEmpty([address.address1, address.address2, address.city, address.stateOrProvince, address.country])} {address.postalCode}</td>
                <td>{this.getStartDate(address)}</td>
                <td>{address.dateInvalidated ? DateUtils.getDateString(address.dateInvalidated) : "Present"}</td>
            </tr>
        })
    }

    getStartDate = (residence) => {
        if (residence.startDate != null){
            return DateUtils.getDateString(residence.startDate);
        } else if (residence.dateReported != null){
            return DateUtils.getDateString(residence.dateReported);
        } else {
            return "Unknown";
        }
    }

    render() {
        const {subject} = this.props;

        if (!subject.currentResidence) {
            return <Alert variant="danger">No Current Address!</Alert>
        }

        return (
            <Fragment>
                <h4>Current Residence
                    (From {this.getStartDate(subject.currentResidence) })</h4>
                <address>
                    {subject.currentResidence.address1}<br/>
                    {subject.currentResidence.address2}<br/>
                    {CommonUtils.appendValuesIfNotEmpty([subject.currentResidence.city, subject.currentResidence.stateOrProvince, subject.currentResidence.country])} {subject.currentResidence.postalCode}
                </address>

                <h4>Past Residences</h4>
                <Table>
                    <thead>
                    <tr>
                        <th>Address</th>
                        <th>From Date</th>
                        <th>To Date</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.getAddressHistory()}
                    </tbody>
                </Table>
            </Fragment>
        )
    }

    // render() {
    //     const {subject} = this.props;
    //
    //     if (!subject.addressLine1) {
    //         return <Alert variant="danger">No Current Address!</Alert>
    //     }
    //
    //     return (
    //         <Fragment>
    //             <h4>Current Residence</h4>
    //             <address>
    //                 {subject.addressLine1}<br/>
    //                 {subject.addressLine2}<br/>
    //                 {subject.addressState}, {subject.addressZipcode}<br/>
    //                 {subject.addressCountry}
    //             </address>
    //         </Fragment>
    //     )
    // }
}

SubjectAddressDetails.propTypes = {
    subject: PropTypes.object.isRequired
};