import {Formik, Field} from "formik";
import React, {useEffect, useState} from "react";
import DeleteConfirmationModal from "../../../../components/delete_confirm_modal";
import * as Yup from "yup";
import {Form, Card, Col, Button, ButtonToolbar, Modal} from "react-bootstrap";
import {CommonUtils} from "../../../../actions/common_utils";
import {FormikFieldDropzone} from "../../../../components/formik/formik_field_dropzone";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {FormikFieldDualListBox} from "../../../../components/formik/formik_field_duallistbox";

export function NewTask_ThirdStep({
                                      formData,
                                      FormSubmit,
                                      onEntityTypeChange,
                                      selectableEntityTypes,
                                      secondSigOptions,
                                      availEntities,
                                      firstSelected,
                                      availSecondEntities,
                                      templateSelectedRoles,
                                      previousPage,
                                      handleCancel,
                                      availSubjects,
                                  }) {
    const [FromValues, setFormValues] = useState(formData);
    useEffect(() => {
        setFormValues(formData);
    }, [formData]);

    const OnSubmitClick = (values, actions) => {
        FormSubmit(values);
        actions.onSubmit(true);
    };
    return (
        <Formik
            initialValues={FromValues}
            enableReinitialize={true}
            onSubmit={OnSubmitClick}
            validationSchema={Yup.object().shape({
                name: Yup.string().required("Enter a name"),
                desc: Yup.string().required("Please enter a description"),
                dueDate: Yup.date().required("Enter a date"),
                requiredDocs: Yup.array()
                    .required("Select at least one document type")
                    .test(
                        "required-docs-test",
                        "Please select a document",
                        function (value) {
                            return value && value.length > 0;
                        }
                    ),
                entityType: Yup.string()
                    .required("Entity type is required")
                    .test(
                        "entity-type-test",
                        "Please select an entity type",
                        function (value) {
                            return value && value !== "";
                        }
                    ),
                entityId: Yup.string()
                    .required("Entity is required")
                    .test("entity-test", "Please select an entity", function (value) {
                        return value && value !== "";
                    }),
                secondEntityId: Yup.string().test(
                    "second-entity-id-test",
                    "Please select an approver",
                    function (value) {
                        if (
                            (this.parent.templateId === "" ||
                                !this.parent.templateId ||
                                templateSelectedRoles.length < 2) &&
                            (!this.parent.workflowRequired ||
                                this.parent.workflowRequired === false ||
                                this.parent.workflowRequired.toUpperCase() !== "YES")
                        ) {
                            return true;
                        }
                        console.log("value for secondEntityId is ", value);
                        return value && value !== "";
                    }
                ),
                selectedSubjectIds: Yup.array().test(
                    "subject-selection-test",
                    "Please select at lease one subject",
                    function (value) {
                        if (this.parent.entityType === "SUBJECT") {
                            return true;
                        }
                        return value && value.length > 0;
                    }
                ),
            })}
        >
            {({
                  isSubmitting,
                  values,
                  handleChange,
                  setFieldValue,
                  setFieldTouched,
                  handleSubmit,
                  touched,
                  resetForm,
                  errors,
                  handleBlur,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Card>
                        <Card.Body>
                            <Form.Row>
                                <Form.Group as={Col} md="6" controlId={"entityType"}>
                                    <Form.Label>Applies To</Form.Label>
                                    <Form.Control
                                        required
                                        as="select"
                                        name={"entityType"}
                                        onChange={(value, e) => {
                                            onEntityTypeChange(value, setFieldValue);
                                            handleChange(value);
                                        }}
                                        onBlur={handleBlur}
                                        isInvalid={!!errors.entityType || !values.entityType}
                                        value={values.entityType}
                                    >
                                        {CommonUtils.getOptionsForSelect(selectableEntityTypes)}
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.entityType}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId={"entityId"}>
                                    <Form.Label>
                                        {!values.templateId ? "Assignee" : "Signer"}{" "}
                                        {values.entityType === "ORG"
                                            ? "(Everyone in the Organization)"
                                            : ""}
                                    </Form.Label>
                                    <Form.Control
                                        required
                                        as="select"
                                        name={"entityId"}
                                        disabled={values.entityType === "ORG"}
                                        onChange={(value, e) => {
                                            console.log("Setting second signature options");
                                            secondSigOptions(
                                                value,
                                                values.templateId,
                                                values.entityType
                                            );
                                            handleChange(value);
                                        }}
                                        onBlur={handleBlur}
                                        value={values.entityId}
                                        isInvalid={
                                            !!errors.entityId ||
                                            (values.entityType !== "ORG" &&
                                                (!values.entityId || !values.entityId))
                                        }
                                    >
                                        {CommonUtils.getOptionsForSelect(availEntities)}
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.entityId}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                {(templateSelectedRoles.length === 2 ||
                                    (values.workflowRequired &&
                                        (values.workflowRequired === true ||
                                            values.workflowRequired.toUpperCase() === "YES"))) && (
                                    <Form.Group as={Col} md="6" controlId={"secondEntityId"}>
                                        <Form.Label>Approver</Form.Label>
                                        <Form.Control
                                            required
                                            as="select"
                                            name={"secondEntityId"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.secondEntityId}
                                            isInvalid={
                                                !!errors.secondEntityId ||
                                                !values.secondEntityId ||
                                                !values.secondEntityId
                                            }
                                        >
                                            {CommonUtils.getOptionsForSelect(availSecondEntities)}
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.secondEntityId}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            </Form.Row>
                            {availSubjects && availSubjects.length > 0 && (
                                <Form.Row>
                                    <Form.Group as={Col} md="12">
                                        <Form.Label>Select Subjects</Form.Label>
                                        <Field
                                            id="selectedSubjectIds"
                                            name="selectedSubjectIds"
                                            component={FormikFieldDualListBox}
                                            options={availSubjects}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.selectedSubjectIds}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                            )}
                            <Form.Row>
                                <Form.Group
                                    as={Col}
                                    md="12"
                                    controlId={"userInstructionDocs"}
                                >
                                    <Form.Label>User Instruction Documents</Form.Label>
                                    <Field
                                        name="userInstructionDocs"
                                        component={FormikFieldDropzone}
                                        multiple={true}
                                    />
                                </Form.Group>
                            </Form.Row>
                        </Card.Body>
                        <Card.Footer>
                            <div className={"pull-right1"}>
                                <ButtonToolbar>
                                    <Button variant="primary" onClick={() => previousPage(values)}>
                                        Previous
                                    </Button>
                                    <Button variant="primary" type="submit">
                                        Submit
                                    </Button>
                                    <DeleteConfirmationModal
                                        buttonText={"Cancel"}
                                        actionText={"cancel"}
                                        deleteEntity="New Task Creation Form"
                                        handleDeleteCallback={handleCancel}
                                    />
                                </ButtonToolbar>
                            </div>
                        </Card.Footer>
                    </Card>
                </Form>
            )}
        </Formik>
    );
}

export const NewTask_StepThird = connect(null, {})(NewTask_ThirdStep);

NewTask_StepThird.propTypes = {
    formData: PropTypes.object.isRequired,
    FormSubmit: PropTypes.func.isRequired,
    onEntityTypeChange: PropTypes.func.isRequired,
    selectableEntityTypes: PropTypes.object.isRequired,
    secondSigOptions: PropTypes.object.isRequired,
    availEntities: PropTypes.object.isRequired,
    firstSelected: PropTypes.object.isRequired,
    availSecondEntities: PropTypes.object.isRequired,
    templateSelectedRoles: PropTypes.object.isRequired,
    previousPage: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
};
