import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import { fetchPersonAsync, reportForSubjectBySO } from "../../../../../../actions/subject_actions.js";
import { showErrorGrowl, showSuccessGrowl } from "../../../../../../actions/msg_actions.js";
import { ConditionalDisplay } from "../../../../../util/shouldDisplay.js";
import Modal from "react-bootstrap/Modal";
import { SubjectForeignCitizenshipDetails } from "../subject_foreign_citizenship_details.js";
import { CommonForeignCitizenShip } from "../../../../../reporting/subject/commonInfo/common_foreign_citizenship.js";
import { getDocDetail } from "../../../../../../actions/doc_actions.js";
import { CommonUtils } from "../../../../../../actions/common_utils.js";
import { CommonForeignContacts } from "../../../../../reporting/subject/commonInfo/common_foreign_contact.js";
import { forEach } from "lodash";
import { SubjectForeignContactListDetails } from "../subject_foreign_contact_ques_details.js";


class SubjectReportForeginContactsComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModel: false,
            contactReportables: [],
            travelReportables: []
        }

    }

    componentDidMount() {
        const { contactReportables,travelReportables } = this.props;
        this.setState({
            contactReportables: contactReportables,
            travelReportables: travelReportables
        })
    }

    async onSubmit(values) {
        const { subject } = this.props;
        values.subjectId = subject.id;
        values.type = "FOREIGN_CONTACTS_QUESTIONNAIRE";
        values.jsonClassType = "ForeignContactsQuestionnaireReportable";
        values.ReportableActivityType="FOREIGN_CONTACTS_QUESTIONNAIRE";
        if (values.contacts) {
            var uploadedfiles = [];
            for (let contact of values.contacts) {
                contact.type = "FOREIGN_CONTACT";
                contact.jsonClassType = "ForeignContact";
                contact.subjectId = subject.id;
                if (contact.files) {
                    for (let eachDoc of contact.files) {
                        eachDoc.docType = "FOREIGN_CONTACT_DETAILS_DOC";
                        uploadedfiles.push(eachDoc);
                    } 
                }
            }
            values.supportingDocs = uploadedfiles;
        }
        console.log("foreign contact data", values);
        let response = await reportForSubjectBySO(values)
        if (response) {
            this.props.showSuccessGrowl("Foreign Contact Reported");
            let response = await fetchPersonAsync(subject.id);
            if (response != null && response.foreignContactsQuestionnaireReportables) {
                this.setState({
                    contactReportables: response.foreignContactsQuestionnaireReportables,
                    travelReportables: response.foreignTravels
                })
            }
            this.setState({ showModel: false })
        } else {
            if (error.response.status === 404) {
                this.props.showErrorGrowl("Subject Not Found", "Please check the identity information.")
            }
        }
    }

    handleCancel = () => {
        this.setState({ showModel: false })
    }
    viewAdd = () => {
        this.setState({ showModel: true })
    }
    render() {
        const { showModel, contactReportables, travelReportables } = this.state;
        return (
            <Fragment>
                {ConditionalDisplay(
                    <div className="align-right">
                        <ButtonToolbar>
                            <Button variant="primary" className="mb-3" onClick={this.viewAdd}>Add Foreign Contact</Button>
                        </ButtonToolbar>
                    </div>
                )}
                <Modal size="lg" show={showModel} onHide={this.handleCancel} >
                    <Modal.Header closeButton>
                        <Modal.Title><h3>Foreign Contact Reporting</h3></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <CommonForeignContacts onSubmit={this.onSubmit.bind(this)} onCancel={this.handleCancel} />
                    </Modal.Body>
                </Modal>
                <SubjectForeignContactListDetails
                    contactReportables={contactReportables}
                    travelReportables={travelReportables} />
            </Fragment>
        )
    }
}

function mapStateToProps({ person }) {
    return {
        subject: person
    }
}
export const SubjectReportForeginContacts = connect(mapStateToProps, { showSuccessGrowl, showErrorGrowl })(SubjectReportForeginContactsComp);

