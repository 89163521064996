import React from "react";
import SubjectUploadData from "./subject_upload_data";
import excel2PropMap from '../../../../mappingFiles/excel2PropertyMap.json';
import {BULK_UPLOAD_IGNORE_OPTION} from "./subject_upload_list";

const SubjectUploadHeader = ({fieldMapObject, headerFormatterCallback, columnPreferences, titleFunc, noColor}) => {
    return Object.entries(fieldMapObject).filter((fieldValArr)=>!excel2PropMap.doNotDisplayList.includes(fieldValArr[0])).map((fieldValArr) => {
        let returnVal = {
            dataField: fieldValArr[1],
            text: fieldValArr[0].toUpperCase(),
            formatter: (cell, row) => SubjectUploadData(cell, row, fieldValArr[1], noColor),
            sort: false,
            style: {'whiteSpace': 'nowrap'},
            headerFormatter: headerFormatterCallback,
            title: titleFunc,
            headerStyle: (column, colIndex) => {
                let style = {'whiteSpace': 'nowrap'};// Enables the columns to not wrap
                if (columnPreferences[column.dataField] === BULK_UPLOAD_IGNORE_OPTION){
                    // dull the column
                    style = {'whiteSpace': 'wrap', 'backgroundColor': 'grey'}
                }
                return style;
            }
        };
        // if (titleFunc){
        //     returnVal.title = titleFunc;
        // }
        return returnVal;
    });
};


export default SubjectUploadHeader;