import React, {Fragment} from "react";
import {Container, Row, Col} from "react-bootstrap";
import {CommonUtils} from "../../../../actions/common_utils";

const SubjectUploadData = (cell, person, dataField, noColor) => {
    if (dataField === 'placeOfBirth'){
        cell = CommonUtils.reformatPlaceOfBirthWithFullCountryName(cell);
    }
    let styleObj = getStyle(person, dataField, noColor)
    // if there are data differences then highlight the whole row and display what we are uploading and what we
    return (
        <Container>
            <Row style={styleObj}>
                <Col>{cell}</Col>
            </Row>
            {getDBData(person, dataField, styleObj)}
        </Container>
    );
};

const getStyle = (person, fieldName, noColor) => {
    let isDifferent = false;
    if (!person.fromDB){
        isDifferent = true;
    } else {
        if (person[fieldName] !== person.fromDB[fieldName]){
            if (person[fieldName] !== 'N/A' || person.fromDB[fieldName] !== null){
                if (person[fieldName] && person.fromDB[fieldName]) {
                    isDifferent = person[fieldName].toLowerCase() !== person.fromDB[fieldName].toLowerCase();
                } else {
                    isDifferent = true;
                }
            }
        }
    }
    if (isDifferent && !noColor){
        return {color: "red", background: "yellow"};
    } else {
        return {color: "black"};
    }
}

const getDBData = (person, fieldName, styleOfImport, noColor) => {
    if (person.fromDB && styleOfImport.color === 'red'){
        let styleObj = {color: "white", background: "green"};
        if (person.fromDB.fieldsAndTheirSources[fieldName]){
            if (person.fromDB.fieldsAndTheirSources[fieldName] === 'JPAS'){
                styleObj = {color: "white", background: "blue"};
            } else if (person.fromDB.fieldsAndTheirSources[fieldName] === 'HR'){
                styleObj = {color: "white", background: "cyan"};
            } else if (person.fromDB.fieldsAndTheirSources[fieldName] === 'EMAIL'){
                styleObj = {color: "white", background: "orange"};
            } else if (person.fromDB.fieldsAndTheirSources[fieldName] === 'DISS') {
                styleObj = {color: "white", background: "green"};
            } else if (person.fromDB.fieldsAndTheirSources[fieldName] === 'BADGE_AND_BACKGROUND' ||
                person.fromDB.fieldsAndTheirSources[fieldName] === 'DONNA_MASTER_ROSTER') {
                styleObj = {color: "white", background: "pink"};
            } else {
                styleObj = {color: "black", background: "yellow"};
            }
            return (
                <Row style={styleObj}>
                    <Col>{person.fromDB[fieldName]}</Col>
                </Row>
            )
        } else {
            return (
                <Row style={styleObj}>
                    <Col>{person.fromDB[fieldName]}</Col>
                </Row>
            )
        }
    } else {
        return (
            <Fragment></Fragment>
        )
    }
}

export default SubjectUploadData;