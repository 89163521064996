/**
 * Created by pshivaraman on 4/10/18.
 */

import React, {Component, Fragment} from "react";
import {CUR_ORG} from "../../../../actions/session_constants";
import DocumentUploadDialogForCSV from "../../../../components/csv_upload";
import {connect} from "react-redux";
import {fetchCurOrg} from "../../../../actions/org_actions";
import {extractCSVData} from "../../../../actions/person_csvupload_action"
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link";
import SubjectSearchModal from "../../../../components/subject/subject_search_modal";
import {getAllSubjectsForAnOrg, getSubjectsForOrgWithFilter} from "../../../../actions/subject_actions";
import {Link} from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {selectFilter, textFilter} from "react-bootstrap-table2-filter";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {ConditionalDisplay, isHROnlyRole} from "../../../util/shouldDisplay";
import {BulkDocsNew} from "../../../../components/document/bulk_docs_new";
import paginationFactory from "react-bootstrap-table2-paginator";
import {TableExport} from "../../../../components/table_export";
import { Subject_Filter } from "./subject_filter";

class SubjectList extends Component {
    constructor(props) {
        super(props);
        this.extractCSV = this.extractCSV.bind(this);
        this.onSelectSubject = this.onSelectSubject.bind(this);
        this.state = {
            previousLoadedSubjectsActive: true,
            subjects: [],
            totalSize: 0,
            page: 1,
            sizePerPage: 10,
            loading: true,
            filter: {
                personFirstName:"",
                personLastName:"",
                socialSecurityNumber:"",
                stateOfBirth:"",
                countryOfBirth:"",
                affiliations:"",
                contractAssociation:"",
                activeStatus:"",
            },
            Sort:{sortField:"personFirstName",sortOrder:"asc"}
        }
    }

    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/org/dash" onClick={() => fetchCurOrg()}>
    //                 Organization {this.props.cur_org.name}
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 Subjects
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    extractCSV(doc, docType, selectedFacilityId, fillPseudoSsn) {
        this.props.extractCSVData(doc, docType, fillPseudoSsn, () => {
            this.props.history.push("/org/dash/subjectUploadToOrg/" + selectedFacilityId);
        });
    }

    onSelectSubject(subject) {
        //this.props.getAllSubjectsForAnOrg(sessionStorage.getItem(CUR_ORG));
    }

    componentDidMount() {
    }

    getColumns = () => {
        const activeOptions = [
            {value: "Active", label: "Active"},
            {value: "Separated", label: "Separated"}
        ];
        const yesNoOptions = [
            {value: "Yes", label: "Yes"},
            {value: "No", label: "No"}
        ];

        const headerFormatter = (column, colIndex, {sortElement, filterElement}) => {
            return (
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div>
                        {column.text}
                        {sortElement}
                    </div>
                    {filterElement}
                </div>
            );
        };

        return [{
            dataField: 'personFirstName',
            text: 'First Name',
            formatter: (cell, row) => <Link to={`/org/dash/subject/${row.id}`}>{cell}</Link>,
            sort: true,
            // filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'personLastName',
            text: 'Last Name',
            formatter: (cell, row) => <Link to={`/org/dash/subject/${row.id}`}>{cell}</Link>,
            sort: true,
            // filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'socialSecurityNumber',
            text: 'SSN',
            formatter: (cell, row) => <Link to={`/org/dash/subject/${row.id}`}>{cell}</Link>,
            sort: true,
            // filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'stateOfBirth',
            text: 'State of Birth',
            formatter: (cell, row) => <Link to={`/org/dash/subject/${row.id}`}>{cell}</Link>,
            sort: true,
            // filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'countryOfBirth',
            text: 'Country of Birth',
            formatter: (cell, row) => <Link to={`/org/dash/subject/${row.id}`}>{cell}</Link>,
            sort: true,
            // filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'affiliations',
            text: 'Data Source(s)',
            formatter: (cell, row) => <Link to={`/org/dash/subject/${row.id}`}>{cell}</Link>,
            sort: true,
            // filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'contractAssociation',
            text: 'Contract Association',
            formatter: (cell, row) => <Link to={`/org/dash/subject/${row.id}`}>{cell}</Link>,
            sort: false,
            // filter: selectFilter({
            //     options: yesNoOptions,
            // }),
            headerFormatter: headerFormatter
        }, {
            dataField: 'activeStatus',
            text: 'Status',
            formatter: (cell, row) => <Link to={`/org/dash/subject/${row.id}`}>{cell}</Link>,
            sort: true,
            // filter: selectFilter({
            //     options: activeOptions,
            //     // defaultValue: 'Active'
            // }),
            headerFormatter: headerFormatter
        }];
    }

    setStringFilterValue = (filter) => filter ? filter.filterVal : "";

    onTableChange = (type, { page, sizePerPage, filters, sortField, sortOrder, cellEdit }) => {
        let { filter } = this.state; 
        this.setState({
            Sort: { sortField: sortField, sortOrder: sortOrder },
            page: page,
            sizePerPage: sizePerPage
        })
        this.onFilterClick(this.state.filter, page, sizePerPage);
    }

    // componentDidUpdate(prevProps, prevState) { 
    //     if (this.state.Sort !== prevState.Sort) {  
    //       this.onFilterClick(this.state.filter)
    //     }
    // }


    onFilterClick = async (filters,page, sizePerPage) => {
        console.log(filters)
        let { Sort} = this.state;
        // Sort
        let personFirstNameSort = 0;
        let personLastNameSort = 0;
        let socialSecurityNumberSort = 0;
        let stateOfBirthSort = 0;
        let countryOfBirthSort = 0;
        let affiliationsSort = 0;
        let contractAffiliationSort = 0;
        let activeStatusSort = 0;
       // let page = 1;

        const sortDir = Sort.sortOrder === 'asc' ? 1 : -1; 
        switch (Sort.sortField) {
            case "personFirstName":
                personFirstNameSort = sortDir;
                break;
            case "personLastName":
                personLastNameSort = sortDir;
                break;
            case "socialSecurityNumber":
                socialSecurityNumberSort = sortDir;
                break;
            case "stateOfBirth":
                stateOfBirthSort = sortDir;
                break;
            case "countryOfBirth":
                countryOfBirthSort = sortDir;
                break;
            case "affiliations":
                affiliationsSort = sortDir;
                break;
            case "contractAssociation":
                contractAffiliationSort = sortDir;
                break;
            case "activeStatus":
                activeStatusSort = sortDir;
                break;
        }

        this.setState({
            loading: true
        });

        console.log('Size per page is', sizePerPage)
        // Request Object
        getSubjectsForOrgWithFilter(filters.personFirstName, personFirstNameSort,
            filters.personLastName, personLastNameSort,
            filters.socialSecurityNumber, socialSecurityNumberSort,
            filters.stateOfBirth, stateOfBirthSort,
            filters.countryOfBirth, countryOfBirthSort,
            filters.affiliations, affiliationsSort,
            filters.contractAssociation, contractAffiliationSort,
            filters.activeStatus, activeStatusSort,
            page - 1, sizePerPage).then(response => {
                this.setState({
                    subjects: response.subjects,
                    totalSize: response.totalSize,
                    page: page,
                    sizePerPage: sizePerPage,
                    loading: false,
                    filter: filters
                });
            });
    }
    render() {
        const {page, sizePerPage, subjects = [], totalSize, loading, filter } = this.state;

        const remoteSettings = {
            filter: true,
            pagination: true,
            sort: true,
            cellEdit: false
        };

        return (
            <div>
                {/* {this.getBreadCrumb()} */}
                {!isHROnlyRole() && ConditionalDisplay(
                <div className="float-right">
                    <ButtonToolbar>
                        <DocumentUploadDialogForCSV onUpload={this.extractCSV} multiple={false} accept={['.csv']} />
                        <SubjectSearchModal buttonText="Add Subject" onSelect={this.onSelectSubject}/>
                        <BulkDocsNew/>
                        <TableExport columns={this.getColumns()} data={subjects} columnNameField={'text'} dataFieldName={'dataField'} reportName={'Subjects'}/>
                    </ButtonToolbar>
                </div>
                )}
                <div className="d-flex">
                    <h3 className="page_title_left">Subjects</h3>
                    <Subject_Filter filters={filter} onSubmit={this.onFilterClick} />
                </div>
                <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="id" data={subjects}
                                columns={this.getColumns()} filter={filterFactory()} remote={remoteSettings}
                                onTableChange={this.onTableChange}
                                loading={loading}
                                defaultSortDirection="asc" 
                                pagination={paginationFactory({page, sizePerPage, totalSize: totalSize})}
                                defaultSorted={[{dataField:'personFirstName', order:'asc'}]}
                />
            </div>
        )
    }
}

export default connect(null, {extractCSVData})(SubjectList);