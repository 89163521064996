import React, {Component, Fragment} from "react";
import filterFactory, {textFilter} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import BootstrapTable from "react-bootstrap-table-next";
import {connect} from "react-redux";
import {Formik,Field} from "formik";
import * as Yup from "yup";
import {Button, Card, Col, Form, Modal} from "react-bootstrap";
import {CommonUtils, DateUtils} from "../../../../../actions/common_utils";
import DeleteConfirmationModal from "../../../../../components/delete_confirm_modal";
import {isHROnlyRole, isReadOnlyRole} from "../../../../util/shouldDisplay";
import {NewHireSearchModal} from "./newhire_search_modal";
import MaskedInput from "react-input-mask";
import {showErrorGrowl, showSuccessGrowl} from "../../../../../actions/msg_actions";
import {CUR_ORG} from "../../../../../actions/session_constants";
import {createClearanceCheckRequest} from "../../../../../actions/new_hire_actions";
import {getSubjectDob, getSubjectSsn} from "../../../../../actions/subject_actions";
import {  getFacilityLevels} from "../../../../../actions/fieldValue_actions";
import {FormikDatePickerComponent} from "../../../../../components/formik/formik_date_picker";
import {getCitizenshipAcquiredTypes} from '../../../../../actions/fieldValue_actions'




class NewHireClearanceCheckComp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            newHires: [],
            showAddNewHireForm: false,
            disableSubmit: false,
            requiredClearanceLevels:[],
            citizenshipTypes:[]
        }
    }

    onHireAdd = (values, actions) => {
        actions.setSubmitting(false);

        let {newHires} = this.state;
        if (values.personBirthDate){
            values.personBirthDate = DateUtils.toYYYYMMDD(values.personBirthDate);
        }
        if(!values.citizenshipAcquiredType && values.bornStatus){
            values.citizenshipAcquiredType = 'BORN_IN_TERRITORY'
        }
        newHires.push(values);
        this.setState({showAddNewHireForm: false});
    }

    onInitiateClearanceCheck = async () => {
        this.setState({disableSubmit: true});
        try {

            await createClearanceCheckRequest(this.state.newHires);
            this.props.showSuccessGrowl("New Hire Clearance Check", "Successfully created clearance check request");
            this.props.history.push("/org/dash/task");
        } catch (e) {
            console.log(e);
            this.props.showErrorGrowl("Unable to initiate new Hire clearance check", "Unexpected error");
        }
        this.setState({disableSubmit: false});
    }
    componentDidMount() {
        getFacilityLevels((response) => {
            let newRequiredClearanceLevels = []  
         response.data.forEach((requiredClearance) => {
              if (requiredClearance.displayName.toLowerCase() !== "user") {
                newRequiredClearanceLevels.push({
                  id: requiredClearance.id,
                  name: requiredClearance.displayName,
                });
              }
            });
            this.setState({
                requiredClearanceLevels: newRequiredClearanceLevels
            });
         })
         this.getCitizenshipTypes()
    }
    getCitizenshipTypes = async () =>{
        const response = await getCitizenshipAcquiredTypes();
        let newCitizenshipTypes = []  
         response.data.forEach((citizenType) => {
              if (citizenType.displayName.toLowerCase() !== "user") {
                newCitizenshipTypes.push({
                  id: citizenType.id,
                  name: citizenType.displayName,
                });
              }
            });
            this.setState({
                citizenshipTypes: newCitizenshipTypes
            });
          }

    open = () => {
        this.setState({showAddNewHireForm: true});
    }
    close = () => {
        this.setState({showAddNewHireForm: false});
    }

    handleCancel = () => {
        window.history.back();
    }

    removeNewHire = (newHire) => {
        let {newHires} = this.state;
        let index = newHires.indexOf(newHire);
        newHires.splice(index, 1);
        this.setState({newHires})
    }

    getSensData = (subject) => {
        return new Promise((resolve)=> {
            getSubjectDob(subject.id, (resp) => {
                subject.personBirthDate = DateUtils.getDateString(resp.data);
                getSubjectSsn(subject.id, (resp) => {
                    subject.socialSecurityNumber = resp.data;
                    resolve();
                });
            });
        })
    }

    onSelectSubject = (subject) => {
        console.log('Subject b4 is ', subject.countryOfBirth);
        let {showErrorGrowl} = this.props;
        // Check if subject already exists within this org and if so no they cant be a new hire
        let orgFound = false;
        if (subject.orgs) {
            for (let eachOrg of subject.orgs) {
                if (eachOrg.active && eachOrg.orgId === sessionStorage.getItem(CUR_ORG)) {
                    orgFound = true;
                    break;
                }
            }
        }
        let abbrToCountryMap = CommonUtils.getCountryAbbrToFullNameMap();
        console.log('Country to name is ', abbrToCountryMap)
        subject.countryOfBirth = abbrToCountryMap.get(subject.countryOfBirth);
        this.getSensData(subject).then(()=>{
            console.log('Subject after is ', subject);
            if (orgFound){
                showErrorGrowl("Subject Exists in Organization", "Subject already is a member of this organization. Cannot be added again");
                return;
            }
            this.setState({
                selectedSubject: subject,
                showAddNewHireForm: true
            });
        });
    }
    onCreateSubject = (values) => {
        console.log('Values are ', values);
        let subject = {
            socialSecurityNumber: values.ssn
        };
        this.setState({
            selectedSubject: subject,
            showAddNewHireForm: true
        });
    }
    render() {

        const {showAddNewHireForm, newHires, selectedSubject} = this.state;

        const columns = [{
            dataField: 'personFirstName',
            text: 'First Name',
            sort: true,
            filter: (newHires && newHires.length > 0)?textFilter():''
        }, {
            dataField: 'personLastName',
            text: 'Last Name',
            sort: true,
            filter: (newHires && newHires.length > 0)?textFilter():''
        }, {
            dataField: 'homePhone',
            text: 'Home Phone',
            sort: true,
            filter: (newHires && newHires.length > 0)?textFilter():''
        }, {
            dataField: 'workEmailAddress',
            text: 'Email Address',
            sort: true,
            filter: (newHires && newHires.length > 0)?textFilter():''
        }, {
            dataField: 'actions',
            text: 'Actions',
            formatter: (cell, row) => {
                return (
                    <Fragment>
                        {
                        <Button className="close close_icon" onClick={() => this.removeNewHire(row)}>
                            <span aria-hidden="true" title={"Remove Subject From New Hire Process"} style={{padding: 10}} aria-label="Remove Subject From Hiring">
                            <i className="fa fa-times-circle" style={{color: "black"}}></i>
                            </span>
                        </Button>
                        }
                    </Fragment>
                )
            }
        }];

        const pagination = paginationFactory({
            sizePerPageList: [
                {text: '10', value: 10},
                {text: '20', value: 20},
                {text: '30', value: 30}
            ]
        });

        return (
            <Fragment>
                <Modal.Body>
                    <Card>
                        <Card.Header>New Hires for Clearance Check</Card.Header>
                        <Card.Body>
                            {(!newHires || newHires.length === 0) &&
                            <BootstrapTable bootstrap4={true} hover striped bordered={false}
                                            keyField="socialSecurityNumber"
                                            data={newHires}
                                            columns={columns}
                                            filter={filterFactory()}/>
                            }
                            {(newHires && newHires.length > 0) &&
                            <BootstrapTable bootstrap4={true} hover striped bordered={false}
                                            keyField="socialSecurityNumber"
                                            data={newHires}
                                            columns={columns} pagination={pagination}
                                            filter={filterFactory()}/>
                            }
                            <Modal show={showAddNewHireForm} onHide={this.close} size={'lg'} scrollable={false}>
                                <Formik
                                    initialValues={{
                                        personFirstName: (selectedSubject?selectedSubject.personFirstName:""),
                                        personMiddleName: (selectedSubject?selectedSubject.personMiddleName:""),
                                        personLastName: (selectedSubject?selectedSubject.personLastName:""),
                                        socialSecurityNumber: (selectedSubject?selectedSubject.socialSecurityNumber:""),
                                        homePhone: (selectedSubject?selectedSubject.homePhone:""),
                                        workEmailAddress: "",
                                        requiredClearanceLevel:(selectedSubject?selectedSubject.requiredClearanceLevel:""),
                                        bornStatus: false,
                                        ...(selectedSubject && selectedSubject.bornStatus ? {
                                        citizenshipAcquiredType: selectedSubject?.selectedSubject?.citizenshipAcquiredType || '',
                                         citizenshipDate: selectedSubject.citizenshipDate || '' } : {}),
                                    }}
                                    enableReinitialize={true}
                                    onSubmit={this.onHireAdd}
                                    validationSchema={
                                        Yup.object().shape({
                                            personFirstName: Yup.string().required("Please enter a first name"),
                                            personLastName: Yup.string().required("Please enter a last name"),
                                            socialSecurityNumber: Yup.string().required("Please enter an SSN"),
                                            workEmailAddress: Yup.string().required("Please enter an email address"),
                                            citizenshipDate: Yup.date().when('bornStatus', {
                                                is: true,
                                                then: Yup.date().required("Please enter citizenship date"),
                                                otherwise: Yup.date()
                                            })
                                        })
                                    }
                                >
                                    {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur}) => (
                                        <Form noValidate onSubmit={handleSubmit}>
                                            <Modal.Body>
                                                <Card>
                                                    <Card.Header>Add New Hire Information for Clearance Check</Card.Header>
                                                    <Card.Body>
                                                        <Form.Row>
                                                            <Form.Group as={Col} md="4">
                                                                <Form.Label>First Name</Form.Label>
                                                                <Form.Control required type="text" name={'personFirstName'}
                                                                              onChange={handleChange}
                                                                              onBlur={handleBlur}
                                                                              isInvalid={touched.personFirstName && !!errors.personFirstName}
                                                                              value={values.personFirstName}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.personFirstName}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="4">
                                                                <Form.Label>Middle Name</Form.Label>
                                                                <Form.Control required type="text" name={'personMiddleName'}
                                                                              onChange={handleChange}
                                                                              onBlur={handleBlur}
                                                                              isInvalid={touched.personMiddleName && !!errors.personMiddleName}
                                                                              value={values.personMiddleName}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.personMiddleName}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="4">
                                                                <Form.Label>Last Name</Form.Label>
                                                                <Form.Control required type="text" name={'personLastName'}
                                                                              onChange={handleChange}
                                                                              onBlur={handleBlur}
                                                                              isInvalid={touched.personLastName && !!errors.personLastName}
                                                                              value={values.personLastName}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.personLastName}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Form.Row>
                                                        <Form.Row>
                                                            <Form.Group as={Col} md="4" controlId="socialSecurityNumber">
                                                                <Form.Label>SSN</Form.Label>
                                                                <Form.Control
                                                                    required
                                                                    type={"text"}
                                                                    name="socialSecurityNumber"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    isInvalid={touched.socialSecurityNumber && !!errors.socialSecurityNumber}
                                                                    value={values.socialSecurityNumber}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.socialSecurityNumber}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Form.Group as={Col} md={"4"} controlId="workEmailAddress">
                                                                <Form.Label>Email Address</Form.Label>
                                                                <Form.Control
                                                                    type="email"
                                                                    name="workEmailAddress"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    placeholder="Work Email Address"
                                                                    isInvalid={touched.workEmailAddress && !!errors.workEmailAddress}
                                                                    value={values.workEmailAddress}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.workEmailAddress}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="4" controlId="homePhone">
                                                                <Form.Label>Phone Number</Form.Label>
                                                                <Form.Control
                                                                    required
                                                                    as={MaskedInput}
                                                                    name="homePhone"
                                                                    mask="999-999-9999"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    isInvalid={touched.homePhone && !!errors.homePhone}
                                                                    value={values.homePhone}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.homePhone}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Form.Row>
                                                        <Form.Row>
                                                        <Form.Group as={Col} md="4" controlId="requiredClearanceLevel">
                                                          <Form.Label>Required Clearance Level</Form.Label>
                                                         <Form.Control required as="select" name={'requiredClearanceLevel'}
                                                         onChange={handleChange}
                                                         onBlur={handleBlur}
                                                         isInvalid={touched.requiredClearanceLevel && !!errors.requiredClearanceLevel}
                                                         value={values?.requiredClearanceLevel !== null? values?.requiredClearanceLevel :null}
                                                         >
                                                        {CommonUtils.getOptionsForSelect(this.state.requiredClearanceLevels)}
                                               </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.requiredClearanceLevel}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                                        </Form.Row>
                                                        <Form.Row>
                                             <Form.Group as={Col} md="6" controlId={'bornStatus'}>
                                                  <Form.Label> </Form.Label>
                                                                    <Form.Check
                                                                    className="custom_check"
                                                                        type="checkbox"
                                                                        name={"bornStatus"}
                                                                        onBlur={handleBlur}
                                                                        label="Is the person born outside of the US"
                                                                        onChange={handleChange}
                                                                        checked={values?.bornStatus}
                                                
                                                                    />
                                                                </Form.Group>
                                                        </Form.Row>
                                                        {values?.bornStatus ? <Form.Row>
                                                        <Form.Group as={Col} md="6" controlId={'citizenshipAcquiredType'}>
                                                                <Form.Label>Citizenship Type</Form.Label>
                                                                <Form.Control required as="select" name={'citizenshipAcquiredType'}
                                                                              onChange={handleChange}
                                                                              onBlur={handleBlur}
                                                                              isInvalid={touched.citizenshipAcquiredType && !!errors.citizenshipAcquiredType}
                                                                              value={values.citizenshipAcquiredType? values.citizenshipAcquiredType:null}
                                                                >
                                                                    {CommonUtils.getOptionsForSelect(this.state.citizenshipTypes)}
                                                                </Form.Control>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.citizenshipAcquiredType}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="6" controlId={'citizenshipDate'}>
                                                                <Form.Label> Citizenship Date</Form.Label>
                                                                <Field
                                                                    id="citizenshipDate"
                                                                    name="citizenshipDate"
                                                                    component={FormikDatePickerComponent}
                                                                    placeholder={"citizen Ship Date"}
                                                                />
                                                            </Form.Group>
                                                            <Form.Control.Feedback type="invalid">
                                                                    {errors.citizenshipDate}
                                                                </Form.Control.Feedback>
                                                        </Form.Row>:null}
                                                    </Card.Body>
                                                    <ButtonToolbar>
                                                    <Button variant="primary" disabled={isSubmitting} type="submit">Add
                                                        New Hire</Button>
                                                    <Button variant="danger" onClick={this.close}>Cancel</Button>
                                                </ButtonToolbar>
                                                </Card>
                                            </Modal.Body>
                                            {/* <Modal.Footer>
                                               
                                            </Modal.Footer> */}
                                        </Form>
                                    )}
                                </Formik>
                            </Modal>
                        </Card.Body>
                        <Card.Footer>
                            {(!isReadOnlyRole() || isHROnlyRole()) &&
                            <div className={"text-right"}>
                                <NewHireSearchModal buttonText="Search/Create Subject" onSelect={this.onSelectSubject} onCreateNew={this.onCreateSubject} />
                            </div>
                            }
                        </Card.Footer>
                    </Card>
                </Modal.Body>
                <Modal.Footer>
                    <div className={"pull-right1"}>
                        <ButtonToolbar>
                            <Button variant="primary" disabled={!newHires || newHires.length === 0 || this.state.disableSubmit}
                                    onClick={this.onInitiateClearanceCheck}>Submit</Button>
                            {/* <Button variant="danger" onClick={this.props.onCancel}>Cancel</Button> */}
                            <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="New Hire Clearance Check"
                                                         handleDeleteCallback={this.handleCancel}/>
                        </ButtonToolbar>
                    </div>
                </Modal.Footer>
            </Fragment>
        )
    }

}

export const NewHireClearanceCheck = connect(null, {showErrorGrowl, showSuccessGrowl})(NewHireClearanceCheckComp);
