import React, {Component, Fragment} from "react";
import {DateUtils} from "../../../../../actions/common_utils";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";

export class SubjectForeignContactDetails extends Component {
    getForeignContacts() {
        return this.props.subject.foreignContacts.map((contact) => {
            return <tr key={contact.id}>
                <td>{contact.firstName} {contact.lastName}{contact.relative === "true" ? " (Relative)" : ""}</td>
                <td>{contact.citizenCountry}</td>
                <td>{contact.placeOfContact}</td>
                <td>{contact.contactReason}</td>
                <td>{contact.startDate ? DateUtils.getDateString(contact.startDate) : "Unknown"}</td>
                <td>
                    {contact.dateInvalidated ?
                        `${DateUtils.getDateString(contact.dateInvalidated)} (${contact.reasonInvalidated ? contact.reasonInvalidated.displayName : "Unknown"})`
                        : "Present"}
                </td>
            </tr>
        })
    }

    render() {
        const {subject} = this.props;

        if (!subject.foreignContacts || subject.foreignContacts.length === 0) {
            return <Alert variant="danger">No Foreign Contacts!</Alert>
        }

        return (
            <Fragment>
                <Table>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Citizenship</th>
                        <th>Contact Location</th>
                        <th>Contact Reason</th>
                        <th>From Date</th>
                        <th>To Date</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.getForeignContacts()}
                    </tbody>
                </Table>
            </Fragment>
        );
    }
}