import React, { Component, Fragment } from "react";
import { connect } from "react-redux"; 
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import { fetchPersonAsync, reportForSubjectBySO } from "../../../../../../actions/subject_actions.js";
import { showErrorGrowl, showSuccessGrowl } from "../../../../../../actions/msg_actions.js"; 
import { ConditionalDisplay } from "../../../../../util/shouldDisplay.js";
import Modal from "react-bootstrap/Modal";
import { SubjectForeignVotingDetails } from "../subject_foreign_voting_details.js";
import { CommonForeignVoting } from "../../../../../reporting/subject/commonInfo/common_foreign_voting.js";
import { CommonForeignIntelligenceContact } from "../../../../../reporting/subject/commonInfo/common_foreign_intelligence_contect.js";
import { SubjectForeignIntelligenceContactDetails } from "../subject_foreign_intelligence_contact_details.js";
import { SubjectMediaContactDetails } from "../subject_media_contact_details.js";
import { CommonPersonalInfoMediaContact } from "../../../../../reporting/subject/commonInfo/common_personalInfo_mediacontact.js";


class SubjectReportIMediaContactComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModel: false,
            mediaContacts:[]
        }

    }
    
    componentDidMount() {
        const { mediaContacts } = this.props;
        this.setState({ mediaContacts: mediaContacts })
    }

    async onSubmit(values) {
        const { subject } = this.props; 
        values.subjectId = subject.id;  
        values.type = "MEDIA_CONTACT";   
        values.jsonClassType="MediaContactReportable";  
        let response = await reportForSubjectBySO(values) 
        if (response) {
            this.props.showSuccessGrowl("Intelligence Contact Reported");
            let response = await fetchPersonAsync(subject.id); 
            if (response != null && response.mediaContacts ) {
                this.setState({ mediaContacts : response.mediaContacts  }) 
            } 
            this.setState({ showModel: false })
        } else {
            if (error.response.status === 404) {
                this.props.showErrorGrowl("Subject Not Found", "Please check the identity information.")
            }
        }
    }

    handleCancel = () => {
        this.setState({ showModel: false })
    }
    viewAdd = () => {
        this.setState({ showModel: true })
    } 
    render() { 
        const { showModel,mediaContacts } = this.state; 
        return (
            <Fragment>
                {ConditionalDisplay(
                    <div className="align-right">
                        <ButtonToolbar>
                            <Button variant="primary" className="mb-3" onClick={this.viewAdd}>Add Media Contact</Button>
                        </ButtonToolbar>
                    </div>
                )}
                <Modal size="lg" show={showModel} onHide={this.handleCancel} >
                    <Modal.Header closeButton>
                        <Modal.Title><h3>Report Media Contact Form</h3></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <CommonPersonalInfoMediaContact onSubmit={this.onSubmit.bind(this)} onCancel={this.handleCancel}/>
                    </Modal.Body>
                </Modal>
                <SubjectMediaContactDetails mediaContacts={mediaContacts}/>
            </Fragment>
        )
    }
}

function mapStateToProps({ person }) {
    return {
        subject: person
    }
}
export const SubjectReportIMediaContact = connect(mapStateToProps, {showSuccessGrowl, showErrorGrowl})(SubjectReportIMediaContactComp);

 