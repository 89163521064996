import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Form} from "redux-form";
import {DateUtils} from "../../../../../actions/common_utils";
import PropTypes from "prop-types";
import React, {Component, Fragment} from "react";
import Button from "react-bootstrap/Button";
import {downloadDocument} from "../../../../../actions/doc_actions";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";

export class SubjectAddlClearanceDetailComp extends Component {

    render() {
        let {subjectAddlClearance} = this.props;
        console.log('Details are ', subjectAddlClearance)
        return (
            <Fragment>
                <Card>
                    <Card.Header>Details</Card.Header>
                    <Card.Body>
                        <Row className={"mb-3"}>
                            <Col as={Form.Label} sm={3} md={2} lg={3}>
                                Eligibility 
                            </Col>
                            <Col sm={3} md={4} lg={3}>
                               {subjectAddlClearance.eligibility}
                            </Col>
                            <Col as={Form.Label} sm={3} md={2} lg={3}>
                                Eligibility Date 
                            </Col>
                            <Col sm={3} md={4} lg={3}>
                               {DateUtils.getDateString(subjectAddlClearance.eligibilityDate)}
                            </Col>
                        </Row>
                        <Row className={"mb-3"}>
                            <Col as={Form.Label} sm={3} md={2} lg={3}>
                                SCI Access 
                            </Col>
                            <Col sm={3} md={4} lg={3}>
                            {subjectAddlClearance.sciAccesses} 
                            </Col>
                            <Col as={Form.Label} sm={3} md={2} lg={3}>
                                Special Access 
                            </Col>
                            <Col sm={3} md={4} lg={3}>
                           {subjectAddlClearance.specialAccesses} 
                            </Col>
                        </Row>
                        <Row className={"mb-3"}>
                            <Col as={Form.Label} sm={3} md={2} lg={3}>
                                Granting Org 
                            </Col>
                            <Col sm={3} md={4} lg={3}>
                            {subjectAddlClearance.clearanceOrgType.displayName}
                            </Col>
                            <Col as={Form.Label} sm={3} md={2} lg={3}>
                                Investigation Type
                            </Col>
                            <Col sm={3} md={4} lg={3}>
                           {subjectAddlClearance.investigationType}
                            </Col>
                        </Row>
                        <Row className={"mb-3"} lg={3}>
                            <Col as={Form.Label} sm={3} md={2} lg={3}>
                                Inv. Begin Date 
                            </Col>
                            <Col sm={3} md={4} lg={3}>
                            {DateUtils.getDateString(subjectAddlClearance.investigationBeginDate)}
                            </Col>
                            <Col as={Form.Label} sm={3} md={2} lg={3}>
                                CE Date 
                            </Col>
                            <Col sm={3} md={4} lg={3}>
                           {DateUtils.getDateString(subjectAddlClearance.ceDateEnrolled)}  
                            </Col>
                        </Row>
                        <Row className={"mb-3"}>
                            <Col as={Form.Label} sm={3} md={2} lg={3}>
                                CE Status 
                            </Col>
                            <Col sm={3} md={4} lg={3}>
                            {subjectAddlClearance.ceStatus}  
                            </Col>
                            <Col as={Form.Label} sm={3} md={2} lg={3}>
                                Determination :
                            </Col>
                            <Col sm={3} md={4} lg={3}>
                             {subjectAddlClearance.determination} 
                            </Col>
                        </Row>
                        <Row className={"mb-3"}>
                            <Col as={Form.Label} sm={3} md={2} lg={3}>
                                Polygraph Date :
                            </Col>
                            <Col sm={3} md={4} lg={3}>
                             {DateUtils.getDateString(subjectAddlClearance.polygraph.date)}  
                            </Col>
                            <Col as={Form.Label} sm={3} md={2} lg={3}>
                                Polygraph Type :
                            </Col>
                            <Col sm={3} md={4} lg={3}>
                             {subjectAddlClearance.polygraph.type}  
                            </Col>
                        </Row>
                        <Row className={"mb-3"}>
                            <Col as={Form.Label} sm={3} md={2} lg={3}>
                                Polygraph Org :
                            </Col>
                            <Col sm={3} md={4} lg={3}>
                            {subjectAddlClearance.polygraph.agency}
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                {
                    subjectAddlClearance.debriefInfo &&
                        <Card className="mt-20">
                            <Card.Header>Debrief Information</Card.Header>
                            <Card.Body>
                                <Row className={"mb-3"}>
                                    <Col as={Form.Label} sm={3} md={2} lg={3}>
                                        Date 
                                    </Col>
                                    <Col sm={3} md={4} lg={3}>
                                        {DateUtils.getDateString(subjectAddlClearance.debriefInfo.date)}  
                                    </Col>
                                    <Col as={Form.Label} sm={3} md={2} lg={3}>
                                        Comment 
                                    </Col>
                                    <Col sm={3} md={4} lg={3}>
                                        {subjectAddlClearance.debriefInfo.comments} 
                                    </Col>
                                </Row>
                                {subjectAddlClearance.debriefInfo.awsS3ObjectKey &&
                                <Row className={"mb-3"}>
                                    <Col as={Form.Label} sm={3} md={2} lg={3}>
                                        Debrief Document (if any) 
                                    </Col>
                                    <Col sm={9} md={10} lg={9}>
                                        <Button variant={'link'} title={"Download file"} className="download_text"
                                                onClick={() => downloadDocument(subjectAddlClearance.debriefInfo.awsS3ObjectKey)}>
                                            {subjectAddlClearance.debriefInfo.fileName}
                                            {/*<span aria-hidden="true" style={{paddingRight: 10}}*/}
                                            {/*      title={"Download Document"} aria-label="Download Document">*/}
                                            {/*    <i className="fa fa-circle-down"></i>*/}
                                            {/*</span>*/}
                                        </Button>
                                    </Col>
                                </Row>
                                }
                            </Card.Body>
                        </Card>
                }
            </Fragment>
        )
    }
}

SubjectAddlClearanceDetailComp.propTypes = {
    subjectAddlClearance: PropTypes.any
};
