import {Formik, Field} from "formik";
import React, {Component, Fragment, useEffect, useState} from "react";
import DeleteConfirmationModal from "../../../../components/delete_confirm_modal";
import * as Yup from "yup";
import {Form, Card, Col, Button, ButtonToolbar, Modal} from "react-bootstrap";
import {CommonUtils} from "../../../../actions/common_utils";

export const NewTask_SecondStep = ({
                                       formData,
                                       FormSubmitStep2,
                                       massEmail,
                                       workflowOptions,
                                       onWorkflowOptionChange,
                                       previousPage,
                                       handleCancel,
                                       signTemplate,
                                       selectedDocType,
                                   }) => {
    const [FormValues, setFormValues] = useState(formData);

    useEffect(() => {
        setFormValues(formData);
    }, [formData]);
    const OnSubmitClick = (values, actions) => {
        actions.setSubmitting(false);
        FormSubmitStep2(values);
    };
    return (
        <Formik
            initialValues={FormValues}
            enableReinitialize={true}
            onSubmit={OnSubmitClick}
            validationSchema={Yup.object().shape({
                name: Yup.string().required("Enter a name"),
                desc: Yup.string().required("Please enter a description"),
                dueDate: Yup.date().required("Enter a date"),
                requiredDocs: Yup.array()
                    .required("Select at least one document type")
                    .test(
                        "required-docs-test",
                        "Please select a document",
                        function (value) {
                            return value && value.length > 0;
                        }
                    ),
            })}
        >
            {({
                  isSubmitting,
                  values,
                  handleChange,
                  setFieldValue,
                  setFieldTouched,
                  handleSubmit,
                  touched,
                  resetForm,
                  errors,
                  handleBlur,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Modal.Body>
                        {signTemplate && signTemplate.length >= 1 && <label className="alert alert-info">
                            Document types: {selectedDocType.join(", ")} have signature templates available. Please
                            select the checkbox if you want to use them.
                        </label>}
                        <Card>
                            <Card.Body>
                                <Form.Row>
                                    {!massEmail && (
                                        <Form.Group as={Col} md="6" controlId={"workflowRequired"}>
                                            <Form.Label>Is workflow needed?</Form.Label>
                                            <Form.Control
                                                required
                                                as="select"
                                                name={"workflowRequired"}
                                                onChange={(value, e) => {
                                                    onWorkflowOptionChange(value, setFieldValue);
                                                    handleChange(value);
                                                }}
                                                disabled={values.templateId}
                                                onBlur={handleBlur}
                                                isInvalid={
                                                    touched.workflowRequired && !!errors.workflowRequired
                                                }
                                                value={values.workflowRequired}
                                            >
                                                {CommonUtils.getOptionsForSelect(workflowOptions)}
                                            </Form.Control>
                                        </Form.Group>
                                    )}
                                    {
                                        signTemplate && signTemplate.length >= 1 &&
                                        <Form.Group as={Col} md="6" controlId={"useTemplates"}>
                                            <label className="mt-2"></label>
                                            <Form.Check className="mt-3"
                                                        id={'useTemplates'}
                                                        name={'useTemplates'}
                                                        label={'Do you want to use template'}
                                                        checked={values.useTemplates}
                                                        onChange={(event) => handleChange(event)}
                                                        isInvalid={touched.useTemplates && !!errors.useTemplates}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.useTemplates}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    }
                                </Form.Row>
                            </Card.Body>
                            <Card.Footer>
                                <div className={"pull-right1"}>
                                    <ButtonToolbar>
                                        <Button variant="primary" onClick={() => previousPage(values)}>
                                            Previous
                                        </Button>
                                        <Button variant="primary" disabled={false} type="submit">
                                            Next
                                        </Button>
                                        <DeleteConfirmationModal
                                            buttonText={"Cancel"}
                                            actionText={"cancel"}
                                            deleteEntity="New Task Creation Form"
                                            handleDeleteCallback={handleCancel}
                                        />
                                    </ButtonToolbar>
                                </div>
                            </Card.Footer>
                        </Card>
                    </Modal.Body>

                </Form>
            )}
        </Formik>
    );
};
