import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

class SubjectBackgroundInformation extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {subject} = this.props;

        return (
            <Fragment>
                {subject.backgroundInvestigation &&
                    <Fragment>
                        <Row className={'mb-3'}>
                            <Col as={Form.Label} sm={3} md={2}>
                                Background Information Package Name
                            </Col>
                            <Col sm={3} md={4}>
                                {subject.backgroundInvestigation.biPackageName}
                            </Col>
                        </Row>
                        <Row className={'mb-3'}>
                            <Col as={Form.Label} sm={3} md={2}>
                                Background Information Date
                            </Col>
                            <Col sm={3} md={4}>
                                {subject.backgroundInvestigation.biDate}
                            </Col>
                        </Row>
                        <Row className={'mb-3'}>
                            <Col as={Form.Label} sm={3} md={2}>
                                Background Information Company
                            </Col>
                            <Col sm={3} md={4}>
                                {subject.backgroundInvestigation.biCompany}
                            </Col>
                        </Row>
                        <Row className={'mb-3'}>
                            <Col as={Form.Label} sm={3} md={2}>
                                Background Information Disposition
                            </Col>
                            <Col sm={3} md={4}>
                                {subject.backgroundInvestigation.biDisposition}
                            </Col>
                        </Row>
                    </Fragment>
                }
            </Fragment>
        )
    }
}

SubjectBackgroundInformation.propTypes = {
    subject: PropTypes.object.isRequired
};

export default SubjectBackgroundInformation;