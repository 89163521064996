import {Steps} from "primereact/steps";
import React, {
    Component,
    Fragment,
    useCallback,
    useEffect,
    useState,
} from "react";
import {
    getDocTypes,
    getEntityTypes,
} from "../../../../actions/fieldValue_actions";
import {connect} from "react-redux";
import {Card, Button, Modal} from "react-bootstrap";
import {NewTask_FirstStep} from "./newTask_FirstStep";
import {NewTask_SecondStep} from "./newTask_SecondStep";
import {fetchTemplates} from "../../../../actions/signature_actions";
import {
    fetchActivePersonsByActiveOrgAsync,
    fetchActiveSubjectsByContractIdAsync,
    fetchSubjectsByFacilityIdAsync,
    getActiveOrgPersons,
} from "../../../../actions/subject_actions";
import {getOrgContracts} from "../../../../actions/contract_actions";
import {getOrgFacilities} from "../../../../actions/facility_actions";
import {CUR_ORG} from "../../../../actions/session_constants";
import {ORG_NAME} from "../../../../actions/auth_actions";
import {NewTask_ThirdStep} from "./newTask_ThirdStep";
import {
    createEmailTask,
    createEmailTaskPrecheck,
} from "../../../../actions/task_actions";
import {
    showErrorGrowl,
    showSuccessGrowl,
} from "../../../../actions/msg_actions";
import {fetchUsersByEntity} from "../../../../actions/user_actions";

export function NewTask_Main({
                                 createEmailTask,
                                 showSuccessGrowl,
                                 showErrorGrowl,
                                 history,
                             }) {
    const [stepItems, setStepItems] = useState(null);
    const [formValues, setFormValues] = useState(null);
    const [stepItemLabels, setStepItemLabels] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const [documentTypes, setDocumentTypes] = useState([]);
    const [templateSelectedRoles, setTemplateSelectedRoles] = useState([]);
    const [disableContactSubmit, setdisableContactSubmit] = useState(false);
    const [massEmail, setMassEmail] = useState(false);
    const [selectableEntityTypes, setSelectableEntityTypes] = useState({});
    const [templates, setTemplates] = useState(null);
    const [warningMessage, setWarningMessage] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [signTemplate, setSignTemplate] = useState(null);
    const [selectedDocType, setSelectedDocType] = useState(null);
    const [firstSelected, setFirstSelected] = useState(false);
    const [showEntityBox, setShowEntityBox] = useState(false);
    const [availSubjects, setAvailSubjects] = useState(null);
    const [availEntityTypes, setAvailEntityTypes] = useState([
        {
            id: "",
            name: "Select Entity Type...",
        },
    ]);
    const [massEmailOptions, setMassEmailOptions] = useState([
        {
            id: "no",
            name: "No. (User needs to take action to this task)",
        },
        {
            id: "yes",
            name: "Yes. (No feedback from user necessary)",
        },
    ]);
    const [workflowOptions, setWorkflowOptions] = useState([
        {
            id: "no",
            name: "No. (No workflow needed)",
        },
        {
            id: "yes",
            name: "Yes. (Yes, admin needs to approve the document(s))",
        },
    ]);
    const [availEntities, setAvailEntities] = useState([
        {
            id: "",
            name: "Select Assignee",
        },
    ]);
    const [availSecondEntities, setAvailSecondEntities] = useState([
        {
            id: "",
            name: "Select Approver",
        },
    ]);

    useEffect(() => {
        setFormValues({
            name: "",
            massEmailIssue: "",
            workflowRequired: "no",
            templateId: "",
            entityType: "",
            entityId: "",
            secondEntityId: "",
            desc: "",
            dueDate: "",
            documents: [],
            requiredDocs: "",
            userInstructionDocs: "",
            selectedSubjectIds: [],
            useTemplates: false,
        });
        setStepItemLabels([
            {label: "Step 1"},
            {label: "Step 2"},
            {label: "Step 3"},
        ]);
        setStepItems([
            {label: "Step 1"},
            {label: "Step 2"},
            {label: "Step 3"},
        ]);

        getTemplates();
        getDocTypes((response) => {
            let docuTypes = [];
            response.data.forEach((docType) => {
                if (docType.taskCreationDoc)
                    docuTypes.push({
                        value: docType.id,
                        label: docType.type,
                    });
            });
            setDocumentTypes(docuTypes);
        });

        getEntityTypes().then((response) => {
            let newEntityTypes = [
                {
                    id: "",
                    name: "Select Entity Type...",
                },
            ];
            response.data.forEach((entityType) => {
                console.log("EntityType is ", entityType);
                if (entityType.displayName.toLowerCase() !== "user") {
                    newEntityTypes.push({
                        id: entityType.id,
                        name: entityType.displayName,
                    });
                }
            });
            let availSecondEntities = [
                {
                    id: "",
                    name: "Select Approver",
                },
            ];

            // getActiveOrgPersons((response) => {
            //     let newEntityIds = [
            //         {
            //             id: "",
            //             name: "Second Entity Type",
            //         },
            //     ];
            //     response.data.forEach((subject) => {
            //         newEntityIds.push({
            //             id: subject.id,
            //             name: `${subject.personFirstName ? subject.personFirstName : ""} ${
            //                 subject.personLastName
            //                 }`,
            //         });
            //     });
            //
            //     for (let i = 1; i < newEntityIds.length; i++) {
            //         if (newEntityIds[i].id !== event.target.value) {
            //             availSecondEntities.push({
            //                 id: newEntityIds[i].id,
            //                 name: newEntityIds[i].name,
            //             });
            //         }
            //     }
            //     setAvailSecondEntities(availSecondEntities);
            // });
            //
            // Get the Users' subjects for approver list
            fetchUsersByEntity('ORG', sessionStorage.getItem(CUR_ORG)).then((response) => {
                if (response && response.data){
                    response.data.forEach((user) => {
                        let subject = user.subject;
                        if (subject && subject.id !== event.target.value) {
                            availSecondEntities.push({
                                id: subject.id,
                                name: `${subject.personFirstName ? subject.personFirstName : ""} ${
                                    subject.personLastName
                                    }`,
                            });
                        }
                    })
                    setAvailSecondEntities(availSecondEntities);
                }
            })

            setAvailEntityTypes(newEntityTypes);
            setSelectableEntityTypes(newEntityTypes);
            setWarningMessage(null);
            setShowModal(false);
        });
    }, []);

    const close = () => {
        setShowModal(false);
        setWarningMessage(false);
    };
    const handleCancel = () => {
        window.history.back();
    };
    const OnActiveIndexChange = () => {
        // renderStepContent();
    };
    const previousPage = (values) => {
        setFormValues(values);
        let stepid = activeStep;
        if (massEmail) setActiveStep(parseInt(stepid - 2));
        else setActiveStep(parseInt(stepid - 1));
    };
    const FormSubmitStep1 = (value) => {
        setSignTemplate(null)
        setTemplateSelectedRoles([])
        if (value.requiredDocs.length >= 1 && templates && templates.length > 0) {

            let assignTemplatelist = templates.filter((objA, index) => {
                if (index != 0 && objA.documentType) {
                    return value.requiredDocs.includes(objA.documentType.id)
                }
            });
            let SelectedDocType = [];
            assignTemplatelist.map((objA) => {
                SelectedDocType.push(objA.documentType.type);
                if (objA.roles && objA.roles.length > 1) {
                    setTemplateSelectedRoles(objA.roles);
                }
            });
            console.log("SelectedDocType", SelectedDocType)
            setSelectedDocType(SelectedDocType);
            setSignTemplate(assignTemplatelist);
            // setTemplateSelectedRoles(assignTemplatelist[0].roles);
        } else {
            setSelectedDocType(null);
            setSignTemplate(null);
        }
        setFormValues(value);
        let stepid = activeStep;
        if (massEmail) setActiveStep(parseInt(stepid + 2));
        else setActiveStep(parseInt(stepid + 1));
    };
    const FormSubmitStep2 = (value) => {
        console.log(value);
        setFormValues(value);
        let stepid = activeStep;
        console.log(parseInt(stepid + 1));
        setActiveStep(parseInt(stepid + 1));
    };

    const onMassEmailChange = (event) => {
        if (event.target.value.toLowerCase() === "no") {
            setMassEmail(false);
        } else {
            setMassEmail(true);
        }
    };

    const trimmSubjectFieldsAndAdd = (subjects) => {
        let trimmedFieldList = subjects.map((subject) => {
            return {
                value: subject.id,
                label: `${subject.personFirstName} ${subject.personLastName} (${subject.socialSecurityNumber})`,
            };
        });
        setAvailSubjects(trimmedFieldList);
    };

    const getTemplates = async () => {
        let _templates = await fetchTemplates((error) => {
            if (error.response.status === 412) {
                showErrorGrowl(
                    "Emailing to subjects not allowed",
                    "Organization does not allow email to be sent to subjects, so templates cannot be used"
                );
            } else {
                showErrorGrowl("Server Error", "A server error occurred!");
                console.log("Error is ", error);
            }
        });
        console.log("Templates are ", _templates);
        if (_templates && _templates.length > 0) {
            _templates = [
                {
                    id: "",
                    name: "Select Template...",
                    roles: [],
                },
                ..._templates,
            ];
        }
        setTemplates(_templates);
    };

    const onWorkflowOptionChange = (event, setFieldValue) => {
        let _availEntityTypes = availEntityTypes;
        if (event.target.value === "no") {
            setSelectableEntityTypes(_availEntityTypes);
        } else {
            setSelectableEntityTypes(
                getAvailEntityTypesToSubject(availEntityTypes, setFieldValue)
            );
        }
    };
    // const onTemplateChange = (event, setFieldValue) => {
    //   let _templates = templates;
    //   let roles = [];
    //   let newAvailTypes = availEntityTypes;
    //   if (event.target.value) {
    //     for (let template of _templates) {
    //       if (template.id === event.target.value) {
    //         roles = template.roles;
    //         break;
    //       }
    //     }
    //     // newAvailTypes = this.getAvailEntityTypesToSubject(availEntityTypes, setFieldValue);
    //   }
    //   setTemplateSelectedRoles(roles);
    // };

    const getAvailEntityTypesToSubject = (availEntityTypes, setFieldValue) => {
        let newAvailTypes = [];
        availEntityTypes.forEach((entityType) => {
            if (entityType.id === "SUBJECT") {
                newAvailTypes.push(entityType);
            }
        });
        setFieldValue("entityType", "SUBJECT");
        getSubjects(setFieldValue);
        return newAvailTypes;
    };

    const getSubjects = (setFieldValue) => {
        getActiveOrgPersons((response) => {
            let newEntityIds = [
                {
                    id: "",
                    name: "Select Subject",
                },
            ];
            response.data.forEach((subject) => {
                newEntityIds.push({
                    id: subject.id,
                    name: `${subject.personFirstName ? subject.personFirstName : ""} ${
                        subject.personLastName
                        }`,
                });
            });

            setAvailEntities(newEntityIds);
            setFieldValue("entityId", "");
            setAvailSubjects([]);
        });
    };

    const onConfirm = (values) => {
        let _formValues = values;
        createEmailTask(
            _formValues,
            (response) => {
                if (response.status === 503) {
                    showSuccessGrowl(response.data);
                } else {
                    showSuccessGrowl("Task Created Successfully!");
                }
                history.push("/org/dash/task/filter/" + _formValues.entityType);
            },
            (error) => {
                showErrorGrowl(error.response);
                setWarningMessage(null);
                setShowModal(false);
            }
        );
    };

    const onSubmit = async (values) => {
        setFormValues(values);
        if (!Array.isArray(values.requiredDocs)) {
            // When user clicks No on the confirmation and comes back the requiredDocs si already an array
            values.requiredDocs = [values.requiredDocs];
        }
        if (
            values.massEmailIssue &&
            values.massEmailIssue.toUpperCase() === "YES"
        ) {
            values.massEmailIssue = true;
        } else {
            values.massEmailIssue = false;
        }
        if (
            values.workflowRequired &&
            (values.workflowRequired === true ||
                values.workflowRequired.toUpperCase() === "YES")
        ) {
            values.workflowRequired = true;
        } else {
            values.workflowRequired = false;
        }
        // Clearing second entity ID if not required
        if (values.secondEntityId === "") {
            values.secondEntityId = null;
        }
        setFormValues(values);
        createEmailTaskPrecheck(
            values,
            () => {
                onConfirm(values);
            },
            (error) => {
                console.log("error", error);
                if (error.response.status === 208 || error.response.status === 412) {
                    console.log(error.response);
                    setWarningMessage(error.response.data);
                    setShowModal(true);
                } else {
                    showErrorGrowl("Unexpected error occurred", "Error creating task.");
                }
            }
        );
    };
    const onEntityTypeChange = (event, setFieldValue, entityType) => {
        setFirstSelected(false);
        switch (event.target.value) {
            case "ORG":
                // Add only the current organization to the list
                setAvailEntities([
                    {
                        id: sessionStorage.getItem(CUR_ORG),
                        name: sessionStorage.getItem(ORG_NAME),
                    },
                ]);
                secondSigOptions(event);
                fetchActivePersonsByActiveOrgAsync().then((subjects) => {
                    trimmSubjectFieldsAndAdd(subjects);
                    setFieldValue("entityId", sessionStorage.getItem(CUR_ORG));
                });
                break;
            case "":
                setAvailEntities([
                    {
                        id: "",
                        name: "Select an Entity Type...",
                    },
                ]);
                setFieldValue("entityId", "");
                setAvailSubjects([]);
                break;
            case "CONTRACT":
                getOrgContracts(sessionStorage.getItem(CUR_ORG)).then((response) => {
                    let newEntityIds = [
                        {
                            id: "",
                            name: "Select Contract",
                        },
                    ];
                    response.data.forEach((contract) => {
                        newEntityIds.push({
                            id: contract.contract.id,
                            name: contract.contract.name,
                        });
                    });
                    setAvailEntities(newEntityIds);
                    setFieldValue("entityId", "");
                    setAvailSubjects([]);
                });
                break;
            case "SUBJECT":
                getSubjects(setFieldValue);
                break;
            case "FACILITY":
                getOrgFacilities(sessionStorage.getItem(CUR_ORG)).then((response) => {
                    let newEntityIds = [
                        {
                            id: "",
                            name: "Select Location",
                        },
                    ];
                    response.data.forEach((facility) => {
                        newEntityIds.push({
                            id: facility.facility.id,
                            name: facility.facility.name,
                        });
                    });
                    setAvailEntities(newEntityIds);
                    setShowEntityBox(true);
                    setAvailSubjects([]);
                    setFieldValue("entityId", "");
                });
                break;
        }
    };
    const secondSigOptions = (event, templateId, entityType) => {
        console.log("Val is ", event.target.value);
        if (event.target.value && templateId) {
            setFirstSelected(true);
        }
        //else {
        switch (entityType) {
            case "CONTRACT":
                fetchActiveSubjectsByContractIdAsync(event.target.value).then(
                    (subjects) => {
                        trimmSubjectFieldsAndAdd(subjects);
                    }
                );
                break;
            case "FACILITY":
                fetchSubjectsByFacilityIdAsync(event.target.value).then((subjects) => {
                    trimmSubjectFieldsAndAdd(subjects);
                });
                break;
        }
        setFirstSelected(false);
        //}
    };


    return (
        <div className="container">

            <h3 className="page_title_left mb-3">New Task Creation Form</h3>

            <Fragment>
                {stepItems != null && (
                    <Card>
                        <Card.Body>
                            <Steps
                                model={stepItems}
                                activeIndex={activeStep}
                                activeIndexChange={OnActiveIndexChange}
                            />
                            {activeStep == 0 ? (
                                <NewTask_FirstStep
                                    formData={formValues}
                                    FormSubmitStep1={FormSubmitStep1}
                                    templateSelectedRoles={templateSelectedRoles}
                                    onMassEmailChange={onMassEmailChange}
                                    massEmailOptions={massEmailOptions}
                                    documentTypes={documentTypes}
                                    handleCancel={handleCancel}
                                />
                            ) : activeStep == 1 ? (

                                <NewTask_SecondStep
                                    formData={formValues}
                                    FormSubmitStep2={FormSubmitStep2}
                                    massEmail={massEmail}
                                    workflowOptions={workflowOptions}
                                    onWorkflowOptionChange={onWorkflowOptionChange}
                                    previousPage={previousPage}
                                    handleCancel={handleCancel}
                                    signTemplate={signTemplate}
                                    selectedDocType={selectedDocType}
                                />
                            ) : (
                                <NewTask_ThirdStep
                                    formData={formValues}
                                    FormSubmit={onSubmit}
                                    onEntityTypeChange={onEntityTypeChange}
                                    selectableEntityTypes={selectableEntityTypes}
                                    secondSigOptions={secondSigOptions}
                                    availEntities={availEntities}
                                    firstSelected={firstSelected}
                                    availSecondEntities={availSecondEntities}
                                    templateSelectedRoles={templateSelectedRoles}
                                    previousPage={previousPage}
                                    handleCancel={handleCancel}
                                    availSubjects={availSubjects}
                                />
                            )}

                            <Modal show={showModal} onHide={close}>
                                <Modal.Header closeButton>
                                    <Modal.Title>{"Task creation confirmation"}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>Are you sure you want to submit this New Task?</p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        onClick={() => {
                                            // resetForm();
                                            close();
                                        }}
                                        variant={"danger"}
                                    >
                                        No
                                    </Button>
                                    <Button onClick={onConfirm} variant={"primary"}>
                                        Yes
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </Card.Body>
                    </Card>
                )}
            </Fragment>
        </div>
    );
}

export default connect(null, {
    createEmailTask,
    showSuccessGrowl,
    showErrorGrowl,
    history,
})(NewTask_Main);
