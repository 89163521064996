import React, { Component, Fragment } from "react";
import { connect } from "react-redux"; 
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import { fetchPersonAsync, reportForSubjectBySO } from "../../../../../../actions/subject_actions.js";
import { showErrorGrowl, showSuccessGrowl } from "../../../../../../actions/msg_actions.js"; 
import { ConditionalDisplay } from "../../../../../util/shouldDisplay.js";
import Modal from "react-bootstrap/Modal";
import { CommonReportAlcoholDrug } from "../../../../../reporting/subject/commonInfo/common_report_alcohol_drug_treatment.js";
import { SubjectAlcoholDrugTreatmentDetails } from "../subject_alcohol_drug_treatment_details.js";
import { SubjectFinancialIssueAnomaliesDetails } from "../subject_financial_anomalies_details.js";
import { CommonFinancialIssueAnomaly } from "../../../../../reporting/subject/commonInfo/common_finanacial_issue_anomaly.js";


class SubjectReportFinancialIssueAnomalyComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModel: false,
            financialIssues:[]
        }

    }
    
    componentDidMount() {
        const { financialIssuesAndAnomalies } = this.props;
        this.setState({ financialIssues: financialIssuesAndAnomalies })
    }

    async onSubmit(values) {
        const { subject } = this.props; 
        values.subjectId = subject.id;  
        values.type = "FINANCIAL_ISSUES_AND_ANOMALIES";   
        values.jsonClassType="FinancialIssueAndAnomalyReportable";  
        let response = await reportForSubjectBySO(values) 
        if (response) {
            this.props.showSuccessGrowl("Treatment Reported");
            let response = await fetchPersonAsync(subject.id); 
            if (response != null && response.financialIssuesAndAnomalies) {
                this.setState({ financialIssues: response.financialIssuesAndAnomalies }) 
            } 
            this.setState({ showModel: false })
        } else {
            if (error.response.status === 404) {
                this.props.showErrorGrowl("Subject Not Found", "Please check the identity information.")
            }
        }
    }

    handleCancel = () => {
        this.setState({ showModel: false })
    }
    viewAdd = () => {
        this.setState({ showModel: true })
    } 
    render() { 
        const { showModel,financialIssues } = this.state; 
        return (
            <Fragment>
                {ConditionalDisplay(
                    <div className="align-right">
                        <ButtonToolbar>
                            <Button variant="primary" className="mb-3" onClick={this.viewAdd}>Add  Financial Issue & Anomaly</Button>
                        </ButtonToolbar>
                    </div>
                )}
                <Modal size="lg" show={showModel} onHide={this.handleCancel} >
                    <Modal.Header closeButton>
                        <Modal.Title><h3>Report Financial Issue & Anomaly</h3></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <CommonFinancialIssueAnomaly onSubmit={this.onSubmit.bind(this)} onCancel={this.handleCancel}/>
                    </Modal.Body>
                </Modal>
                <SubjectFinancialIssueAnomaliesDetails
                                financialIssuesAndAnomalies={financialIssues}/>
            </Fragment>
        )
    }
}

function mapStateToProps({ person }) {
    return {
        subject: person
    }
}
export const SubjectReportFinancialIssueAnomaly = connect(mapStateToProps, {showSuccessGrowl, showErrorGrowl})(SubjectReportFinancialIssueAnomalyComp);

 