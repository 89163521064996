import React, {Component, Fragment} from "react";
import {DateUtils} from "../../../../../actions/common_utils";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";

export class SubjectForeignPropertyDetails extends Component {
    getForeignProperties() {
        return this.props.foreignProperties.map((property) => {
            return <tr key={property.id}>
                <td>{property.location}</td>
                <td>{property.valueUsd}</td>
                <td>{property.balanceDue}</td>
                <td>{property.purpose}</td>
                <td>{property.howAcquired}</td>
                <td>{property.startDate ? DateUtils.getDateString(property.startDate) : "Unknown"}</td>
                <td>
                    {property.dateInvalidated ?
                        `${DateUtils.getDateString(property.dateInvalidated)} (${property.reasonInvalidated ? property.reasonInvalidated.displayName : "Unknown"})`
                        : "N/A"}
                </td>
            </tr>
        })
    }

    render() {
        const {foreignProperties} = this.props;

        if (!foreignProperties || foreignProperties.length === 0) {
            return <Alert variant="danger">No Foreign Properties</Alert>
        }

        return (
            <Fragment>
                <Table>
                    <thead>
                    <tr>
                        <th>Location</th>
                        <th>Value (USD)</th>
                        <th>Balance Due</th>
                        <th>Purpose</th>
                        <th>Acquired How</th>
                        <th>Acquired Date</th>
                        <th>Sold Date</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.getForeignProperties()}
                    </tbody>
                </Table>
            </Fragment>
        );
    }
}