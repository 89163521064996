/**
 * Created by pshivaraman on 1/29/19.
 */

import React, {Component, Fragment} from "react";
import {getSubjectDob, getSubjectSsn} from "../../../../../actions/subject_actions";
import {DateUtils} from "../../../../../actions/common_utils";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";

class SubjectGeneralDetails extends Component {
    constructor(props) {
        super(props);

        this.maskDob = this.maskDob.bind(this);
        this.maskSsn = this.maskSsn.bind(this);
        this.unmaskDob = this.unmaskDob.bind(this);
        this.unmaskSsn = this.unmaskSsn.bind(this);

        this.state = {
            dobMasked: true,
            ssnMasked: true,
            dob: "",
            ssn: ""
        }
    }

    unmaskDob() {
        getSubjectDob(this.props.subject.id, (resp) => {
            this.setState({
                dob: DateUtils.getDateString(resp.data),
                dobMasked: false
            });
        });
    }

    unmaskSsn() {
        getSubjectSsn(this.props.subject.id, (resp) => {
            this.setState({
                ssn: resp.data,
                ssnMasked: false
            });
        });
    }

    maskDob() {
        this.setState({
            dob: "",
            dobMasked: true
        });
    }

    maskSsn() {
        this.setState({
            ssn: "",
            ssnMasked: true
        });
    }

    render() {
        const {subject} = this.props;
        const {ssn, dob, ssnMasked, dobMasked} = this.state;

        let emailValidationStatus = 'Not Applicable';
        if (subject.emailAddress){
            if (!subject.emailAddressNotValidated){
                emailValidationStatus = 'Validated';
            } else {
                emailValidationStatus = 'Not Validated';
            }
        }
        const stateCountryOfBirth = () => {
            console.log('Subject inf is ', subject);
            if (subject.stateOfBirth && subject.stateOfBirth !== "") {
                return subject.stateOfBirth;
            }
            return subject.countryOfBirth;
        };

        return (
            <Fragment>
                <Row className={'mb-3'}>
                    <Col as={Form.Label} sm={3} md={2}>
                        First Name
                    </Col>
                    <Col sm={3} md={4}>
                        {subject.personFirstName}
                    </Col>
                    <Col as={Form.Label} sm={3} md={2}>
                        Last Name
                    </Col>
                    <Col sm={3} md={4}>
                        {subject.personLastName}
                    </Col>
                </Row>
                <Row className={'mb-3'}>
                    <Col as={Form.Label} sm={3} md={2}>
                        Birth City/State/Country
                    </Col>
                    <Col sm={3} md={4}>
                        {subject.cityOfBirth}{subject.cityOfBirth ? "," : ""} {stateCountryOfBirth()}
                    </Col>
                    <Col as={Form.Label} sm={3} md={2}>
                        Gender
                    </Col>
                    <Col sm={3} md={4}>
                        {subject.gender}
                    </Col>
                </Row>
                <Row className={'mb-3'}>
                    <Col as={Form.Label} sm={3} md={2}>
                        Social Security Number
                    </Col>
                    <Col sm={3} md={4}>
                        {ssnMasked &&
                        <Fragment>
                            {subject.socialSecurityNumber}
                            <Button onClick={this.unmaskSsn} className="ml-15">
                                <i className="fa fa-unlock-alt" aria-hidden="true"/>
                            </Button>
                        </Fragment>
                        }
                        {!ssnMasked &&
                        <Fragment>
                            {ssn}
                            <Button onClick={this.maskSsn} className="ml-15">
                                <i className="fa fa-lock" aria-hidden="true"/>
                            </Button>
                        </Fragment>
                        }
                    </Col>
                    <Col as={Form.Label} sm={3} md={2}>
                        Date of Birth
                    </Col>
                    <Col sm={3} md={4}>
                        {dobMasked &&
                        <Fragment>
                            {subject.maskedDateOfBirth}
                            <Button onClick={this.unmaskDob} className="ml-15">
                                <i className="fa fa-unlock-alt" aria-hidden="true"/>
                            </Button>
                        </Fragment>
                        }
                        {!dobMasked &&
                        <Fragment>
                            {dob}
                            <Button onClick={this.maskDob} className="ml-15">
                                <i className="fa fa-lock" aria-hidden="true"/>
                            </Button>
                        </Fragment>
                        }
                    </Col>
                </Row>
                <Row className={'mb-3'}>
                    <Col as={Form.Label} sm={3} md={2}>
                        Email Address
                    </Col>
                    <Col sm={3} md={4}>
                        {subject.emailAddress}
                    </Col>
                    <Col as={Form.Label} sm={3} md={2}>
                        Email Validation Status
                    </Col>
                    <Col sm={3} md={4}>
                        {emailValidationStatus}
                    </Col>
                </Row>
                <Row className={'mb-3'}>
                    <Col as={Form.Label} sm={3} md={2}>
                        Personal Email Address
                    </Col>
                    <Col sm={3} md={4}>
                        {subject.personalEmailAddress}
                    </Col>
                </Row>
            </Fragment>
        )
    }
}

SubjectGeneralDetails.propTypes = {
    subject: PropTypes.object.isRequired
};

export default SubjectGeneralDetails;