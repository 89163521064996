import React, {Component, Fragment} from "react";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";

export class SubjectFinancialIssueAnomaliesDetails extends Component {
    getFinancialIssues() {
        return this.props.financialIssuesAndAnomalies.map((anomaly) => {
            return <tr key={anomaly.id}>
                <td>{anomaly.typeOfIssue}</td>
                <td>{anomaly.valueUsd}</td>
                <td>{anomaly.mitigatingCondition}</td>
            </tr>
        })
    }

    render() {
        const {financialIssuesAndAnomalies} = this.props;

        if (!financialIssuesAndAnomalies || financialIssuesAndAnomalies.length === 0) {
            return <Alert variant="danger">No Financial Issues or Anomalies</Alert>
        }

        return (
            <Fragment>
                <Table>
                    <thead>
                    <tr>
                        <th>Issue Type</th>
                        <th>Value (USD)</th>
                        <th>Mitigating Condition</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.getFinancialIssues()}
                    </tbody>
                </Table>
            </Fragment>
        );
    }
}