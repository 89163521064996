/**
 * Created by pshivaraman on 1/29/19.
 */

import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {
    fetchPersonWithoutHidingDetails,
    getSubjectDob,
    getSubjectSsn,
    updateSubjectGeneralDetails
} from "../../../../../actions/subject_actions";
import PropTypes from "prop-types";
import {showErrorGrowl, showSuccessGrowl} from "../../../../../actions/msg_actions";
import Form from "react-bootstrap/Form";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import {CommonUtils, DateUtils, MARRIED_STATUS, SINGLE_STATUS} from "../../../../../actions/common_utils";
import MaskedInput from "react-input-mask";
import {Field, Formik} from "formik";
import * as Yup from "yup";
import {Card, Col} from "react-bootstrap";
import {FormikDatePickerComponent} from "../../../../../components/formik/formik_date_picker";
import DatePickerReact from "react-datepicker";
import {getInvalidationTypesByReportableType} from "../../../../../actions/fieldValue_actions";
import DeleteConfirmationModal from "../../../../../components/delete_confirm_modal";


class SubjectGeneralUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            availInvalidateReasons: [{id: "none", name: "Select Reason..."}]
        }
    }

    componentDidMount() {
        this.props.fetchPersonWithoutHidingDetails(this.props.subjectId);
        getInvalidationTypesByReportableType("PERSONNEL_INFO_MARITAL_STATUS").then((invalidationTypes) => {
            const reasons = [{id: "none", name: "Select Reason..."}];

            invalidationTypes.forEach((type) => {
                reasons.push({
                    id: type.id,
                    name: type.displayName
                });
            });

            this.setState({
                availInvalidateReasons: reasons
            });
        });

    }

    onSubmit = (values) => {
        const {subject} = this.props;
        // No need to pass this now as there are some serialization issues and we are never going to set issues from frontend
        for (let eachField in values){
            if (Array.isArray(values[eachField])){
                values[eachField] = null;
            }
        }
        if (values.maritalStatusCode === subject.maritalStatusCode){
            values.spouseInfo = null;
        }
        if (values.maritalStatusCode !== subject.maritalStatusCode &&
            values.maritalStatusCode === MARRIED_STATUS){
            values.spouseInfo.reasonInvalidated = null;
        }
        values.briefingLevel = null;
        if (values.spouseInfo && values.spouseInfo.reasonInvalidated === ''){
            values.spouseInfo.reasonInvalidated = null;
        }
        this.props.updateSubjectGeneralDetails(this.props.subjectId, values, () => {
            this.props.showSuccessGrowl("Subject Updated", "Subject details updated successfully!");
            this.props.onFinish();
        }, (error)=>{
            console.log(error);
            this.props.showErrorGrowl("Save Error", error.response.data);
        });
    }

    cancelUpdate = () => {
        this.props.onFinish();
    }

    isValidSsn = (ssn) => {
        return new RegExp(/^\d{3}-\d{2}-\d{4}$/).test(ssn);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {subject, subjectId} = this.props;

        if (subject.id && subject.id === subjectId) {
            // Get DOB and SSN
            getSubjectSsn(subjectId, (ssnResp) => {
                getSubjectDob(subjectId, (dobResp) => {
                    subject.socialSecurityNumber = ssnResp.data;
                    subject.personBirthDate = dobResp.data;

                    if (!subject.gender || subject.gender === ''){
                        subject.gender = 'Male';
                    }

                    if (!subject.maritalStatusCode || subject.maritalStatusCode === ''){
                        subject.maritalStatusCode = SINGLE_STATUS;
                    }

                });
            });
        }
    }

    maritalStatusChangeTest = (testName, message, subject, status) => {
        return Yup.string().test(
            testName,
            message,
            function (value) {
                if (this.from[1].value.maritalStatusCode !== subject.maritalStatusCode &&
                    this.from[1].value.maritalStatusCode === status){
                    return value !== "" && value !== undefined;
                } else {
                    return true;
                }
            }
        )
    }
    // Compare original value of marital status code with the current one selected
    // if it is changed then set marital status changed flag
    render() {
        const {subject} = this.props;

        return (
            <Formik
                initialValues={{
                    personFirstName: subject.personFirstName?subject.personFirstName:'',
                    personLastName: subject.personLastName?subject.personLastName:'',
                    emailAddress: subject.emailAddress?subject.emailAddress:'',
                    personalEmailAddress: subject.personalEmailAddress ? subject.personalEmailAddress:'',
                    socialSecurityNumber: subject.socialSecurityNumber,
                    countryOfBirth: subject.countryOfBirth?subject.countryOfBirth:'',
                    personBirthDate: subject.personBirthDate,
                    cityOfBirth: subject.cityOfBirth?subject.cityOfBirth:'',
                    stateOfBirth: subject.stateOfBirth?subject.stateOfBirth:'',
                    gender: subject.gender?subject.gender:'Male',
                    maritalStatusCode: subject.maritalStatusCode?subject.maritalStatusCode:SINGLE_STATUS,
                    spouseInfo: {
                        firstName:'',
                        lastName:'',
                        startDate:'',
                        spouseDob:'',
                        spousePlaceOfBirth:'',
                        spouseCitizenship:'United States',
                        dateInvalidated:'',
                        reasonInvalidated:'none'
                    }
                }}
                enableReinitialize={true}
                onSubmit={this.onSubmit}
                validationSchema={
                    Yup.object().shape({
                        personFirstName: Yup.string().required('First Name is required'),
                        personLastName: Yup.string().required('LastName is required'),
                        emailAddress: Yup.string().required("Email Address is required").email('Enter a valid email'),
                        personalEmailAddress: Yup.string().email('Enter a valid email'),
                        socialSecurityNumber: Yup.string().required('SSN is required'),
                        countryOfBirth: Yup.string().required('Country of birth is required'),
                        spouseInfo: Yup.object().shape({
                            firstName: this.maritalStatusChangeTest('spouse-first-name-test', 'Spouse First Name is required', subject, MARRIED_STATUS),
                            lastName: this.maritalStatusChangeTest('spouse-last-name-test', 'Spouse Last Name is required', subject, MARRIED_STATUS),
                            startDate: this.maritalStatusChangeTest('spouse-startDate-test', 'Date of Marriage is required', subject, MARRIED_STATUS),
                            spouseDob: this.maritalStatusChangeTest('spouse-spouseDob-test', 'Spouse Date of Birth is required', subject, MARRIED_STATUS),
                            spousePlaceOfBirth: this.maritalStatusChangeTest('spouse-spousePlaceOfBirth-test', 'Spouse Place of Birth is required', subject, MARRIED_STATUS),
                            spouseCitizenship: this.maritalStatusChangeTest('spouse-spouseCitizenship-test', 'Spouse Country of Citizenship is required', subject, MARRIED_STATUS),
                            dateInvalidated: this.maritalStatusChangeTest('spouse-dateInvalidated-test', 'End Date is required', subject, SINGLE_STATUS),
                            reasonInvalidated: Yup.string().test(
                                'spouse-reasonInvalidated-test',
                                'Reason is required',
                                function (value) {
                                    if (this.from[1].value.maritalStatusCode !== subject.maritalStatusCode &&
                                        this.from[1].value.maritalStatusCode === SINGLE_STATUS){
                                        return value !== "none";
                                    } else {
                                        return true;
                                    }
                                }
                            ),
                        })
                    })
                }
            >
                {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, resetForm, errors, handleBlur}) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Card>
                            <Card.Header>Update General Details Form</Card.Header>
                            <Card.Body>
                                <Form.Row>
                                    <Form.Group as={Col} md="4" controlId={'personFirstName'}>
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control required type="text" name={'personFirstName'}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur} placeholder={'Enter first name'}
                                                      isInvalid={touched.personFirstName && !!errors.personFirstName}
                                                      value={values.personFirstName}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.personFirstName}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId={'personLastName'}>
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control required type="text" name={'personLastName'}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur} placeholder={'Enter last name'}
                                                      isInvalid={touched.personLastName && !!errors.personLastName}
                                                      value={values.personLastName}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.personLastName}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId={'emailAddress'}>
                                        <Form.Label>Email Address</Form.Label>
                                        <Form.Control required type="text" name={'emailAddress'}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur} placeholder={'Enter email address'}
                                                      isInvalid={touched.emailAddress && !!errors.emailAddress}
                                                      value={values.emailAddress}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.emailAddress}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                  
                                    <Form.Group as={Col} md={"4"} controlId="socialSecurityNumber">
                                        <Form.Label>Social Security Number (SSN)</Form.Label>
                                        {/*{this.isValidSsn(subject.socialSecurityNumber) &&*/}
                                        <Form.Control
                                            required
                                            as={MaskedInput}
                                            name="socialSecurityNumber"
                                            mask="*99-99-9999"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={touched.socialSecurityNumber && !!errors.socialSecurityNumber}
                                            value={values.socialSecurityNumber}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.socialSecurityNumber}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId={'personBirthDate'}>
                                        <Form.Label>Date of Birth</Form.Label>
                                        <Field
                                            id="personBirthDate"
                                            name="personBirthDate"
                                            component={FormikDatePickerComponent}
                                            placeholder={"Date of Birth (MM/DD/YYYY)"}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.personBirthDate}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId={'cityOfBirth'}>
                                        <Form.Label>Birth City</Form.Label>
                                        <Form.Control type="text" name={'cityOfBirth'}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur} placeholder={'Enter the city of birth'}
                                                      isInvalid={touched.cityOfBirth && !!errors.cityOfBirth}
                                                      value={values.cityOfBirth}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.cityOfBirth}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                   
                                    <Form.Group as={Col} md={'4'} controlId={'stateOfBirth'}>
                                        <Form.Label>Birth State</Form.Label>
                                        <Form.Control required type="text" name={'stateOfBirth'}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur} placeholder={'Enter state of birth'}
                                                      isInvalid={touched.stateOfBirth && !!errors.stateOfBirth}
                                                      value={values.stateOfBirth}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.stateOfBirth}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md={'4'} controlId={'countryOfBirth'}>
                                        <Form.Label>Birth Country</Form.Label>
                                        <Form.Control required type="text" name={'countryOfBirth'}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur} placeholder={'Enter country of birth'}
                                                      isInvalid={touched.countryOfBirth && !!errors.countryOfBirth}
                                                      value={values.countryOfBirth}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.countryOfBirth}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId={'gender'}>
                                        <Form.Label>Gender</Form.Label>
                                        <Form.Control required as="select" name={'gender'}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      isInvalid={touched.gender && !!errors.gender}
                                                      value={values.gender}
                                        >
                                            {CommonUtils.getOptionsForSelect(CommonUtils.forDropDown(Array.of('Male', 'Female')))}
                                        </Form.Control>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} md="4" controlId={'personalEmailAddress'}>
                                        <Form.Label>Personal Email Address</Form.Label>
                                        <Form.Control type="text" name={'personalEmailAddress'}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur} placeholder={'Enter personal email address (if any)'}
                                                      isInvalid={touched.personalEmailAddress && !!errors.personalEmailAddress}
                                                      value={values.personalEmailAddress}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.personalEmailAddress}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="4" controlId={'maritalStatusCode'}>
                                        <Form.Label>Marital Status</Form.Label>
                                        <Form.Control required as="select" name={'maritalStatusCode'}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      isInvalid={touched.maritalStatusCode && !!errors.maritalStatusCode}
                                                      value={values.maritalStatusCode}
                                        >
                                            {CommonUtils.getOptionsForSelect(CommonUtils.forDropDown(Array.of(SINGLE_STATUS, MARRIED_STATUS)))}
                                        </Form.Control>
                                    </Form.Group>
                                </Form.Row>
                                {values.maritalStatusCode !== subject.maritalStatusCode && values.maritalStatusCode === MARRIED_STATUS &&
                                    this.getMarriageInfo(handleChange, handleBlur, touched, errors, values, setFieldValue)
                                }
                                {values.maritalStatusCode !== subject.maritalStatusCode && values.maritalStatusCode === SINGLE_STATUS &&
                                    this.getDivorceInfo(handleChange, handleBlur, touched, errors, values, setFieldValue)
                                }
                                <Fragment>
                                    <ButtonToolbar>
                                        <Button variant="primary" type="submit">Save</Button>

                                        <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="Update General Details Form"
                                                  handleDeleteCallback={this.cancelUpdate}/>
                                    </ButtonToolbar>
                                </Fragment>
                            </Card.Body>
                        </Card>
                    </Form>
                )}
            </Formik>
        );
    }

    getMarriageInfo = (handleChange, handleBlur, touched, errors, values, setFieldValue) => {
        return (
            <Fragment>
                <Form.Row>
                    <Form.Group as={Col} md="4" controlId={'spouseInfo.firstName'}>
                        <Form.Label>Spouse First Name</Form.Label>
                        <Form.Control required type="text" name={'spouseInfo.firstName'}
                                      onChange={handleChange}
                                      onBlur={handleBlur} placeholder={"Enter spouse's first name"}
                                      isInvalid={touched.spouseInfo && touched.spouseInfo.firstName && !!(errors.spouseInfo && errors.spouseInfo.firstName)}
                                      value={values['spouseInfo.firstName']}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.spouseInfo?errors.spouseInfo.firstName:''}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId={'spouseInfo.lastName'}>
                        <Form.Label>Spouse Last Name</Form.Label>
                        <Form.Control required type="text" name={'spouseInfo.lastName'}
                                      onChange={handleChange}
                                      onBlur={handleBlur} placeholder={"Enter spouse's last name"}
                                      isInvalid={touched.spouseInfo && touched.spouseInfo.lastName && !!(errors.spouseInfo && errors.spouseInfo.lastName)}
                                      value={values['spouseInfo.lastName']}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.spouseInfo?errors.spouseInfo.lastName:''}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId={'spouseInfo.startDate'}>
                        <Form.Label>Date of Marriage</Form.Label>
                        <DatePickerReact selected={DateUtils.getDate(values.spouseInfo ? values.spouseInfo.startDate : null)}
                                         name={'spouseInfo.startDate'}
                                         onChange={(date) => {
                                             if (date === null) {
                                                 setFieldValue('spouseInfo.startDate', "");
                                             } else {
                                                 setFieldValue('spouseInfo.startDate', date.toISOString());
                                             }
                                         }}
                                         dateFormat={"MM/dd/yyyy"} placeholderText={"Date of Marriage (MM/DD/YYYY)"} isClearable
                                         className={"form-control " + ((touched.spouseInfo && touched.spouseInfo.startDate && !!(errors.spouseInfo && errors.spouseInfo.startDate)) ? "is-invalid" : "")}
                        />
                        {errors.spouseInfo && errors.spouseInfo.startDate && touched.spouseInfo && touched.spouseInfo.startDate &&
                        <div className={"invalid-feedback"} style={{display: "block"}}>{errors.spouseInfo.startDate}</div>}
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} md="4" controlId={'spouseInfo.spouseDob'}>
                        <Form.Label>Spouse Date of Birth</Form.Label>
                        <DatePickerReact selected={DateUtils.getDate(values.spouseInfo ? values.spouseInfo.spouseDob : null)}
                                         name={'spouseInfo.spouseDob'}
                                         onChange={(date) => {
                                             if (date === null) {
                                                 setFieldValue('spouseInfo.spouseDob', "");
                                             } else {
                                                 setFieldValue('spouseInfo.spouseDob', date.toISOString());
                                             }
                                         }}
                                         dateFormat={"MM/dd/yyyy"} placeholderText={"Spouse Date of Birth (MM/DD/YYYY)"} isClearable
                                         className={"form-control " + ((touched.spouseInfo && touched.spouseInfo.spouseDob && !!(errors.spouseInfo && errors.spouseInfo.spouseDob)) ? "is-invalid" : "")}
                        />
                        {errors.spouseInfo && errors.spouseInfo.spouseDob && touched.spouseInfo && touched.spouseInfo.spouseDob &&
                        <div className={"invalid-feedback"} style={{display: "block"}}>{errors.spouseInfo.spouseDob}</div>}
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId={'spouseInfo.spousePlaceOfBirth'}>
                        <Form.Label>Spouse Place of Birth</Form.Label>
                        <Form.Control required type="text" name={'spouseInfo.spousePlaceOfBirth'}
                                      onChange={handleChange}
                                      onBlur={handleBlur} placeholder={"Enter spouse's place of birth"}
                                      isInvalid={touched.spouseInfo && touched.spouseInfo.spousePlaceOfBirth && !!(errors.spouseInfo && errors.spouseInfo.spousePlaceOfBirth)}
                                      value={values['spouseInfo.spousePlaceOfBirth']}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.spouseInfo?errors.spouseInfo.spousePlaceOfBirth:''}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId={'spouseInfo.spouseCitizenship'}>
                        <Form.Label>Spouse's Citizenship Country</Form.Label>
                        <Form.Control required as="select" name={'spouseInfo.spouseCitizenship'}
                                      onChange={handleChange}
                                      onBlur={handleBlur} placeholder={"Enter spouse's citizenship country"}
                                      isInvalid={touched.spouseInfo && touched.spouseInfo.spouseCitizenship && !!(errors.spouseInfo && errors.spouseInfo.spouseCitizenship)}
                                      value={values.spouseInfo? values.spouseInfo.spouseCitizenship:null}
                        >
                            {CommonUtils.getOptionsForSelect(CommonUtils.getAvailCountries())}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {errors.spouseInfo?errors.spouseInfo.spouseCitizenship:''}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>
            </Fragment>
        );

    }

    getDivorceInfo = (handleChange, handleBlur, touched, errors, values, setFieldValue) => {
        return (
            <Fragment>
                <Form.Row>
                    <Form.Group as={Col} md="4" controlId={'spouseInfo.dateInvalidated'}>
                        <Form.Label>Marriage End Date</Form.Label>
                        <DatePickerReact selected={DateUtils.getDate(values.spouseInfo ? values.spouseInfo.dateInvalidated : null)}
                                         name={'spouseInfo.dateInvalidated'}
                                         onChange={(date) => {
                                             if (date === null) {
                                                 setFieldValue('spouseInfo.dateInvalidated', "");
                                             } else {
                                                 setFieldValue('spouseInfo.dateInvalidated', date.toISOString());
                                             }
                                         }}
                                         dateFormat={"MM/dd/yyyy"} placeholderText={"Marriage End Date (MM/DD/YYYY)"} isClearable
                                         className={"form-control " + ((touched.spouseInfo && touched.spouseInfo.dateInvalidated && !!(errors.spouseInfo && errors.spouseInfo.dateInvalidated)) ? "is-invalid" : "")}
                        />
                        {errors.spouseInfo && errors.spouseInfo.dateInvalidated && touched.spouseInfo && touched.spouseInfo.dateInvalidated &&
                        <div className={"invalid-feedback"} style={{display: "block"}}>{errors.spouseInfo.dateInvalidated}</div>}
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId={'spouseInfo.reasonInvalidated'}>
                        <Form.Label>Reason</Form.Label>
                        <Form.Control required as="select" name={'spouseInfo.reasonInvalidated'}
                                      onChange={handleChange}
                                      onBlur={handleBlur} placeholder={"Enter reason for end of marriage"}
                                      isInvalid={touched.spouseInfo && touched.spouseInfo.reasonInvalidated && !!(errors.spouseInfo && errors.spouseInfo.reasonInvalidated)}
                                      value={values.spouseInfo?values.spouseInfo.reasonInvalidated:null}
                        >
                            {CommonUtils.getOptionsForSelect(this.state.availInvalidateReasons)}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {errors.spouseInfo?errors.spouseInfo.reasonInvalidated:''}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>
            </Fragment>
        );

    }
}


function mapStateToProps({person}) {
    return {
        subject: person
    }
}

SubjectGeneralUpdate.propTypes = {
    subjectId: PropTypes.string.isRequired,
    onFinish: PropTypes.func.isRequired
};

export default connect(mapStateToProps, {fetchPersonWithoutHidingDetails, updateSubjectGeneralDetails, showSuccessGrowl, showErrorGrowl})(SubjectGeneralUpdate);